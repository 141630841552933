// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import * as StorageDataKeys from '../../google/constants';
import { ComponentProps } from '../../google/connect/models';

// Externals
import { t, Trans } from '@lingui/macro';
import {
	CircularProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Theme,
	Typography,
	useMediaQuery
} from '@mui/material';

// Models

// Services

// Stores
import { StorageValue } from 'components/legacy/hooks/useStorageValue';
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';

interface IStorageValueCollection extends Query {
	[StorageDataKeys.PROPERTY_ID_GA]: StorageValue<string>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const [mapping] = useStorageValueCollection<IStorageValueCollection>(
		[StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.AUTH_TOKEN, StorageDataKeys.TABLE_ID_GA],
		organizationId
	);

	const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

	const tableData = selectedGA.value ? mapping[StorageDataKeys.TABLE_ID_GA].value : null;

	const loading = mapping[StorageDataKeys.PROPERTY_ID_GA].loading || mapping[StorageDataKeys.TABLE_ID_GA].loading;

	function GetHeaderName(value: string) {
		switch (value) {
			case 'fullPageUrl':
				return t`URL`;
			case 'screenPageViews':
			case 'ga:pageviews':
				return t`Pages vues`;
			case 'pagePathPlusQueryString':
			case 'ga:pagePath':
				return t`URL relative`;
			case 'pageTitle':
			case 'ga:pageTitle':
				return t`Titre de la page`;

			default:
				return value;
		}
	}

	if (loading) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					title={name}
					tooltip={tooltip}
				/>
				<CircularProgress />
			</>
		);
	}

	if (tableData && selectedGA.value) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					title={name}
					tooltip={tooltip}
				/>
				<Paper>
					<TableContainer component={Paper}>
						<Table aria-label="data table ga" size="small">
							<TableHead>
								<TableRow>
									{tableData.dimensionHeaders.map((headers, index) =>
										isMobile && headers.name === 'fullPageUrl' ? null : (
											<TableCell key={headers.name + index.toString()}>{GetHeaderName(headers.name)}</TableCell>
										)
									)}
									{tableData.metricHeaders.map((headers, index) =>
										isMobile && headers.name === 'fullPageUrl' ? null : (
											<TableCell key={headers.name + (index + 1).toString()}>{GetHeaderName(headers.name)}</TableCell>
										)
									)}
								</TableRow>
							</TableHead>
							<TableBody>
								{tableData.rows?.map((rowsValue, index) => (
									<TableRow
										key={rowsValue.dimensionValues + index}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										{rowsValue.dimensionValues.map((item, index) =>
											index === 0 ? (
												<TableCell key={rowsValue.dimensionValues[1] + index}>
													{isMobile ? (
														<a target="_blank" href={'https://' + rowsValue.dimensionValues[1].value} rel="noreferrer">
															{item.value}
														</a>
													) : (
														item.value
													)}
												</TableCell>
											) : index === 1 && isMobile ? null : ( //hidden url column
												<TableCell
													key={rowsValue.dimensionValues[1] + index}
													sx={{ overflowWrap: 'anywhere', maxWidth: '30%' }}
												>
													{item.value}
												</TableCell>
											)
										)}
										{rowsValue.metricValues.map((item, index) => (
											<TableCell
												key={rowsValue.dimensionValues[1] + rowsValue.dimensionValues.length + 1}
												sx={{ overflowWrap: 'anywhere', maxWidth: '30%' }}
											>
												{item.value}
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
							{tableData.rows === null || tableData.rows?.length === 0 ? (
								<TableFooter>
									<TableCell>{t`Pas de données`}</TableCell>
								</TableFooter>
							) : (
								''
							)}
						</Table>
					</TableContainer>
				</Paper>
			</>
		);
	}
	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={name}
				tooltip={tooltip}
			/>
			<Typography>{t`Aucune données pour le moment.`}</Typography>
		</>
	);
};

class GooglePageViews implements ICustomBlock {
	name = () => t`Pages les plus vues - Google Analytics`;
	description = () => t`Voici les 10 pages les plus consultées ces 30 derniers jours:`;
	tooltip = () => t`Cette métrique regarde les pages au sein de votre site ayant comptabilisé le plus de vues.`;
	isScoreVisible = () => false;
	key = () => 'google-ga-pageviews';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GooglePageViews;
