import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

// Internals
import {
	emailLeakDetectorProfiles,
	emailLeakDetectorProfilesResponse
} from 'components/legacy/models/emailLeakDetectorProfiles';

// Externals
import { t } from '@lingui/macro';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	InputLabel,
	Input,
	Typography,
	DialogActions,
	Button,
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel
} from '@mui/material';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Services
import { usePutProfileMutation, usePostProfileMutation } from 'components/legacy/services/EmailLeakDetector';

const inputLabelStyle = {
	color: (theme) => theme.palette.text.primary
};

const inputStyle = {
	mb: 3
};

interface HaveIBeenPwnedDialogSettingsProps {
	dialogOpen: boolean;
	setDialogOpen: Dispatch<SetStateAction<boolean>>;
	organizationId: number;
}

const HaveIBeenPwnedDialogSettings: React.FC<HaveIBeenPwnedDialogSettingsProps> = ({
	dialogOpen,
	setDialogOpen,
	organizationId
}) => {
	const [emailLeakDetectorProfiles] = useStorageValue<emailLeakDetectorProfiles>(
		connectorStorageKeys.emailLeakDetector.profiles,
		organizationId
	);

	useEffect(() => {
		if (
			emailLeakDetectorProfiles !== undefined &&
			emailLeakDetectorProfiles !== null &&
			!emailLeakDetectorProfiles.loading &&
			emailLeakDetectorProfiles.value !== undefined &&
			emailLeakDetectorProfiles.value !== null
		) {
			setProfiles([...emailLeakDetectorProfiles.value.response]);
		} else {
			setProfiles(undefined);
		}
	}, [emailLeakDetectorProfiles]);

	const [profiles, setProfiles] = useState<emailLeakDetectorProfilesResponse[]>(undefined);

	const [isError, setIsError] = useState(false);

	const [putProfile] = usePutProfileMutation();
	const [postProfile] = usePostProfileMutation();

	const updateStatus = (userIndex: number, checked: boolean) => {
		const newProfiles = [...profiles];
		newProfiles[userIndex].isToBeTreated = checked;
		setProfiles(newProfiles);
	};

	const [newEmail, setNewEmail] = useState('');

	const AddProfile = () => {
		if (newEmail && newEmail.length >= 5) postProfile({ email: newEmail });
	};

	const SaveChange = (event) => {
		putProfile({
			users: profiles.map((e) => {
				return {
					email: e.email,
					toBeTreated: e.isToBeTreated
				};
			})
		}).then(() => {
			setDialogOpen(false);
		});
	};

	const cancelHandler = () => {
		if (
			emailLeakDetectorProfiles !== undefined &&
			emailLeakDetectorProfiles !== null &&
			!emailLeakDetectorProfiles.loading &&
			emailLeakDetectorProfiles.value !== undefined &&
			emailLeakDetectorProfiles.value !== null
		) {
			setProfiles([...emailLeakDetectorProfiles.value.response]);
		}
		setDialogOpen(false);
	};

	return (
		<Dialog
			aria-describedby="alert-dialog-description"
			aria-labelledby="alert-dialog-title"
			keepMounted={true}
			onClose={cancelHandler}
			open={dialogOpen}
		>
			<DialogTitle id="alert-dialog-title" sx={{ my: 2 }}>{t`Configuration des e-mails à auditer`}</DialogTitle>
			<DialogContent>
				{emailLeakDetectorProfiles.loading ? (
					<CircularProgress />
				) : (
					<>
						<InputLabel sx={inputLabelStyle}>{t`Nouvelle adresse Email`}</InputLabel>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row',
								py: 2
							}}
						>
							<Input
								fullWidth={true}
								name="newEmail"
								onChange={(e) => {
									if (e.target.value !== undefined && e.target.value !== null) {
										setIsError(
											!e.target.value.includes('@') ||
												e.target.value[0] === '@' ||
												!e.target.value.includes('.') ||
												e.target.value.at(-1) === '.'
										);
										setNewEmail(e.target.value);
									}
								}}
								placeholder={t`nouvelle adresse email`}
								value={newEmail}
								sx={inputStyle}
								autoFocus={true}
								required={true}
								error={isError}
							/>
							<Button
								disabled={isError || newEmail.length < 5}
								onClick={() => {
									AddProfile();
								}}
							>
								{t`Ajouter l'adresse`}
							</Button>
						</Box>
						{profiles &&
							profiles.map((user, userIndex) => (
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										flexDirection: 'row',
										':hover': {
											color: (theme) => theme.palette.primary.main
										}
									}}
									key={user.email}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={user.isToBeTreated}
												onChange={(event) => updateStatus(userIndex, event.target.checked)}
												name="checked"
												sx={{ color: (theme) => theme.palette.text.primary }}
											/>
										}
										label={<Typography sx={{ ml: 2 }}>{user.email}</Typography>}
										sx={{ margin: 0 }}
									/>
								</Box>
							))}
					</>
				)}
			</DialogContent>
			<DialogActions sx={{ mb: 2, mr: 3 }}>
				<Button color="primary" onClick={SaveChange} variant="contained">
					{t`Enregistrer`}
				</Button>
				<Button onClick={cancelHandler} variant="outlined">
					{t`Annuler`}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default HaveIBeenPwnedDialogSettings;
