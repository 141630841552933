import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { MenuItem, Stack, TextField } from '@mui/material';

import { postGenerationOptions } from '../Models/postGenerationOptions';
import { StorageValue } from 'components/legacy/hooks/useStorageValueByRef';

interface AiPostsGenerationDesktopProps {
	subjects: StorageValue<string[]>;
	handlerSendGenerationQuery: () => Promise<void>;
	isPostGenerating: boolean;
	options: postGenerationOptions;
	SetOption: React.Dispatch<React.SetStateAction<postGenerationOptions>>;
	postLengths: any;
	nbHashtags: any;
	tonalities: any;
	translationLanguages: any;
}

const AiPostsGenerationMobile: React.FC<AiPostsGenerationDesktopProps> = ({
	subjects,
	handlerSendGenerationQuery,
	isPostGenerating,
	options,
	SetOption,
	postLengths,
	nbHashtags,
	tonalities,
	translationLanguages
}) => {
	return (
		<>
			<Stack direction="column" spacing={2} sx={{ alignItems: 'end', display: 'flex', mb: 2 }}>
				<TextField
					disabled={false}
					id="Subject"
					label={t`Sujet`}
					margin="normal"
					select={true}
					sx={{
						'& label': {
							color: '#FF0000'
						},
						mb: 0,
						width: '100%'
					}}
					SelectProps={{
						MenuProps: {
							PaperProps: {
								style: {
									overflowX: 'auto' // enable horizontal scroll
								}
							}
						}
					}}
					value={
						subjects.value &&
						options.subject &&
						Object.values(subjects.value).includes(options.subject) &&
						Object.keys(subjects.value)[Object.values(subjects.value).indexOf(options.subject)]
					}
				>
					{subjects &&
						subjects.value &&
						Object.entries(subjects.value).map(([cle, valeur]) => (
							<MenuItem
								key={'Subject' + cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, subject: valeur }));
								}}
								value={cle}
							>
								{valeur}
							</MenuItem>
						))}
				</TextField>
				<TextField
					disabled={false}
					id="postLength"
					label={t`Longueur du message`}
					margin="normal"
					select={true}
					sx={{
						'& label': {
							color: '#FF0000'
						},
						mb: 0,
						width: '100%'
					}}
					value={options.postLengths}
				>
					{Object.entries(postLengths).map(([cle, valeur]) => (
						<MenuItem
							key={'postLengths' + cle}
							onClick={() => {
								SetOption((prevOptions) => ({ ...prevOptions, postLengths: cle }));
							}}
							value={cle}
						>
							{valeur}
						</MenuItem>
					))}
				</TextField>
				<TextField
					disabled={false}
					id="nbHashtags"
					label={t`Nombre de hashtags`}
					margin="normal"
					select={true}
					sx={{
						'& label': {
							color: '#FF0000'
						},
						mb: 0,
						width: '100%'
					}}
					value={options.nbHashtags}
				>
					{Object.entries(nbHashtags).map(([cle, valeur]) => (
						<MenuItem
							key={'nbHashtags' + cle}
							onClick={() => {
								SetOption((prevOptions) => ({ ...prevOptions, nbHashtags: cle }));
							}}
							value={cle}
						>
							{valeur}
						</MenuItem>
					))}
				</TextField>
				<TextField
					disabled={false}
					id="tonality"
					label={t`Tonalité`}
					margin="normal"
					select={true}
					sx={{
						'& label': {
							color: '#FF0000'
						},
						mb: 0,
						width: '100%'
					}}
					value={options.tonality}
				>
					{Object.entries(tonalities)
						// Sort by translated language
						.sort(([, valeurA], [, valeurB]) => {
							if (valeurA < valeurB) return -1;
							if (valeurA > valeurB) return 1;
							return 0;
						})
						.map(([cle, valeur]) => (
							<MenuItem
								key={'tonality' + cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, tonality: cle }));
								}}
								value={cle}
							>
								{valeur}
							</MenuItem>
						))}
				</TextField>
				<TextField
					disabled={false}
					id="languages"
					label={t`Langue`}
					margin="normal"
					select={true}
					sx={{
						'& label': {
							color: '#FF0000'
						},
						mb: 0,
						width: '100%'
					}}
					value={options.translationLanguages}
				>
					{Object.entries(translationLanguages)
						// Sort by translated language
						.sort(([, valeurA], [, valeurB]) => {
							if (valeurA < valeurB) return -1;
							if (valeurA > valeurB) return 1;
							return 0;
						})
						.map(([cle, valeur]) => (
							<MenuItem
								key={'languages' + cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, translationLanguages: cle }));
								}}
								value={cle}
							>
								{valeur}
							</MenuItem>
						))}
				</TextField>
				<LoadingButton
					disabled={isPostGenerating}
					loading={isPostGenerating}
					onClick={handlerSendGenerationQuery}
					sx={{ mt: 2, width: 'max-content' }}
					variant="contained"
				>
					<Trans>{t`Générer`}</Trans>
				</LoadingButton>
			</Stack>
		</>
	);
};

export default AiPostsGenerationMobile;
