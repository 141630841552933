// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const historicalDataApi = createApi({
	reducerPath: 'historicalDataApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['historical-data'],
	endpoints: (build) => ({
		getAllHistoricalDataByReference: build.query({
			query: ({ connectorName, key }) =>
				`/api/historical-data/all-by-reference?connectorName=${connectorName}&key=${key}`,
			providesTags: ['historical-data']
		}),
		getLastsHistoricalData: build.query({
			query: ({ connectorName, key, quantity }) =>
				`/api/historical-data/lasts?connectorName=${connectorName}&key=${key}&quantity=${quantity}`,
			providesTags: ['historical-data']
		})
	})
});

export const {
	useGetAllHistoricalDataByReferenceQuery,
	useGetLastsHistoricalDataQuery,
	useLazyGetAllHistoricalDataByReferenceQuery,
	useLazyGetLastsHistoricalDataQuery
} = historicalDataApi;
