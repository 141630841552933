// Common
import { request } from '../../common';

export const GetLastUpdateDatetime = (): Promise<Response> =>
	request({ method: 'POST', api: `/api/organizations/last-update-datetime`, authorized: true, body: null });

interface Response {
	data: {
		lastUpdate: number;
	};
}
