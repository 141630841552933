// Externals
import { t, Trans } from '@lingui/macro';
import { Typography } from '@mui/material';

// Components
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Store
import { useEffect, useState } from 'react';
import { useGetWebSiteSummaryQuery } from 'components/legacy/services/connectors';
import QuoteDisplay from '../../quote-display';

interface ComponentProps {
	name: string;
	description: string;
	tips: string;
}
const UserComponent = ({ name, description, tips }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const [information, setInformation] = useState<string>();
	const { data: response, isLoading: isLoading } = useGetWebSiteSummaryQuery({});

	useEffect(() => {
		fetchUsage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [response]);
	const fetchUsage = async () => {
		setInformation(response?.data);
	};
	return (
		<>
			<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />
			<Typography sx={{ pt: 2, pb: 2 }} variant="body2">
				<Trans>D'après notre intelligence artificielle, voici le résumé de votre page web :</Trans>
				<QuoteDisplay text={information} isLoading={isLoading} />
			</Typography>
		</>
	);
};

class WebSiteSummary implements ICustomBlock {
	tips = () => t``;
	description = () => t``;
	name = () => t`Résumé de votre page`;
	key = () => 'ai-website-summary';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent description={this.description()} name={this.name()} tips={this.tips()} />
	);
}

export default WebSiteSummary;
