function stringToColor(string) {
	let hash = 0;
	let i;

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}
	/* eslint-enable no-bitwise */

	return color;
}

function stringAvatar(name) {
	const parts = name.split(' ');
	const first = parts.length > 0 && parts[0].length ? parts[0][0] : '';
	const second = parts.length > 1 && parts[1].length ? parts[1][0] : '';
	return {
		sx: {
			bgcolor: stringToColor(name)
		},
		children: `${first}${second}`.toUpperCase()
	};
}

export { stringAvatar };
