import { Trans } from '@lingui/macro';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const PublicationTableHead = ({ onRequestSort, sortColumn, sortOrder }) => {
	const handleSort = (columnId) => {
		const isAsc = sortColumn === columnId && sortOrder === 'asc';

		onRequestSort(columnId, isAsc ? 'desc' : 'asc');
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell
					key="PublishedTime"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel
						active={sortColumn === 'PublishedTime'}
						direction={sortOrder}
						onClick={() => handleSort('PublishedTime')}
					>
						<Trans>Date</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="Impressions"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel
						active={sortColumn === 'Impressions'}
						direction={sortOrder}
						onClick={() => handleSort('Impressions')}
					>
						<Trans>Vues</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="Reactions"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel
						active={sortColumn === 'Reactions'}
						direction={sortOrder}
						onClick={() => handleSort('Reactions')}
					>
						<Trans>Réactions</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="Comments"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel
						active={sortColumn === 'Comments'}
						direction={sortOrder}
						onClick={() => handleSort('Comments')}
					>
						<Trans>Comments</Trans>
					</TableSortLabel>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default PublicationTableHead;
