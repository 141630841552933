import { Trans } from '@lingui/macro';
import { TableRow, TableCell, TableSortLabel, Table } from '@mui/material';

const PublicationTableHead = ({ onRequestSort, sortColumn, sortOrder, isSticky }) => {
	const handleSort = (columnId) => {
		const isAsc = sortColumn === columnId && sortOrder === 'asc';

		onRequestSort(columnId, isAsc ? 'desc' : 'asc');
	};

	return (
		<TableRow>
			<TableCell key="rsPhotoDesc" align="left">
				<Trans>Post</Trans>
			</TableCell>
			<TableCell key="PublishedTime" align="right">
				<TableSortLabel
					active={sortColumn === 'PublishedTime'}
					direction={sortOrder}
					onClick={() => handleSort('PublishedTime')}
				>
					<Trans>Date</Trans>
				</TableSortLabel>
			</TableCell>
			<TableCell key="Impressions" align="right">
				<TableSortLabel
					active={sortColumn === 'Impressions'}
					direction={sortOrder}
					onClick={() => handleSort('Impressions')}
				>
					<Trans>Vues</Trans>
				</TableSortLabel>
			</TableCell>
			<TableCell key="Reactions" align="right">
				<TableSortLabel
					active={sortColumn === 'Reactions'}
					direction={sortOrder}
					onClick={() => handleSort('Reactions')}
				>
					<Trans>Réactions</Trans>
				</TableSortLabel>
			</TableCell>
			<TableCell key="Comments" align="right">
				<TableSortLabel active={sortColumn === 'Comments'} direction={sortOrder} onClick={() => handleSort('Comments')}>
					<Trans>Comments</Trans>
				</TableSortLabel>
			</TableCell>
		</TableRow>
	);
};

export default PublicationTableHead;
