import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Externals
import { Trans, t } from '@lingui/macro';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
	Typography
} from '@mui/material';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { format } from 'date-fns';
import { round } from 'lodash';

// Components
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Hooks
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

interface ComponentProps {
	name: string;
	description: string;
	tips: string;
}

const UserComponent = ({ name, description, tips }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [alignment, setAlignment] = useState<string>('365');
	const [data, setData] = useState<any>(null);
	const [adsSummaries, setAdsSummaries] = useState<iAdsSummaries>(null);

	const [mapping] = useStorageValueCollection<Query>(
		[
			connectorStorageKeys.googleAds.adAccount,
			connectorStorageKeys.googleAds.adsSummary7,
			connectorStorageKeys.googleAds.adsSummary30,
			connectorStorageKeys.googleAds.adsSummary90,
			connectorStorageKeys.googleAds.adsSummary365,
			connectorStorageKeys.googleAds.adsSummaryData,
			connectorStorageKeys.googleAds.selectedEntity
		],
		organizationId
	);

	const isLoading =
		mapping[connectorStorageKeys.googleAds.adAccount].loading ||
		mapping[connectorStorageKeys.googleAds.adsSummary7].loading ||
		mapping[connectorStorageKeys.googleAds.adsSummary30].loading ||
		mapping[connectorStorageKeys.googleAds.adsSummary90].loading ||
		mapping[connectorStorageKeys.googleAds.adsSummary365].loading ||
		mapping[connectorStorageKeys.googleAds.adsSummaryData].loading ||
		mapping[connectorStorageKeys.googleAds.selectedEntity].loading;

	interface iAdsSummary {
		adsAccount: any;
		adsSummary7: any;
		adsSummary30: any;
		adsSummary90: any;
		adsSummary365: any;
		adsSummaryData: any;
		actions?: { action_type: string; value: number }[];
	}

	interface iAdsSummaries {
		googleAds: iAdsSummary;
	}

	const connectorName = 'google-ads';

	const generateRealData = useCallback(
		(numDays: any) => {
			const currentDate = new Date();
			const startDate = new Date().setDate(currentDate.getDate() - numDays);

			const localChartData = [];
			localChartData.push([{ type: 'date', label: 'Dates' }]);

			let selectedDate = new Date(startDate);
			for (let i = 0; i <= numDays; i++) {
				let clicks = [];
				const matchingData = adsSummaries.googleAds.adsSummaryData?.find((adSummaryData) => {
					var creactionDate = new Date(adSummaryData.created);
					return (
						creactionDate.getDate() === selectedDate.getDate() &&
						creactionDate.getMonth() === selectedDate.getMonth() &&
						creactionDate.getFullYear() === selectedDate.getFullYear()
					);
				});
				clicks[connectorName] = matchingData ? matchingData.clicks : 0;

				const formattedDate = new Date(format(selectedDate, 'MM/dd/yyyy'));
				localChartData.push([formattedDate, clicks[connectorName]]);

				selectedDate.setDate(selectedDate.getDate() + 1);
			}

			setData(localChartData);
		},
		[adsSummaries]
	);

	useEffect(() => {
		if (!isLoading && adsSummaries !== null) {
			generateRealData(alignment);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, adsSummaries, generateRealData]);

	const getPropName = (days: string) => {
		switch (days) {
			case '7':
				return 'adsSummary7';
			case '30':
				return 'adsSummary30';
			case '90':
				return 'adsSummary90';
			case '365':
				return 'adsSummary365';
			default:
				return 'adsSummaryData';
		}
	};

	const getValue = (days: string, valueKey: string) => adsSummaries.googleAds[getPropName(days)]?.[valueKey] ?? 0;

	useEffect(() => {
		if (!isLoading) {
			const result = {
				googleAds: {
					adsAccount: mapping[connectorStorageKeys.googleAds.adAccount].value,
					adsSummary7: mapping[connectorStorageKeys.googleAds.adsSummary7].value,
					adsSummary30: mapping[connectorStorageKeys.googleAds.adsSummary30].value,
					adsSummary90: mapping[connectorStorageKeys.googleAds.adsSummary90].value,
					adsSummary365: mapping[connectorStorageKeys.googleAds.adsSummary365].value,
					adsSummaryData: mapping[connectorStorageKeys.googleAds.adsSummaryData].value
				}
			};
			setAdsSummaries(result);
		}
	}, [isLoading, mapping]);

	const getClicks = (days: string): number => parseInt(getValue(days, 'clicks'));

	const getImpressions = (days: string): number => parseInt(getValue(days, 'impressions'));

	const getSpent = (days: string): number => round(getValue(days, 'spent'), 2);

	const getCurrency = (): string => adsSummaries.googleAds.adsAccount?.currencyCode;

	const currencySymbol = (currency) => {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency ? currency : 'USD',
			currencyDisplay: 'symbol'
		});

		let symbol;
		formatter.formatToParts(0).forEach(({ type, value }) => {
			if (type === 'currency') {
				symbol = value;
			}
		});

		return symbol;
	};

	const getCPC = (days: string) => round(getValue(days, 'cpc'), 2);

	const handleClickDays = (days: number) => {
		const date = new Date();
		date.setDate(date.getDate() - days);
	};

	const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: number) => {
		if (newAlignment === null || newAlignment.toString() === alignment) return;
		handleClickDays(newAlignment);
		setAlignment(newAlignment.toString());
	};

	const option = {
		color: ['rgb(77, 119, 255)'],
		smooth: true,
		xAxis: {
			type: 'category',
			boundaryGap: false,
			axisLabel: {
				rotate: 30
			},
			data: data?.slice(-alignment, data.length).map((element) => moment(element[0]).format('DD/MM/YY'))
		},
		toolbox: {
			feature: {
				saveAsImage: {}
			}
		},
		tooltip: {
			trigger: 'axis'
		},
		yAxis: {
			type: 'value',
			height: '400px'
		},
		series: [
			{
				data: data?.slice(-alignment, data.length).map((element) => element[1]),
				type: 'line',
				showSymbol: false,
				stack: 'Total'
			}
		]
	};

	return (
		<>
			<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />
			<Typography variant="body2">
				<Trans>Clics sur vos campagnes chaque jour.</Trans>
			</Typography>
			<br />
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
					<ToggleButton value="7">{t`7 jours`}</ToggleButton>
					<ToggleButton value="30">{t`30 jours`}</ToggleButton>
					<ToggleButton value="90">{t`90 jours`}</ToggleButton>
					<ToggleButton value="365">{t`365 jours`}</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			{data === null ? (
				<Typography gutterBottom={true} variant="h5">
					<Trans>Aucune donnée pour le moment.</Trans>
				</Typography>
			) : (
				<>
					<ReactECharts option={option} />

					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>{t`Dépensé`}</TableCell>
									<TableCell>{t`Impressions`}</TableCell>
									<TableCell>{t`Clics`}</TableCell>
									<TableCell>{t`CPC`}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow key={connectorName}>
									<TableCell sx={{ fontWeight: 600 }}>Google Ads </TableCell>
									<TableCell>
										{getSpent(alignment)} {currencySymbol(getCurrency())}
									</TableCell>
									<TableCell>{getImpressions(alignment)}</TableCell>
									<TableCell>{getClicks(alignment)}</TableCell>
									<TableCell>
										{getCPC(alignment)} {currencySymbol(getCurrency())}
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</>
			)}
		</>
	);
};

class GoogleAdsChart implements ICustomBlock {
	tips = () =>
		t`Voici les performances de vos campagnes. Les impressions sont le nombre de fois qu'elles ont été vues, les engagements sont toutes les interactions avec vos pubs, les clics le nombre de redirections vers votre lien, et le CPC le coût moyen par clic.`;
	description = () => ``;
	name = () => t`Performances de vos campagnes`;
	key = () => 'google-ads-chart';
	UserComponent: React.FC<UserComponentProps> = () => (
		<UserComponent description={this.description()} name={this.name()} tips={this.tips()} />
	);
}

export default GoogleAdsChart;
