export const request = async ({
	api,
	authorized,
	body,
	params,
	method,
	formData
}: {
	api: string;
	authorized: boolean;
	body?: any;
	params?: { [key: string]: any };
	method: 'DELETE' | 'GET' | 'POST' | 'PUT' | 'OPTIONS';
	formData?: FormData;
}): Promise<any> => {
	return new Promise((resolve: (json: any) => void, reject: (error: any) => void) => {
		const accessToken = window.localStorage.getItem('accessToken');
		if (!accessToken && authorized) reject('Missing access token');

		const srConnectionId = window.sessionStorage.getItem('SR-ConnectionId');
		const organizationId = Number(window.localStorage.getItem('organizationId'));
		const languageCode = window.localStorage.getItem('preferred-language');

		let headers: any = {
			'Accept-Language': languageCode ?? 'fr',
			'access-control-allow-methods': 'DELETE, GET, POST, PUT, OPTIONS',
			'x-sr-connection-id': srConnectionId,
			'x-organization-id': organizationId ?? 0,
			'x-ganesha-api-key': process.env.NEXT_PUBLIC_GANESHA_API_KEY,
			...(authorized && { authorization: `Bearer ${accessToken}` })
		};

		if (body) {
			headers = { ...headers, accept: 'application/json', 'content-type': 'application/json' };
		}

		let options = {
			method,
			headers,
			...(body && {
				body: JSON.stringify(body)
			}),
			...(formData && {
				body: formData
			})
		};

		let queryString = '';

		if (params && Object.keys(params).length > 0) {
			queryString = Object.entries(params)
				.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
				.join('&');
		}

		const apiUrl = `${process.env.NEXT_PUBLIC_API}${api}${queryString ? `?${queryString}` : ''}`;

		// FETCH
		fetch(apiUrl, options)
			.then((response) => {
				if (response.ok || response.status === 429) {
					response.text().then((text) => {
						try {
							const data = text.length ? JSON.parse(text) : undefined;
							resolve(data);
						} catch (error) {
							console.error(`API endpoint '${apiUrl}' not found.`, error);
							reject(new Error(`Failed to parse JSON data: ${api}, ${error}`));
						}
					});
				} else if (response.status === 404) {
					reject(new Error(`API endpoint '${api}' not found.`));
				} else {
					response.text().then((text) => {
						if (text.length) {
							if (text.includes('CurrentUserId-is-not-member') || text.includes('OrganizationId-not-found')) {
								window.localStorage.clear();
								window.location.href = '/';
							} else {
								reject(new Error('API endpoint ' + api + ' text: ' + text));
							}
						}
					});
				}
			})
			.catch((err) => {
				console.error('[Common]', api, err);

				reject(new Error('It looks like our servers have a problem. Our developers are on it!'));
			});
	});
};

export class DefaultResponse {
	errors: string[];
}
