import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

export const AUTH_TOKEN = 'shared-storage:google_token';

//GA
export const PROPERTY_ID_GA = 'google-ga4:ga4_property_id';
export const LIST_PROPERTIES_GA = 'google-ga4:ga4_list_properties';
export const LAST_WEEK_DAY_SESSIONS_REPORT_GA = 'google-ga4:last_week_days_report';
export const LAST_THIRTY_DAY_SESSIONS_REPORT_GA = 'google-ga4:last_thirty_days_report';
export const LAST_NINETY_DAY_SESSIONS_REPORT_GA = 'google-ga4:last_ninety_days_report';
export const LAST_YEAR_DAY_SESSIONS_REPORT_GA = 'google-ga4:last_year_days_report';
export const SESSIONS_REPORT_GA = 'google-ga4:chart_sessions';
export const CHART_SESSIONS_COUNTRY_GA = 'google-ga4:chart_sessions_country';
export const CHART_LANDINGPAGE_GA = 'google-ga4:chart_landing_page';
export const CHART_EXITPAGE_GA = 'google-ga4:chart_exit_page';
export const CHART_GENDER_GA = 'google-ga4:chart_gender';
export const CHART_AGE_GA = 'google-ga4:chart_age';
export const CHART_BROWSERS_GA = 'google-ga4:chart_browsers';
export const CHART_DEVICES_GA = 'google-ga4:chart_devices';
export const CHART_SOURCES_GA = 'google-ga4:chart_sources';
export const CHART_MEDIUMS_GA = 'google-ga4:chart_mediums';

export const TABLE_ID_GA = connectorStorageKeys.googleGa4.report.last30DaysTopPageViews;

//UA
export const PROPERTY_ID_UA = 'google-ua:ua_property_id';
export const LIST_PROPERTIES_UA = 'google-ua:ua_list_properties';

export const LAST_YEAR_DAILY_REPORT = 'google-search:last_365_days_daily_report';
export const LAST_7_DAYS_REPORT_PROGRESS = 'google-search:last_7_days_report_progress';
export const LAST_30_DAYS_REPORT_PROGRESS = 'google-search:last_30_days_report_progress';
export const LAST_90_DAYS_REPORT_PROGRESS = 'google-search:last_90_days_report_progress';
export const LAST_365_DAYS_REPORT_PROGRESS = 'google-search:last_365_days_report_progress';
