import { useState } from 'react';

// Externals
import { alpha, List, ListItem, ListItemText, Pagination, Stack } from '@mui/material';
import { SettingsBackupRestore } from '@mui/icons-material';

import toast from 'react-hot-toast';
import { t } from '@lingui/macro';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Slices
import {
	useGetIgnoredQuestQuery,
	useGetQuestIgnoredCountQuery,
	useIgnoreQuestOccurrenceMutation
} from 'components/legacy/services/quests';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
	tooltip: string;
	isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [pageSkip, setPage] = useState(1);
	const [questSkip, setQuestSkip] = useState(0);
	const [questTake, setQuestTake] = useState(5);

	const { data: ignoredQuest, isLoading: loadingIgnoredQuests } = useGetIgnoredQuestQuery({ questSkip, questTake });
	const { data: ignoredCount, isLoading: loadingIgnoredCount } = useGetQuestIgnoredCountQuery({ organizationId });

	const [IgnoreQuestOccurrence] = useIgnoreQuestOccurrenceMutation();

	const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
		setQuestSkip((value - 1) * questTake);
		setPage(value);
	};

	const handleCancelIgnored = async (questOccurrenceId, title) => {
		await IgnoreQuestOccurrence({ organizationQuestId: questOccurrenceId, isIgnored: false })
			.unwrap()
			.then(async (r) => {
				if (r.errors.length > 0) {
					toast.error(t`Erreur`);
				} else {
					toast.success(title + ' ' + t`remise dans la liste`);
				}
			});
	};

	const description = ignoredQuest?.data?.questItems?.length === 0 ? t`Vous n'avez ignoré aucune tâche` : '';

	return (
		<>
			<Header
				isScoreVisible={props.isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={props.name}
				tooltip={props.tooltip}
			/>
			<List component="nav">
				{ignoredQuest?.data?.questItems?.map((quest: any, index) => (
					<Stack
						alignItems={'center'}
						flexDirection={'row'}
						justifyContent={'center'}
						key={quest.title + index}
						sx={{ mb: 2 }}
					>
						<SettingsBackupRestore
							color="primary"
							onClick={() => handleCancelIgnored(quest.id, quest.title)}
							sx={{ cursor: 'pointer', width: '36px', height: '36px', mr: 1, ml: 1 }}
							titleAccess={t`Désignorer`}
						/>
						<ListItem
							sx={{ backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05), borderRadius: '30px' }}
						>
							<ListItemText primary={quest.title} />
						</ListItem>
					</Stack>
				))}
			</List>

			{ignoredQuest?.data?.questItems?.length === 0 ? (
				''
			) : (
				<Pagination
					color="primary"
					count={ignoredCount?.data?.questsCount ? Math.ceil(ignoredCount?.data?.questsCount / questTake) : 1}
					onChange={handleChangePage}
					page={pageSkip}
					sx={{ display: 'flex', justifyContent: 'center' }}
				/>
			)}
		</>
	);
};

class IgnoredQuest implements ICustomBlock {
	name = () => t`Tâches ignorées`;
	description = () => '';
	key = () => 'quest-ignored-display';
	tooltip = () =>
		t`Vous avez ignoré les tâches suivantes. Elles ne vous seront donc plus jamais reproposées dans vos tâches hebdomadaires, sauf si vous cliquez sur le bouton "Désignorer".`;
	isScoreVisible = () => false;
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}
export default IgnoredQuest;
