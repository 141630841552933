import { t } from '@lingui/macro';

import { Header } from 'components/legacy/pages/audit/questions/header';

import { ICustomBlock, UserComponentProps } from '../..';
import KeyWordsTable from './gs-keywords';

interface ComponentProps {
	name: string;
	description: string;
	tips: string;
	isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	return (
		<>
			<Header
				isScoreVisible={props.isScoreVisible}
				label={props.description}
				organizationId={organizationId}
				title={props.name}
				tooltip={props.tips}
			/>
			<KeyWordsTable />
		</>
	);
};

class GoogleAdsKeyWords implements ICustomBlock {
	tips = () => ``;
	description = () => ``;
	name = () => t`Performances GoogleAds par mots-clés`;
	key = () => 'google-ads-keywords';
	isScoreVisible = () => false;
	UserComponent: React.FC<UserComponentProps> = () => (
		<UserComponent
			description={this.description()}
			name={this.name()}
			tips={this.tips()}
			isScoreVisible={this.isScoreVisible()}
		/>
	);
}

export default GoogleAdsKeyWords;
