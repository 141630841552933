import { t } from '@lingui/macro';
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	TableFooter,
	TablePagination,
	ToggleButtonGroup,
	ToggleButton,
	TableHead,
	Theme,
	useMediaQuery
} from '@mui/material';
import NetworkIcon, { networks } from 'components/legacy/components/network';
import PublicationTableHead from './header';
import { useEffect, useRef, useState } from 'react';
import { round } from 'lodash';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import useStorageValue from 'components/legacy/hooks/useStorageValue';

import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';
import ImageWithSvgOverlay from 'components/legacy/components/blocks/publications/publication-table/components/ImageWithSvgOverlay';
import renderTextWithHashtagsAndMentions from 'components/legacy/components/blocks/publications/publication-table/components/RenderTextWithTagsAndMentions';
import useMoment from 'components/legacy/hooks/useMoment';
interface PubsDetailsProps {
	row: any;
	index: number;
}

/**
 * PublicationTableDesktop component
 *
 * @param {SocialAds[]} rows - Data displayed on the table page.
 * @param {number} totalRows - Number of available items.
 * @param {number} pageSize - Number of items displayed per page.
 * @param {number} pageNumber - Number of pages.
 * @param {string} sortColumn - Name of the sorted column.
 * @param {string} sortOrder - Column sort order (desc or asc).
 * @param {array} filterAvailable - Filters available for the table.
 * @param {function} handleChangePage - Action triggered when the user changes the page.
 * @param {function} handleChangeRowsPerPage - Action triggered when the user changes the number of items per page.
 * @param {function} handleChangeFilterNetwork - Action triggered when filtering by network the table.
 * @param {function} handleChangeFilterStatus - Action triggered when filtering by status the table.
 * @param {function} handleRequestSort - Action triggered when changing the sort column.
 * @version 1.0.0 [DCH] - Initial version of the PublicationTableDesktop component.
 */
const PublicationTableDesktop = ({
	rows,
	totalRows,
	pageSize,
	pageNumber,
	sortColumn,
	sortOrder,
	filterAvailable,
	handleChangePage,
	handleChangeRowsPerPage,
	handleChangeFilterNetwork,
	handleChangeFilterStatus,
	handleRequestSort
}) => {
	const [networkFilter, setNetworkFilter] = useState<string[]>([]);
	const [alignment, setAlignment] = useState<string>('ALL');
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const moment = useMoment();
	const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

	const [linkedinAdAccount] = useStorageValue<any>(connectorStorageKeys.linkedin.adAccount, organizationId);
	const [faceBookAdAccount] = useStorageValue<any>(connectorStorageKeys.facebook.adAccount, organizationId);
	const [hoveredLinkId, setHoveredLinkId] = useState(null);

	const getAdAccount = (network: string): any => {
		switch (network) {
			case 'linkedin':
				return linkedinAdAccount;
			case 'facebook':
				return faceBookAdAccount;
		}
	};

	const handleLinkMouseEnter = (id) => {
		setHoveredLinkId(id);
	};

	const handleLinkMouseLeave = () => {
		setHoveredLinkId(null);
	};

	const handleClickFilterStatus = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		if (newAlignment === null) return;
		setAlignment(newAlignment);
		handleChangeFilterStatus(newAlignment);
	};

	const handleClickFilterNetwork = (tag: string) => {
		initialRender.current = false;

		if (networkFilter?.includes(tag)) {
			setNetworkFilter((current) => current.filter((x) => x !== tag));
		} else {
			setNetworkFilter((current) => [...current, tag]);
		}
	};
	const initialRender = useRef(true);
	useEffect(() => {
		if (!initialRender.current) {
			handleChangeFilterNetwork(networkFilter);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [networkFilter]);

	const isFiltered = (tag: string) => {
		return networkFilter?.includes(tag);
	};
	const PubsDetails: React.FC<PubsDetailsProps> = ({ row, index }) => {
		return (
			<Box
				sx={{
					margin: 0,
					padding: 0,
					maxWidth: 'auto',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'left',
					align: 'left'
				}}
			>
				<a
					id={index.toString()}
					style={{
						textDecoration: index === hoveredLinkId ? 'underline' : 'none',
						color: 'inherit',
						cursor: 'pointer'
					}}
					onMouseEnter={() => handleLinkMouseEnter(index)}
					onMouseLeave={handleLinkMouseLeave}
					target="_blank"
					rel="noopener noreferrer"
					href={row.url}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							align: 'center',
							verticalAlign: 'center',
							margin: 0
						}}
					>
						<Box
							sx={{
								width: '100%',
								maxWidth: '85px',
								height: '100%',
								align: 'center',
								verticalAlign: 'center',
								margin: 0
							}}
						>
							{row.imgPreview ? (
								<Box
									sx={{
										width: '85px',
										height: '70px',
										margin: '0px',
										align: 'center',
										verticalAlign: 'center'
									}}
								>
									<ImageWithSvgOverlay imagePath={row.imgPreview} svgUrl={networks[row.network].logo} alt="Post" />
								</Box>
							) : (
								<Box
									sx={{
										width: '100%',
										maxWidth: '85px',
										height: '100%',
										align: 'center',
										verticalAlign: 'center',
										margin: 0
									}}
								>
									<NetworkIcon networkName={row.network} />
								</Box>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								maxWidth: upLg ? '400px' : '250px'
							}}
						>
							{row.status === 'ACTIVE' ? (
								<PlayCircleOutline color="success" />
							) : (
								<PauseCircleOutline color="disabled" />
							)}

							<Typography
								sx={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									display: 'inline-block',
									maxHeight: '1.5em',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 1
								}}
							>
								{renderTextWithHashtagsAndMentions(
									row.network,
									row.text,
									t`Publicité ` +
										' ' +
										networks[row.network]?.title +
										' ' +
										t` du ` +
										' ' +
										`${moment.unix(row.publishedTime / 1000).format('L')}`,
									true
								)}
							</Typography>
						</Box>
					</Box>
				</a>
			</Box>
		);
	};

	return rows === null || rows?.length === 0 ? (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					pb: 2
				}}
			>
				<ToggleButtonGroup color="primary" exclusive={true} onChange={handleClickFilterStatus} value={alignment}>
					<ToggleButton value="ACTIVE">{t`Active`}</ToggleButton>
					<ToggleButton value="ALL">{t`Toutes`}</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Typography variant="body2">{t`Pas de données pour le moment.`}</Typography>
		</>
	) : (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					pb: 2
				}}
			>
				<ToggleButtonGroup color="primary" exclusive={true} onChange={handleClickFilterStatus} value={alignment}>
					<ToggleButton value="ACTIVE">{t`Active`}</ToggleButton>
					<ToggleButton value="ALL">{t`Toutes`}</ToggleButton>
				</ToggleButtonGroup>
			</Box>

			{filterAvailable?.length > 1 && (
				<Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
					<Typography variant="caption">
						<p>{t`filtrer par:`}</p>
					</Typography>
					{filterAvailable.map((tag, index) => (
						<NetworkIcon
							key={index}
							networkName={tag}
							onClick={() => handleClickFilterNetwork(tag)}
							disable={isFiltered(tag)}
						/>
					))}
				</Box>
			)}

			<TableContainer>
				<Table size="small">
					<TableHead>
						<PublicationTableHead
							onRequestSort={handleRequestSort}
							sortColumn={sortColumn}
							sortOrder={sortOrder}
						></PublicationTableHead>
					</TableHead>

					<TableBody>
						{rows?.map((row: any, index) => (
							<TableRow key={row.adsId}>
								<TableCell>
									<PubsDetails row={row} index={index} />
								</TableCell>
								<TableCell align="right">
									{getAdAccount(row.network)?.value?.currency
										? new Intl.NumberFormat('be-FR', {
												style: 'currency',
												currency: getAdAccount(row.network)?.value?.currency
										  }).format(row.spent)
										: new Intl.NumberFormat().format(round(row.spent, 2))}
								</TableCell>
								<TableCell align="right">{new Intl.NumberFormat().format(row.impressions)}</TableCell>
								<TableCell align="right">{new Intl.NumberFormat().format(row.clicks)}</TableCell>
								<TableCell align="right">
									{getAdAccount(row.network)?.value?.currency
										? new Intl.NumberFormat('be-FR', {
												style: 'currency',
												currency: getAdAccount(row.network).value.currency
										  }).format(row.cpc)
										: new Intl.NumberFormat().format(round(row.cpc, 2))}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20, 30]}
								colSpan={6}
								count={totalRows}
								rowsPerPage={pageSize}
								page={pageNumber}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage={t`Nombre de pubs par page`}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
};
export default PublicationTableDesktop;
