// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const cyberPlanApi = createApi({
	reducerPath: 'cyberPlanApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		generateCyberPlan: build.mutation({
			query: (body: { budget: number }) => ({
				url: `/api/cyberplan/audit`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useGenerateCyberPlanMutation } = cyberPlanApi;
