import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Services

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys, { connectorStorageProperties } from 'components/legacy/connectors/connectorStorageKeys';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import ConnectedEntitySelection from 'components/legacy/components/connected-entity-selection';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';
import { useConnectInstagramMutation, useRemoveConnectionMutation } from 'components/legacy/services/connectors';
import { useDeleteSelectTokenUserMutation } from 'components/legacy/services/organizations';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
	tooltip: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
		organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
		userId: userProfile?.data?.userProfile?.id
	});
	const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
		roleId: role?.data?.member?.roleId
	});

	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [connectionStatus] = useStorageValue<string>(connectorStorageKeys.instagram.connectionStatus, organizationId);
	const [getAcessToken] = useStorageValueUser<any>(connectorStorageKeys.instagram.accessToken, 0);
	const [tokenSelectUser] = useStorageValue<string>(connectorStorageKeys.instagram.selectedUserId, organizationId);

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;

	const accessToken = getAcessToken.value ? getAcessToken.value.accessToken : null;

	const [connectInstagram, { isLoading: loadingConnect }] = useConnectInstagramMutation();
	const [removeConnection, { isLoading: loadingDisconnect }] = useRemoveConnectionMutation();
	const [deleteSelectTokenUser, { isLoading: loadingDeleteToken }] = useDeleteSelectTokenUserMutation();

	const handleLogin = () => {
		window.FB.login(
			function ({ authResponse, status }) {
				if (status === 'connected') {
					onAuthenticated(authResponse);
				}
			},
			{
				scope: 'instagram_basic, instagram_manage_insights',
				return_scopes: true
			}
		);
	};

	const handleLogout = async () => {
		if (tokenSelectUser.value) await deleteSelectTokenUser({ namespace: 'instagram', organizationId: organizationId });
		await removeConnection({
			connectorAuthName: 'instagram'
		});
	};

	const onAuthenticated = async (response) => {
		await connectInstagram({
			token: response.accessToken,
			userId: response.userID
		});
	};

	return (
		<>
			<Header
				isScoreVisible={true}
				label={''}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>
			{accessToken ? (
				<>
					{!tokenSelectUser.value ||
					tokenSelectUser.value !== userProfile?.data?.userProfile.id ||
					!connectionExpired ? (
						<ConnectedEntitySelection
							connector="instagram"
							connectedEntityPropKey={connectorStorageProperties.connectedEntities}
							selectedEntityPropKeys={connectorStorageProperties.selectedEntity}
						/>
					) : (
						''
					)}
					{!tokenSelectUser.value || connectionExpired ? (
						<>
							<Typography sx={{ pb: 2 }} variant="body2">
								{connectionExpired &&
								tokenSelectUser.value &&
								tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
									<Typography sx={{ color: (theme) => theme.palette.red.main }}>
										<Trans>
											Il semble que votre session Instagram ait expiré. Vous devez vous reconnecter à votre compte.
										</Trans>
									</Typography>
								) : accessToken ? (
									<Trans>
										Si le compte de votre organisation n'apparait pas dans la liste, reconnectez votre compte Instagram,
										et sélectionnez tous vos comptes disponibles. Vous pourrez ensuite choisir celui visible par les
										membres de cette organisation.
									</Trans>
								) : (
									<Trans>Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.</Trans>
								)}
							</Typography>
							<LoadingButton
								disabled={
									loadingConnect ||
									loadingDisconnect ||
									!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
										? true
										: false
								}
								loading={loadingConnect || loadingDisconnect}
								onClick={() => (!connectionExpired && accessToken ? handleLogout() : handleLogin())}
								startIcon={
									<Box
										component="img"
										src="/static/icons/brand/instagram_color.png"
										sx={{
											width: '24px',
											height: '24px'
										}}
									/>
								}
								sx={{
									borderColor: (theme) => theme.palette.text.primary,
									border: 1.5,
									color: (theme) => theme.palette.text.primary,
									borderRadius: '18px',
									fontFamily: 'Helvetica, Arial, sans-serif',
									py: 0.5,
									px: 1.5,
									whiteSpace: 'nowrap',
									maxWidth: 'max-content',
									height: '44px'
								}}
							>
								{!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Instagram`}
							</LoadingButton>
						</>
					) : (
						''
					)}
				</>
			) : (
				<>
					<Typography sx={{ pb: 2 }} variant="body2">
						{connectionExpired &&
						tokenSelectUser.value &&
						tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
							<Typography sx={{ color: (theme) => theme.palette.red.main }}>
								<Trans>
									Il semble que votre session Instagram ait expiré. Vous devez vous reconnecter à votre compte.
								</Trans>
							</Typography>
						) : accessToken ? (
							<Trans>
								Si le compte de votre organisation n'apparait pas dans la liste, reconnectez votre compte Instagram, et
								sélectionnez tous vos comptes disponibles. Vous pourrez ensuite choisir celui visible par les membres de
								cette organisation.
							</Trans>
						) : (
							<Trans>Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.</Trans>
						)}
					</Typography>
					<LoadingButton
						disabled={
							loadingConnect ||
							loadingDisconnect ||
							!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
								? true
								: false
						}
						loading={loadingConnect || loadingDisconnect}
						onClick={() => (!connectionExpired && accessToken ? handleLogout() : handleLogin())}
						startIcon={
							<Box
								component="img"
								src="/static/icons/brand/instagram_color.png"
								sx={{
									width: '24px',
									height: '24px'
								}}
							/>
						}
						sx={{
							borderColor: (theme) => theme.palette.text.primary,
							border: 1.5,
							color: (theme) => theme.palette.text.primary,
							borderRadius: '18px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							py: 0.5,
							px: 1.5,
							whiteSpace: 'nowrap',
							maxWidth: 'max-content',
							height: '44px'
						}}
					>
						{!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Instagram`}
					</LoadingButton>
				</>
			)}
		</>
	);
};

class InstagramRecap implements ICustomBlock {
	description = () => t`Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.`;
	name = () => t`Instagram`;
	key = () => 'instagram-recap';
	tooltip =
		() => t` Connectez un compte personnel administrateur de la page Instagram professionnelle de l'entreprise pour avoir accès à ses chiffres clés , tels que le nombre de followers, le nombre de visites sur votre page et le nombre de likes, et des graphes de leur évolution. 
    FreewayTeam ne partage pas vos données avec des tiers, ne fait aucune action avec votre compte entreprise, et n'a pas accès à votre compte privé. Cette connexion est validée et contrôlée par Meta (Instagram), et les données sont supprimées de FreewayTeam si vous déconnectez votre compte Instagram. 
  Vous devez d'abord connecter un compte Facebook si vous souhaitez connecter un compte Instagram. Seuls les comptes Instagram professionnels (Business) peuvent être analysés.`;
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default InstagramRecap;
