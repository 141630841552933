// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const organizationPermissionsApi = createApi({
	reducerPath: 'organizationPermissionsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		getAllOrganizationPermissions: build.query({
			query: () => `/api/permissions/all`
		})
	})
});

export const { useGetAllOrganizationPermissionsQuery } = organizationPermissionsApi;
