// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const storageApi = createApi({
	reducerPath: 'storageApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['values'],
	endpoints: (build) => ({
		fetchAllNamespaceKeys: build.query({
			query: () => `/api/storages/all-namespace-keys`
		}),
		retrieveValuesStorage: build.query({
			query: (body: { organizationId: number; keys: string[] }) => ({
				url: `/api/storages/values`,
				method: 'POST',
				body
			}),
			providesTags: ['values']
		}),
		retrieveValuesStorageByRef: build.query({
			query: (body: { reference: string; keys: string[] }) => ({
				url: `/api/storages/values-by-ref`,
				method: 'POST',
				body
			}),
			providesTags: ['values']
		}),
		retrieveAudit360ValuesStorageByRef: build.query<
			{
				data: { [key: string]: any };
				errors: string[];
			},
			{
				reference: string;
				keys: string[];
				year: string;
				month: string;
				day: string;
			}
		>({
			query: (body: { reference: string; keys: string[]; year: string; month: string; day: string }) => ({
				url: `/api/storages/audit360-values-by-ref`,
				method: 'POST',
				body
			}),
			providesTags: ['values']
		})
	})
});

export const {
	useFetchAllNamespaceKeysQuery,
	useLazyRetrieveValuesStorageQuery,
	useRetrieveValuesStorageQuery,
	useLazyRetrieveValuesStorageByRefQuery,
	useRetrieveValuesStorageByRefQuery,
	useRetrieveAudit360ValuesStorageByRefQuery,
	useLazyRetrieveAudit360ValuesStorageByRefQuery,
	util: { invalidateTags }
} = storageApi;
