import { t, Trans } from '@lingui/macro';
import { AccountCircleOutlined } from '@mui/icons-material';
import { Avatar, Box, Menu, MenuItem, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { stringAvatar } from 'components/legacy/utils/stringAvatar';
import { useDispatch } from 'components/legacy/store';
import MessageConfirm from 'components/legacy/dialogs/message-confirm';
import { SupportProfil } from 'components/legacy/utils/supportProfil';

// query
import { useGetBillingEntityQuery } from 'components/legacy/services/billing';
import { useGetOrganizationMembersQuery } from 'components/legacy/services/organizationMember';
import { useGetOrganizationDetailsQuery } from 'components/legacy/services/organizations';
import { useUpdateQuestAssignementMutation } from 'components/legacy/services/quests';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

type AssignationProps = {
	occurrenceId: number;
	questDefinitionId: any;
	questTitle: string;
	status: string;
};

export interface MemberDto {
	picture: string;
	name: string;
	userId: string;
	email: string;
}

const AssignDisplay: FC<AssignationProps> = (assignationProps) => {
	const dispatch = useDispatch();
	const orgaId = Number(window.localStorage.getItem('organizationId'));

	const [anchorEl, setAnchorEl] = useState();
	const [openMenu, setOpenMenu] = useState<any>(false);
	const [openConfirmHelp, setOpenConfirmHelp] = useState<any>(false);
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: billing, isLoading: billingLoading } = useGetBillingEntityQuery({});
	const { data: listOrganizationMembers, isLoading: loadingListOrganizationMembers } = useGetOrganizationMembersQuery({
		organizationId: organizationId
	});
	const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
		id: orgaId
	});

	const [updateQuestAssignement] = useUpdateQuestAssignementMutation();

	const [listMembers, setListMembers] = useState<MemberDto[]>(undefined);
	const isCompleted = assignationProps.status === 'completed';
	const urbiQuestSupport = assignationProps.questDefinitionId === null ? false : true;
	const handleClick = (event) => {
		if (!isCompleted && !openConfirmHelp) {
			event.stopPropagation();
			fetchMembersList();
			setAnchorEl(event.currentTarget);
			setOpenMenu(!openMenu);
		}
	};
	const UpdateAssignement = async (
		questId: number,
		organizationId: number,
		organizationUrl: string,
		userEmail: string,
		userId: string,
		supportEmail: string,
		questName: string,
		planName: string
	) => {
		await updateQuestAssignement({
			id: questId,
			organizationId: organizationId,
			organizationUrl: organizationUrl,
			userEmail: userEmail,
			supportEmail: supportEmail,
			questName: questName,
			planName: billing?.data?.entity?.plan ? billing?.data?.entity?.plan : 'trial',
			userId: userId
		});
	};
	const UpdateAssignementToSupport = async (
		questId: number,
		organizationId: number,
		organizationUrl: string,
		userEmail: string,
		userId: string,
		supportEmail: string,
		questName: string,
		planName: string,
		askHelpFromSupport: boolean
	) => {
		await updateQuestAssignement({
			id: questId,
			organizationId: organizationId,
			organizationUrl: organizationUrl,
			userEmail: userEmail,
			supportEmail: supportEmail,
			questName: questName,
			planName: billing?.data?.entity?.plan ? billing?.data?.entity?.plan : 'trial',
			userId: userId,
			askHelpFromSupport: askHelpFromSupport
		});

		setOpenConfirmHelp(false);
	};

	const fetchMembersList = async () => {
		if (!listMembers) {
			const members: MemberDto[] = listOrganizationMembers?.data?.members.map((x) => {
				const memberDto: MemberDto = {
					email: x.email,
					picture: x.picture,
					name: `${x.firstname} ${x.lastname}`.trim(),
					userId: x.id
				};

				return memberDto;
			});

			//Add support in list.
			if (urbiQuestSupport)
				members.unshift({
					userId: SupportProfil.id,
					email: SupportProfil.email,
					picture: SupportProfil.picture,
					name: `${SupportProfil.firstName} ${SupportProfil.lastName}`.trim()
				});
			setListMembers(members);
		}
	};

	const handleClose = async (event, element: MemberDto, questOccurenceId: number) => {
		event.stopPropagation();
		setOpenMenu(!openMenu);

		if (element?.userId === 'getSomeHelp') {
			setOpenConfirmHelp(true);
		} else {
			UpdateAssignement(
				assignationProps.occurrenceId,
				organizationId,
				organizationDetails?.data?.organization?.website,
				element.email,
				element.userId,
				SupportProfil.email,
				assignationProps.questTitle,
				billing?.data?.entity?.plan
			);
		}
	};
	if (isCompleted) return null;

	return (
		<div>
			<Box
				onClick={(event) => handleClick(event)}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					cursor: 'pointer'
				}}
			>
				<Menu
					anchorEl={anchorEl}
					elevation={0}
					id="basic-menu"
					open={openMenu}
					sx={{
						'& .MuiPaper-root': {},
						boxShadow: 'none'
					}}
				>
					{listMembers?.map((member, index) => (
						<div key={member.name}>
							<MenuItem
								key={member.name}
								onClick={(event) => handleClose(event, member, assignationProps.occurrenceId)}
								value={member.name}
							>
								{index === 0 && urbiQuestSupport ? (
									<Box
										alt={'demandez une aide à un coach FreewayTeam'}
										component={'img'}
										src={member.picture}
										sx={{ width: '24px', height: '24px', mx: (theme) => theme.spacing(1) }}
									/>
								) : (
									<Avatar
										alt={'organization'}
										src={member?.picture}
										sx={{
											height: (theme) => theme.spacing(3),
											width: (theme) => theme.spacing(3),
											mx: (theme) => theme.spacing(1),
											background: '#F1F1F1',
											color: '#A8A8A8'
										}}
										variant="circular"
									>
										{member.picture === '' && member.name !== '' ? (
											<Typography {...stringAvatar(`${member?.name}`)} sx={{ fontSize: 14 }} />
										) : null}
									</Avatar>
								)}
								{member.name ? member.name : member.email}
							</MenuItem>
						</div>
					))}
				</Menu>
				<Paper
					sx={{
						boxShadow: 'none',
						alignItems: 'center',
						borderRadius: 30,
						display: 'flex',
						flexDirection: 'row',
						height: (theme) => theme.spacing(5),
						minWidth: 'auto',
						maxWidth: 'auto',
						px: 1
					}}
				>
					<AccountCircleOutlined color="primary" fontSize="medium" />
					{
						<Typography
							sx={{
								mx: 1,
								color: '#817C7C',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap'
							}}
						>
							<Trans>Assigner cette tâche</Trans>
						</Typography>
					}
				</Paper>
			</Box>
			<MessageConfirm
				message={t`Un de nos coachs va prendre contact avec vous pour vous aider dans cette tâche.`}
				onAccept={() =>
					UpdateAssignementToSupport(
						assignationProps.occurrenceId,
						organizationId,
						organizationDetails?.data?.organization?.website,
						userProfile?.data?.userProfile?.email,
						userProfile?.data?.userProfile?.id,
						SupportProfil.email,
						assignationProps.questTitle,
						billing?.data?.entity?.plan,
						true
					)
				}
				onCancel={() => setOpenConfirmHelp(false)}
				open={openConfirmHelp}
				title={t`Un message va être envoyé à l'équipe FreewayTeam.`}
			></MessageConfirm>
		</div>
	);
};
export default AssignDisplay;
