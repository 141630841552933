import React, { useEffect, useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import { CircularProgress, Typography } from '@mui/material';
import Chart from 'react-google-charts';

// Components
import { Header } from 'components/legacy/pages/audit/questions/header';

// Hooks & models
import { ICustomBlock, UserComponentProps } from '../..';
import { ComponentProps } from '../../google/connect/models';
import * as StorageDataKeys from '../../google/constants';
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { StorageValue } from 'components/legacy/hooks/useStorageValue';

// Redux
import { useSelector } from 'components/legacy/store';

interface IStorageValueCollection extends Query {
	[StorageDataKeys.CHART_SESSIONS_COUNTRY_GA]: StorageValue<any>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [dataTable, setDataTable] = useState<any>([['Title', 'value']]);

	const [mapping] = useStorageValueCollection<IStorageValueCollection>(
		[StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.CHART_SESSIONS_COUNTRY_GA],
		organizationId
	);

	const loading =
		mapping[StorageDataKeys.PROPERTY_ID_GA].loading || mapping[StorageDataKeys.CHART_SESSIONS_COUNTRY_GA].loading;

	const chartDataCollectionGA = mapping[StorageDataKeys.CHART_SESSIONS_COUNTRY_GA].value;
	const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

	useEffect(() => {
		setDataTable([['Title', 'value']]); //reset Table
	}, [selectedGA]);

	useEffect(() => {
		if (selectedGA.value && chartDataCollectionGA) {
			const table = AdaptDataTableFormat(chartDataCollectionGA);
			setDataTable(table);
		}
	}, [chartDataCollectionGA, selectedGA]);

	if (loading) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					title={name}
					tooltip={tooltip}
				/>
				<CircularProgress />
			</>
		);
	}

	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={name}
				tooltip={tooltip}
			/>
			<Chart
				mapsApiKey="my_key_api"
				chartType="GeoChart"
				data={dataTable}
				height="400px"
				options={options}
				width="100%"
			/>
			{dataTable.length <= 1 && <Typography>{t`Pas de données à afficher`}</Typography>}
		</>
	);
};

export const options = {
	colorAxis: { colors: ['#C8A9FF', '#727BFE'] }
};

class GoogleGA4ChartSessionsCountry implements ICustomBlock {
	name = () => t`Pays des visiteurs`;
	description = () => t`Origine des visiteurs de ces 30 derniers jours:`;
	tooltip = () =>
		t`Google Analytics analyse de quelle région du monde viennent vos utilisateurs. Il se peut néanmoins qu'il y ait des erreurs, car certains cachent leur réelle localisation via des VPN ou autre.`;
	isScoreVisible = () => false;
	key = () => 'google-ga-chart-sessionscountry';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleGA4ChartSessionsCountry;

function AdaptDataTableFormat(chartDataCollection) {
	const table = [];
	const headers = [];

	chartDataCollection[0].forEach((element, index) => {
		headers.push(GetHeaderName(element));
	});
	table.push(headers);

	chartDataCollection.forEach((element, index) => {
		if (index === 0) return; //skip header row
		const data = [];
		element.forEach((element, index) => {
			if (index === 0) {
				//Country Name column
				data.push(element);
			} else {
				//value column
				data.push(parseInt(element));
			}
		});
		table.push(data);
	});
	return table;
}
function GetHeaderName(value: string) {
	switch (value) {
		case 'activeUsers':
		case 'ga:sessions':
			return t`Utilisateurs`;
		default:
			return value;
	}
}
