import { FC, useState } from 'react';

// Externals
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	List,
	Stack,
	TextField,
	Typography,
	useMediaQuery,
	Theme
} from '@mui/material';

import toast from 'react-hot-toast';
import { t, Trans } from '@lingui/macro';

// Store

// Services

// Components
import CheckBoxQuest from './checkbox-quest';
import BannerQuest from './banner-quest';
import {
	useAddOprhanQuestMutation,
	useCompleteQuestMutation,
	useDeleteQuestOccurrenceMutation,
	useGetOrganizationQuestItemsQuery,
	useIgnoreQuestOccurrenceMutation,
	useSkipQuestMutation,
	useUpdateQuestNoteMutation
} from 'components/legacy/services/quests';

interface TableQuestProps {
	[key: string]: any; // toutes les autres props
}

const TableQuest: FC<TableQuestProps> = (props) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const [title, setTitle] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);

	const [addOprhanQuest] = useAddOprhanQuestMutation();
	const [CompleteQuest] = useCompleteQuestMutation();
	const [DeleteQuestOccurrence] = useDeleteQuestOccurrenceMutation();
	const [IgnoreQuestOccurrence] = useIgnoreQuestOccurrenceMutation();
	const [SkipQuest] = useSkipQuestMutation();
	const [updateQuestNote] = useUpdateQuestNoteMutation();

	const { data: quests, isLoading: loadingQuests } = useGetOrganizationQuestItemsQuery({});

	const handleAddOrphanQuest = async () => {
		await addOprhanQuest({ title: title, description: description });

		setTitle('');
		setDescription('');
		setOpen(false);
	};

	const handleSkip = (questOccurrencesId: number, title: string) => {
		SkipQuest({
			questOccurrencesId: questOccurrencesId
		})
			.unwrap()
			.then((r) => {
				if (r.errors.length > 0 && r.errors.find((e) => e.includes('maximum-number-of-skipped-quests-reached'))) {
					toast.error(t`Vous avez déjà reporté le nombre maximum de tâche`);
				} else if (r.errors.length > 0) {
					toast.error(t`Erreur`);
				} else {
					toast.success(title + ' ' + t` reporté`);
				}
			});
	};

	const handleDelete = (questOccurrencesId: number, title: string) => {
		DeleteQuestOccurrence({
			occurrenceId: questOccurrencesId
		})
			.unwrap()
			.then((r) => {
				if (r.errors.length > 0) {
					toast.error(t`Erreur`);
				} else {
					toast.success(title + ' ' + t` : tâche supprimée`);
				}
			});
	};

	const handleIgnore = (questOccurrencesId: number, title: string) => {
		IgnoreQuestOccurrence({
			organizationQuestId: questOccurrencesId,
			isIgnored: true
		})
			.unwrap()
			.then((r) => {
				if (r.errors.length > 0) {
					toast.error(t`Erreur`);
				} else {
					toast.success(title + ' ' + t` : tâche ignorée`);
				}
			});
	};

	const updateNote = async (questOccurenceId: number, noteValue: string, noteOrigin: string) => {
		if (noteOrigin !== noteValue) {
			await updateQuestNote({ id: questOccurenceId, note: noteValue });
		}
	};

	const handleComplete = async (questOccurrenceId: number, isCompleted: boolean, title: string) => {
		await CompleteQuest({
			questOccurrenceId: questOccurrenceId,
			isCompleted: isCompleted
		});
	};

	return (
		<>
			<List aria-labelledby="nested-list-subheader" component="nav">
				{quests?.data?.questItems?.map((quest: any, index) =>
					quest.questDefinitionId !== null ? (
						<Stack
							alignItems={'center'}
							flexDirection={'row'}
							justifyContent={'center'}
							key={quest.title + index}
							sx={{
								width: '100%',
								mb: 2
							}}
						>
							<CheckBoxQuest
								archived={quest.archived}
								completionMode={quest.completionMode}
								handleComplete={handleComplete}
								occurrenceId={quest.occurrenceId}
								status={quest.status}
								title={quest.title}
							/>
							<BannerQuest
								custom={false}
								displayLink={props.displayLink}
								handleDelete={handleDelete}
								handleIgnore={handleIgnore}
								handleSkip={handleSkip}
								quest={quest}
								updateNote={updateNote}
							/>
						</Stack>
					) : (
						''
					)
				)}
			</List>
			<Box sx={{ my: 2 }}>
				<Typography variant="h5">
					<Trans>Créer une tâche</Trans>
				</Typography>
			</Box>
			<Stack flexDirection={isMobile ? 'column' : 'row'} alignItems="center" sx={{ mt: 1, mb: 3 }}>
				<Typography variant="body2">
					<Trans>Ajoutez une tâche spécifique selon vos critères : </Trans>
				</Typography>
				<Button
					onClick={() => setOpen(true)}
					sx={{ mt: isMobile ? 2 : 0, ml: 1, width: 'max-content' }}
					variant="contained"
				>
					<Trans>Nouvelle tâche</Trans>
				</Button>
				<Dialog
					keepMounted={true}
					onClose={() => {
						setDescription('');
						setTitle('');
						setOpen(false);
					}}
					open={open}
					maxWidth={'md'}
					fullWidth={true}
				>
					<DialogTitle>{t`Ajouter une tâche personnalisée`}</DialogTitle>
					<DialogContent>
						<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							<TextField
								label={t`Titre`}
								margin="none"
								onChange={(e) => setTitle(e.target.value)}
								size="small"
								sx={{
									display: 'flex',
									width: '100%',
									mt: 2,
									'& .MuiInputBase-input': { background: (theme) => theme.palette.background.default },
									'& .MuiTextField-root': {
										background: (theme) => theme.palette.background.default
									}
								}}
								type="text"
								value={title}
							></TextField>
							<TextField
								label={t`Description`}
								margin="none"
								multiline={true}
								name="description"
								onChange={(e) => setDescription(e.target.value)}
								rows={5}
								sx={{
									display: 'flex',
									width: '100%',
									mt: 2,
									'& .MuiInputBase-input': {
										background: (theme) => theme.palette.background.default
									},
									'& .MuiTextField-root': {
										background: (theme) => theme.palette.background.default
									}
								}}
								type="text"
								value={description}
							/>
							<Button
								disabled={title === '' || description === ''}
								onClick={() => handleAddOrphanQuest()}
								sx={{ mt: 2, width: 'max-content' }}
								variant="contained"
							>
								<Trans>Ajouter</Trans>
							</Button>
						</Box>
					</DialogContent>
				</Dialog>
			</Stack>
			{quests?.data?.questItems?.map((quest: any, index) =>
				quest.questDefinitionId === null ? (
					<Stack
						alignItems={'center'}
						flexDirection={'row'}
						justifyContent={'center'}
						key={quest.title + index}
						sx={{
							width: '100%',
							mb: 2
						}}
					>
						<CheckBoxQuest
							archived={quest.archived}
							completionMode={quest.completionMode}
							handleComplete={handleComplete}
							occurrenceId={quest.occurrenceId}
							status={quest.status}
							title={quest.title}
						/>
						<BannerQuest
							custom={true}
							displayLink={props.displayLink}
							handleDelete={handleDelete}
							handleIgnore={handleIgnore}
							handleSkip={handleSkip}
							quest={quest}
							updateNote={updateNote}
						/>
					</Stack>
				) : (
					''
				)
			)}
		</>
	);
};

export default TableQuest;
