// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { GoogleSearchHistory } from '../models';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	tooltip: string;
	questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [topTenQueriesOnLastThirtyDaysReport] = useStorageValue<GoogleSearchHistory>(
		connectorStorageKeys.googleSearch.report.topTenQueriesOnLastThirtyDays,
		organizationId
	);

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	return (
		<>
			<Header
				isScoreVisible={false}
				label={description}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>

			{topTenQueriesOnLastThirtyDaysReport.value !== null ? (
				<>
					<Paper
						sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderBottomWidth: 0 }}
						variant="outlined"
					>
						<TableContainer>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>{t`Mots clés`}</TableCell>
										<TableCell>{t`Clics`}</TableCell>
										<TableCell>{t`Impressions`}</TableCell>
										<TableCell>{t`Position moyenne`}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{_.map(topTenQueriesOnLastThirtyDaysReport.value.rows, (r, index) => (
										<TableRow key={(r.keys as any[])[0]}>
											<TableCell>{r.keys[0]}</TableCell>
											<TableCell>{r.clicks}</TableCell>
											<TableCell>{r.impressions}</TableCell>
											<TableCell>{r.position.toFixed(2)}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</>
			) : (
				<Trans>Pas de données</Trans>
			)}
		</>
	);
};

class GoogleSearchTop10 implements ICustomBlock {
	tooltip = () =>
		t`Selon le mot clé recherché, et l'endroit géographique d'où provient cette recherche, votre site apparaitra à une position différente dans l'ordre des pages suggérées. Plus votre site est haut dans la liste (proche de 1), plus il a de visibilité. La position moyenne est la moyenne pondérée de votre classement pour chaque impression.`;
	description = () => t``;
	name = () => t`Top 10 des mots clés recherchés pour votre site`;
	key = () => 'google-search-top-10';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleSearchTop10;
