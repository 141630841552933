import { Box, Avatar, Typography, Skeleton } from '@mui/material';

export const ConnectorSkeleton = () => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%' }}>
				<Skeleton height="100px" width="100%">
					<Typography>.</Typography>
				</Skeleton>
			</Box>
		</Box>
	);
};
