import { useMediaQuery, Theme, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';

interface MeetingsSchedulerProps {
	lang?: string | 'fr';
	isMobile?: boolean | false;
	landscape?: boolean | true;
	url?: string | 'https://calendly.com/contact-freewayteam/30min';
}

const MeetingsScheduler = (props: MeetingsSchedulerProps) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const theme = useTheme();

	//todo : est ce que cette url va changer, si oui ne pas l'oublier !!
	return (
		<>
			<Helmet>
				<script
					async={true}
					src="https://assets.calendly.com/assets/external/widget.js"
					type="text/javascript"
				></script>
			</Helmet>
			<div
				className="calendly-inline-widget"
				data-url={props.url}
				style={{
					border: `1px solid #A2A2A2`,
					maxWidth: isMobile ? '250px' : '600px',
					height: '1000px'
				}}
			></div>
		</>
	);
};
export default MeetingsScheduler;
