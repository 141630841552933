import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';

// Redux
import { useSelector } from 'components/legacy/store';

// Services

// Hooks
import { domainTech } from 'components/legacy/services/organizations/domain.tech';
import { useEffect, useState } from 'react';
import {
	Box,
	CircularProgress,
	Collapse,
	IconButton,
	Link,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { DomainInfo, Server, ServerTechnology, Vulnerability, Website } from 'components/legacy/models/domain-tech.dto';
import Severity from 'components/legacy/models/types/severitiesType';
import DisplayVulnerabilities from './components/DispayVulnerabilities';
import TechnologiesTable from './components/technologiesTable';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
	tooltip: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const [domainInfo, setDomainInfo] = useState<DomainInfo | null>(null);
	const [isLoading, setisLoading] = useState(true);
	const [selectedRowKey, setSelectedRowKey] = useState<string>();
	const [selectedSubRowKey, setSelectedSubRowKey] = useState<string>();

	const getVulnerabilityCountTotal = (website: Website, severity: string) =>
		website.Technologies?.reduce(
			(acc: number, technology: ServerTechnology) =>
				acc + (technology.Vulnerabilities?.filter((vuln: Vulnerability) => vuln.Severity === severity).length || 0),
			0
		);

	useEffect(() => {
		async function fetchData() {
			try {
				const result = await domainTech({ organizationId });
				const jsonData: DomainInfo = JSON.parse(result.data as string);
				setDomainInfo(jsonData);
			} catch (error) {
				console.error('Erreur lors de la récupération des données:', error);
			} finally {
				setisLoading(false);
			}
		}

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationId]);

	return (
		<>
			<Header
				isScoreVisible={false}
				label={description}
				organizationId={organizationId}
				score={0}
				title={name}
				tooltip={tooltip}
			/>
			{!isLoading ? (
				!domainInfo ? (
					<Trans>
						<p>Pas de données actuellement.</p>
					</Trans>
				) : (
					<div>
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell colSpan={2}>
											<Trans>
												<strong>Sous-domaines de </strong>
											</Trans>
											{domainInfo?.Domain}
										</TableCell>
										<TableCell align="center">
											<Trans>Technos obsolètes</Trans>
										</TableCell>
										<TableCell align="center">
											<Trans>Vulnérabilités trouvées</Trans>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{domainInfo.Servers?.map((server: Server, index) => (
										<>
											{server.Websites?.sort((WebA, WebB) => WebA.URL.localeCompare(WebB.URL)).map(
												(website: Website, websiteIndex) => (
													<>
														<TableRow key={website.URL}>
															<TableCell>
																<IconButton
																	aria-label="expand row"
																	size="small"
																	onClick={() => (
																		setSelectedRowKey(selectedRowKey === website.URL ? null : website.URL),
																		setSelectedSubRowKey(null)
																	)}
																>
																	{selectedRowKey === website.URL ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
																</IconButton>
															</TableCell>
															<TableCell align="left">
																<Link href={website.URL} target="_blank" rel="noopener" underline="hover">
																	{website.URL}
																</Link>
																<Typography variant="caption" display="block" gutterBottom>
																	({server.IP})
																</Typography>
															</TableCell>
															<TableCell align="center">
																{website.Technologies?.filter(
																	(technology: ServerTechnology) =>
																		technology.Version !== '' &&
																		technology.LatestVersion !== '' &&
																		technology.Version !== technology.LatestVersion
																)?.length ?? 0}
															</TableCell>
															<TableCell align="center">
																{Object.values(Severity).map((severity, index) => (
																	<DisplayVulnerabilities
																		key={index}
																		value={getVulnerabilityCountTotal(website, severity)}
																		severity={severity}
																	/>
																))}
																{website.Technologies?.reduce(
																	(acc: number, technology: ServerTechnology) =>
																		acc + (technology.Vulnerabilities?.length || 0),
																	0
																) === 0 || (website.Technologies?.length ?? 0) === 0
																	? 'Aucune'
																	: null}
															</TableCell>
														</TableRow>
														<TableRow>
															<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
																<Collapse in={selectedRowKey === website.URL} timeout="auto" unmountOnExit>
																	<Box
																		sx={{
																			py: 2
																		}}
																	>
																		<TechnologiesTable
																			technologies={website.Technologies}
																			selectedRowKey={selectedRowKey}
																			selectedSubRowKey={selectedSubRowKey}
																			setSelectedSubRowKey={setSelectedSubRowKey}
																		/>
																	</Box>
																</Collapse>
															</TableCell>
														</TableRow>
													</>
												)
											)}
										</>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				)
			) : (
				<CircularProgress />
			)}
		</>
	);
};

class DomainTechComponent implements ICustomBlock {
	description = () =>
		t`Voici la liste des sous-domaines liés à votre site web, leurs technologies, versions et vulnérabilités connues.`;
	name = () => t`Mises à jour et vulnérabilités des technologies`;
	key = () => 'domain-tech';
	tooltip = () =>
		t`Pour chaque sous-domaine de votre site (ex https://sousdomaine.monsite.com), nous récupérons si possible les technologies utilisées, leurs versions (que nous comparons à la dernière version existante) et les vulnérabilités de sécurité connues. Mettre à jour vos technologies évite des problèmes de cybersécurité et assure la compatibilité de votre site avec les navigateurs web.`;
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default DomainTechComponent;
