import React, { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import LaptopPng from 'assets/images/views/laptop.png';
import MobilePng from 'assets/images/views/mobile.png';
import TabletPng from 'assets/images/views/tablet.png';

// Externals
import { t } from '@lingui/macro';
import { Box, CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { config } from 'react-spring';

// Hook
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Redux
import { useSelector } from 'components/legacy/store';

import dynamic from 'next/dynamic';
import Image from 'next/image';

const TerminalComponent = dynamic(() => import('react-spring-3d-carousel'), {
	ssr: false
});

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}
const SCREENSHOT_DESKTOP = 'seo:screenshot-desktop';
const SCREENSHOT_MOBILE = 'seo:screenshot-mobile';
const SCREENSHOT_TABLET = 'seo:screenshot-tablet';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const screenshotDesktop = useStorageValue(SCREENSHOT_DESKTOP, organizationId);
	const screenshotMobile = useStorageValue(SCREENSHOT_MOBILE, organizationId);
	const screenshotTablet = useStorageValue(SCREENSHOT_TABLET, organizationId);

	const [offsetRadius, setOffsetRadius] = useState(5);
	const [showArrows, setShowArrows] = useState(false);
	const [goToSlide, setGoToSlide] = useState(null);

	useEffect(() => {
		setOffsetRadius(200);
		setShowArrows(false);
	}, []);

	if (screenshotDesktop[0].loading) {
		return (
			<>
				<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} />
				<CircularProgress />
			</>
		);
	}

	const slides = [
		{
			key: uuidv4(),
			content: (
				<div style={{ position: 'relative' }}>
					<Image alt="ordinateur" src={LaptopPng} height={isMobile ? 200 : 400} width={isMobile ? 200 : 400} />
					<Image
						unoptimized
						alt={t`Vue depuis un ordinateur`}
						src={screenshotDesktop[0].value?.toString()}
						style={{ position: 'absolute', top: '13%', left: '12%', width: '76%' }}
					/>
				</div>
			)
		},
		{
			key: uuidv4(),
			content: (
				<div style={{ position: 'relative' }}>
					<Image alt="mobile" src={MobilePng} height={400} width={200} />
					<Image
						unoptimized
						alt={t`Vue depuis un téléphone`}
						src={screenshotMobile[0].value?.toString()}
						style={{ position: 'absolute', top: '14%', left: '5.9%', width: '87%' }}
					/>
				</div>
			)
		},
		{
			key: uuidv4(),
			content: (
				<div style={{ position: 'relative' }}>
					<Image alt="tablet" src={TabletPng} height={isMobile ? 300 : 400} width={isMobile ? 300 : 300} />
					<Image
						unoptimized
						alt={t`Vue depuis une tablette`}
						src={screenshotTablet[0].value?.toString()}
						style={{ position: 'absolute', top: '4%', left: '5%', width: '90%' }}
					/>
				</div>
			)
		}
	];

	const table = slides.map((element, index) => {
		return { ...element, onClick: () => setGoToSlide(index) };
	});

	return (
		<Box>
			<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} />
			<Box sx={{ height: '500px', width: '95%', margin: '0 auto' }}>
				<TerminalComponent
					goToSlideDelay={null}
					animationConfig={config.gentle}
					goToSlide={goToSlide}
					offsetRadius={offsetRadius}
					showNavigation={showArrows}
					slides={table}
				/>
			</Box>
		</Box>
	);
};

class SEOptimerDisplayViewsRecap implements ICustomBlock {
	description = () => t`Votre site sur différents appareils.`;
	name = () => t`Rendus visuels`;
	key = () => 'seoptimer-views-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default SEOptimerDisplayViewsRecap;
