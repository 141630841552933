// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import GenericList from 'components/legacy/components/layouts/lists';

// Externals
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import _ from 'lodash';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

// We could create a custom Admin Component if necessary
// const AdminComponent = () => {
//   return <div>Hello</div>;
// };

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const MAILTESTER_BLACKLIST_PRO = 'mailtester:blacklist-pro';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps, props) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const [blacklist] = useStorageValue<any>(MAILTESTER_BLACKLIST_PRO, organizationId);

	return (
		<>
			{!isEmpty(blacklist.value) ? (
				<>
					<Header
						isScoreVisible={true}
						label={description}
						organizationId={organizationId}
						score={score}
						title={name}
					/>
					<GenericList
						data={blacklist.value.urls}
						nbrElement={`${blacklist.value.hits}`}
						title={t`Votre email apparaît dans` + ' ' + blacklist.value.hits + ' ' + t` liste(s) noire(s)`}
					/>
				</>
			) : (
				<>
					<Header
						isScoreVisible={false}
						label={description}
						organizationId={organizationId}
						score={score}
						title={name}
					/>
					<GenericList data={[]} nbrElement={'0'} title={t`Votre email n'apparaît dans aucune liste noire.`} />
				</>
			)}
		</>
	);
};

class BlackListProRecap implements ICustomBlock {
	description = () =>
		t`Nous analysons les listes noires de fournisseurs de messageries afin de repérer si l'adresse de l'email test que vous nous avez envoyé y figure.`;
	name = () => t`Blacklists`;
	key = () => 'blacklist-pro-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default BlackListProRecap;
