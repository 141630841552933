enum ConnectorAuthValues {
	running = 'running',
	succeeded = 'succeeded',
	failed = 'failed',
	closing = 'closing',
	closed = 'closed',
	expired = 'expired'
}

export default ConnectorAuthValues;
