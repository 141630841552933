import { Trans, t } from '@lingui/macro';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface CopyButtonProps {
	textToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy }) => {
	const handleCopyText = () => {
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				toast.success(t`Texte copié !`);
			})
			.catch(() => {
				toast.error(t`Échec de la copie du texte`);
			});
	};

	return (
		<>
			<Button onClick={handleCopyText} variant="contained" color="primary">
				<Trans>Copier le texte</Trans>&nbsp;
				<ContentCopyIcon />
			</Button>
		</>
	);
};

export default CopyButton;
