import { HighlightOffOutlined } from '@mui/icons-material';
import { Avatar, IconButton, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { stringAvatar } from 'components/legacy/utils/stringAvatar';

import AssignDisplay from '../assignDisplay';
import { SupportProfil } from 'components/legacy/utils/supportProfil';

// query
import { useGetBillingEntityQuery } from 'components/legacy/services/billing';
import { useGetOrganizationDetailsQuery } from 'components/legacy/services/organizations';
import { useUpdateQuestAssignementMutation } from 'components/legacy/services/quests';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

type AssignationProps = {
	occurrenceId: number;
	questTitle: string;
	status: string;
	displayIconOnly?: boolean;
	assignedTo?: any;
	isDelegated: boolean;
	AssignedToEmail: string;
	questDefinitionId: any;
};

const displayFullNameOrEmail = (firstName: string, lastName: string, email: string) => {
	if (firstName || lastName) return `${firstName} ${lastName}`;
	return email;
};

const AssignationQuest: FC<AssignationProps> = (props) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const orgaId = Number(window.localStorage.getItem('organizationId'));

	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
		id: orgaId
	});
	const { data: billing, isLoading: billingLoading } = useGetBillingEntityQuery({});

	const [updateQuestAssignement] = useUpdateQuestAssignementMutation();

	const handleClickRemoveAttribution = async (event, questOccurenceId: number) => {
		await updateQuestAssignement({
			id: questOccurenceId,
			organizationId: organizationId,
			organizationUrl: organizationDetails?.data?.organization?.website,
			userEmail: userProfile?.data?.userProfile?.email,
			userId: null,
			supportEmail: SupportProfil.email,
			questName: props.questTitle,
			planName: billing?.data?.entity?.plan ? billing?.data?.entity?.plan : 'trial'
		});
	};
	const isSupportAssigned = () => {
		return props.isDelegated && props.AssignedToEmail === 'contact@freewayteam.com';
	};

	return (
		<>
			<Paper
				sx={{
					alignItems: 'center',
					borderRadius: 30,
					display: 'flex',
					flexDirection: 'row',
					height: (theme) => theme.spacing(5),
					minWidth: 'auto',
					maxWidth: 'max-content'
				}}
			>
				{!props.assignedTo?.email ? (
					<AssignDisplay
						occurrenceId={props.occurrenceId}
						questDefinitionId={props.questDefinitionId}
						questTitle={props.questTitle}
						status={props.status}
					/>
				) : (
					<>
						{isSupportAssigned() ? (
							<SupportAgentIcon />
						) : (
							<Avatar
								src={props.assignedTo?.picture}
								sx={{
									height: (theme) => theme.spacing(3),
									width: (theme) => theme.spacing(3),
									mx: (theme) => theme.spacing(1),
									background: '#F1F1F1',
									color: ' #A8A8A8'
								}}
								variant="circular"
							>
								{props.assignedTo?.firstName || props.assignedTo?.lastName ? (
									<Typography
										{...stringAvatar(`${props.assignedTo?.firstName} ${props.assignedTo?.lastName}`)}
										sx={{ fontSize: 14 }}
									/>
								) : null}
								{/* Must be return null to display avatar by default. */}
							</Avatar>
						)}
						<Typography
							sx={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								maxWidth: '100px',
								whiteSpace: 'nowrap',
								display: 'inline-block',
								mr: 2
							}}
						>
							{isSupportAssigned()
								? displayFullNameOrEmail(SupportProfil.firstName, SupportProfil.lastName, SupportProfil.email)
								: displayFullNameOrEmail(
										props.assignedTo?.firstName,
										props.assignedTo?.lastName,
										props.assignedTo?.email
								  )}
						</Typography>
						{props.assignedTo?.email && props.status !== 'completed' && (
							<IconButton
								aria-label="delete"
								onClick={(event) => {
									event.stopPropagation();
									handleClickRemoveAttribution(event, props.occurrenceId);
								}}
							>
								<HighlightOffOutlined sx={{ color: '#EA9494' }} />
							</IconButton>
						)}
					</>
				)}
			</Paper>
		</>
	);
};

export default AssignationQuest;
