import React, { useCallback, useEffect, useState } from 'react';

// Externals
import { CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { t } from '@lingui/macro';

//Components
import AdsTableDesktop from './gs-ads-desktop';
import AdsTableMobile from './gs-ads-mobile';

// Services
import { GetSocialAdsData } from 'components/legacy/services/socialData-ads';
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import { useSignalRContext } from 'components/legacy/components/providers/SignalRProvider';

const AdsTable = () => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [rows, setRows] = useState<any>(null);
	const [pageSize, setPageSize] = useState<number>(10);
	const [pageNumber, setPageNumber] = useState<number>(0); //first page is 0

	const [totalRows, setTotalRows] = useState<number>(100);
	const [sortColumn, setSortColumn] = useState<string>('Clicks'); // Colonne de tri par default
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc'); // Ordre de tri (ascendant ou descendant)
	const [filterStatus, SetFilterStatus] = useState<string>('ALL');
	const [filterAvailable, setFilterAvailable] = useState<string[]>();
	const [filterNetwork, SetFilterNetwork] = useState<string[]>(['facebook', 'linkedin']);

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { registerSocialAdsHandler, removeSocialAdsHandler } = useSignalRContext();

	const valueChangeHandler = useCallback(async () => {
		try {
			const dataResponse = await GetSocialAdsData({
				pageSize,
				pageNumber,
				sortColumn,
				sortOrder,
				filterNetwork,
				filterStatus
			});
			if (dataResponse.errors?.length > 0) {
				throw Error(`Erreur GetSocialAdsData: ${dataResponse.errors.join(';')}`);
			}
			setRows(dataResponse.data.result);

			setTotalRows(dataResponse.data.totalItems);
			setFilterAvailable(dataResponse.data.networkAvailable);
		} catch (e) {
			console.error(t`erreur lors de la récupération des campagnes : `, e);
		} finally {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		valueChangeHandler();
	}, [valueChangeHandler, pageSize, pageNumber, sortColumn, sortOrder, organizationId]);

	useEffect(() => {
		registerSocialAdsHandler(valueChangeHandler);

		return () => {
			removeSocialAdsHandler(valueChangeHandler);
		};
	}, [registerSocialAdsHandler, removeSocialAdsHandler, valueChangeHandler]);

	const handleRequestSort = (columnId, order) => {
		setSortColumn(columnId);
		setSortOrder(order);
		setPageNumber(0);
	};

	const handleChangePage = (event, newPage) => {
		setPageNumber(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		setPageNumber(0);
	};

	const handleChangeFilterStatus = (filteredStatus: string) => {
		SetFilterStatus(filteredStatus);
		setPageNumber(0);
	};

	if (isLoading) {
		return <CircularProgress />;
	} else {
		if (isMobile) {
			return (
				<AdsTableMobile
					rows={rows}
					totalRows={totalRows}
					pageSize={pageSize}
					pageNumber={pageNumber}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
					handleRequestSort={handleRequestSort}
				/>
			);
		} else {
			return (
				<AdsTableDesktop
					rows={rows}
					totalRows={totalRows}
					pageSize={pageSize}
					pageNumber={pageNumber}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
					handleRequestSort={handleRequestSort}
					handleChangeFilterStatus={handleChangeFilterStatus}
				/>
			);
		}
	}
};

export default AdsTable;
