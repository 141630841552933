// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const choicesApi = createApi({
	reducerPath: 'choicesApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		syncChoiceTranslations: build.mutation({
			query: (body: { translations: { label: string; languageCode: string; choiceKey: string }[] }) => ({
				url: `/api/choices/translations`,
				method: 'PUT',
				body
			})
		}),
		updateChoiceIndex: build.mutation({
			query: (body: { indexes: any }) => ({
				url: `/api/choices/indexes`,
				method: 'PUT',
				body
			})
		}),
		updateChoiceValidationTranslation: build.mutation({
			query: (body: { choiceKey: string; languageCode: string; isValidate: boolean }) => ({
				url: `/api/choices/translations/validation`,
				method: 'PUT',
				body
			})
		})
	})
});

export const {
	useSyncChoiceTranslationsMutation,
	useUpdateChoiceValidationTranslationMutation,
	useUpdateChoiceIndexMutation
} = choicesApi;
