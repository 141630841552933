// Material
import { Box, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

// Externals
import toast from 'react-hot-toast';
import { t, Trans } from '@lingui/macro';

// Components
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Store
import { useSelector } from 'components/legacy/store';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	return (
		<>
			<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} />
			<Trans>
				N'hésitez pas à nous contacter si vous avez la moindre question ou recommandation, ou si vous avez un problème
				avec la plateforme.
			</Trans>

			<BoxMessage icon="email">
				<Mailto body="" email="contact@freewayteam.com" subject="">
					contact@freewayteam.com
				</Mailto>
			</BoxMessage>
		</>
	);
};

const Mailto = ({ email, subject = '', body = '', children }) => {
	let params = subject || body ? '?' : '';
	if (subject) params += `subject=${encodeURIComponent(subject)}`;
	if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

	return <a href={`mailto:${email}${params}`}>{children}</a>;
};
const BoxMessage = ({ icon, children }) => {
	return (
		<>
			<Box sx={{ pt: 4 }}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: { xs: 'column', md: 'row' },
						justifyContent: 'space-between',
						alignItems: 'center',
						background: 'rgba(94, 5, 255, 0.05);',
						borderRadius: 1,
						p: 3
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'flex-start',
							mr: { xs: 0, md: 3 },
							mb: { xs: 3, md: 0 }
						}}
					>
						<Box sx={{ flex: '0 0 auto', pt: 0.3 }}>
							<MailOutlineIcon />
						</Box>
						<Box sx={{ flex: '1 1 auto', ml: 2 }}>
							<Typography sx={{ fontSize: '1.2em' }} variant="body1">
								{children}
							</Typography>
						</Box>
					</Box>
					<Tooltip title={t`Copier dans le presse-papiers.`}>
						<ContentCopyIcon
							onClick={() => {
								navigator.clipboard.writeText('contact@freewayteam.com');
								toast.success(t`email copié.`);
							}}
							sx={{ cursor: 'pointer' }}
							titleAccess="test"
						></ContentCopyIcon>
					</Tooltip>
				</Box>
			</Box>
		</>
	);
};

class ContactUs implements ICustomBlock {
	description = () => t`Urbi service postal.`;
	name = () => t`Nous contacter par email`;
	key = () => 'contact-us';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default ContactUs;
