// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t } from '@lingui/macro';
import { Box, Grid, Paper, Typography } from '@mui/material';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	tooltip: string;
	questionKey: string;
}

const ALLBACKLINKS = 'seo:all-backlinks';
const REFERRINGDOMAINS = 'seo:referring-domains';

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);
	const [allBacklinks] = useStorageValue<any>(ALLBACKLINKS, organizationId);
	const [referringDomains] = useStorageValue<any>(REFERRINGDOMAINS, organizationId);

	return (
		<>
			<Header
				isScoreVisible={true}
				label={description}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>
			<Grid columns={{ xs: 1, sm: 8, md: 12 }} container={true} spacing={{ xs: 2, md: 3 }}>
				<Grid item={true} md={4} sm={4} xs={3}>
					<Paper
						elevation={3}
						sx={{
							pb: 4,
							position: 'relative',
							height: '100%',
							background: '#FBF9FF',
							borderRadius: '20px',
							breakInside: 'avoid'
						}}
					>
						<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h6">
							{t`Nombre de backlinks :`}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row'
							}}
						>
							<Typography
								sx={{
									color: (theme) => theme.palette.primary.main,
									textAlign: 'center',
									fontFamily: 'Inter, sans-serif',
									fontSize: 58,
									fontStyle: 'normal',
									fontWeight: 700,
									letterSpacing: 'normal',
									lineHeight: 50 / 42
								}}
							>
								{new Intl.NumberFormat().format(allBacklinks.value)}
							</Typography>
						</Box>
						<Typography sx={{ textAlign: 'center', pt: 2 }} variant="h6">
							{t`venant de`}{' '}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'row'
							}}
						>
							<Typography
								sx={{
									color: (theme) => theme.palette.text.primary,
									textAlign: 'center',
									fontFamily: 'Inter, sans-serif',
									fontSize: 44,
									fontStyle: 'normal',
									fontWeight: 700,
									letterSpacing: 'normal',
									lineHeight: 50 / 42
								}}
							>
								{new Intl.NumberFormat().format(referringDomains.value)}
							</Typography>
							<Typography sx={{ textAlign: 'center', pl: 1, pt: 2 }}>{t`domaines différents`}</Typography>
						</Box>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

class SEOTotalBacklinks implements ICustomBlock {
	tooltip = () =>
		t`Les backlinks sont des liens vers votre page. Plus ils sont nombreux, et plus leur autorité de domaine (DA) est élevée, plus votre DA augmentera et par conséquent votre classement sur les moteurs de recherche. De plus, ils amènent du trafic direct.`;
	description = () => t``;
	name = () => t`Backlinks`;
	key = () => 'seo-total-backlinks';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default SEOTotalBacklinks;
