import { t } from '@lingui/macro';
import { TableContainer, Table, TableBody, TableRow, TableCell, Box, Typography, TablePagination } from '@mui/material';
import useMoment from 'components/legacy/hooks/useMoment';
import NetworkIcon, { networks } from 'components/legacy/components/network';
import renderTextWithHashtagsAndMentions from '../components/RenderTextWithTagsAndMentions';
import { useState } from 'react';
import PublicationTableHead from './header';
import React from 'react';

const PublicationTableMobile = ({
	rows,
	totalRows,
	pageSize,
	pageNumber,
	handleChangePage,
	handleChangeRowsPerPage,
	sortColumn,
	sortOrder,
	handleRequestSort
}) => {
	const [hoveredLinkId, setHoveredLinkId] = useState(null);
	const moment = useMoment();

	const handleLinkMouseEnter = (id) => {
		setHoveredLinkId(id);
	};

	const handleLinkMouseLeave = () => {
		setHoveredLinkId(null);
	};
	const PostDetails = ({ row, index }) => {
		return (
			<Box sx={{ maxWidth: 'auto', display: 'flex', flexDirection: 'row', alignitem: 'center' }}>
				<Box sx={{ padding: 0, display: 'flex', alignItems: 'center', margin: 0 }}>
					<Typography sx={{ fontSize: 25 }}>{row.isBoosted ? '🚀' : null}</Typography>
				</Box>
				<a
					id={row.sourceType + '-' + index}
					style={{
						textDecoration: index === hoveredLinkId ? 'underline' : 'none',
						color: 'inherit',
						cursor: 'pointer'
					}}
					onMouseEnter={() => handleLinkMouseEnter(index)}
					onMouseLeave={handleLinkMouseLeave}
					target="_blank"
					rel="noopener noreferrer"
					href={row.url}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							align: 'center',
							verticalAlign: 'center'
						}}
					>
						<Box>
							<NetworkIcon networkName={row.network} />
						</Box>
						<Box sx={{ display: 'flex', verticalAlign: 'center' }}>
							<Typography
								sx={{
									fontSize: 14,
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									display: 'inline-block',
									maxWidth: '250px',
									maxHeight: '1.5em',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 1
								}}
							>
								{renderTextWithHashtagsAndMentions(
									row.network,
									row.text,
									t`Publication ` +
										' ' +
										networks[row.network]?.title +
										' ' +
										t` du ` +
										' ' +
										`${moment.unix(row.publishedTime / 1000).format('DD/MM/YY')}`,
									true
								)}
							</Typography>
						</Box>
					</Box>
				</a>
			</Box>
		);
	};

	return (
		<TableContainer sx={{ maxHeight: '500px' }}>
			<Table size="small" stickyHeader>
				<PublicationTableHead
					onRequestSort={handleRequestSort}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
				></PublicationTableHead>
				<TableBody>
					{rows === null || rows?.length === 0 ? (
						<TableRow key="empty">
							<TableCell colSpan={4} align="left" scope="row" padding="none">
								<Typography variant="body2">
									<p>{t`Aucune donnée pour le moment`}</p>
								</Typography>
							</TableCell>
						</TableRow>
					) : (
						rows?.map((row, index) => (
							<React.Fragment key={row.id}>
								<TableRow key={row.publicationId}>
									<TableCell colSpan={4} align="left" component="th" scope="row" padding="none">
										<PostDetails row={row} index={index} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell align="right" padding="none">
										{moment.unix(row.publishedTime / 1000).format('DD/MM/YY')}
									</TableCell>
									<TableCell align="right" padding="none">
										{row.impressions}
									</TableCell>
									<TableCell align="right" padding="none">
										{row.reactions}
									</TableCell>
									<TableCell align="right" padding="none">
										{row.comments}
									</TableCell>
								</TableRow>
							</React.Fragment>
						))
					)}
				</TableBody>
				{rows !== null && rows?.length > 0 && (
					<Typography variant="caption">
						<p>🚀 {t`Boostée`}</p>
					</Typography>
				)}
			</Table>
			<TablePagination
				rowsPerPageOptions={rows !== null && rows?.length > 0 ? [5, 10, 20, 30] : []}
				colSpan={4}
				count={totalRows}
				rowsPerPage={pageSize}
				page={pageNumber}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelRowsPerPage={''}
			/>
		</TableContainer>
	);
};
export default PublicationTableMobile;
