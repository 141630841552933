import { Trans, t } from '@lingui/macro';
import { LoadingButton } from '@mui/lab';
import { Box, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Stack, TextField } from '@mui/material';

import { postGenerationOptions } from '../Models/postGenerationOptions';
import { StorageValue } from 'components/legacy/hooks/useStorageValueByRef';

interface AiPostsGenerationDesktopProps {
	subjects: StorageValue<string[]>;
	handlerSendGenerationQuery: () => Promise<void>;
	isPostGenerating: boolean;
	options: postGenerationOptions;
	SetOption: React.Dispatch<React.SetStateAction<postGenerationOptions>>;
	postLengths: any;
	nbHashtags: any;
	tonalities: any;
	translationLanguages: any;
}

const AiPostsGenerationDesktop: React.FC<AiPostsGenerationDesktopProps> = ({
	subjects,
	handlerSendGenerationQuery,
	isPostGenerating,
	options,
	SetOption,
	postLengths,
	nbHashtags,
	tonalities,
	translationLanguages
}) => {
	return (
		<>
			<Stack direction="row" spacing={2} sx={{ alignItems: 'end', display: 'flex', mb: 2 }}>
				<TextField
					disabled={false}
					id="Subject"
					label={t`Sujet`}
					margin="normal"
					select={true}
					sx={{
						'& label': {
							color: '#FF0000'
						},
						mb: 0,
						width: '100%'
					}}
					value={
						subjects.value && options.subject && Object.values(subjects.value).includes(options.subject)
							? Object.keys(subjects.value)[Object.values(subjects.value).indexOf(options.subject)]
							: ''
					}
				>
					{subjects &&
						subjects.value &&
						Object.entries(subjects.value).map(([cle, valeur]) => (
							<MenuItem
								key={'Subject' + cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, subject: valeur }));
								}}
								value={cle}
							>
								{valeur}
							</MenuItem>
						))}
					{!subjects || (!subjects.value && <MenuItem key={'SubjectEmpty'} onClick={() => {}} value=""></MenuItem>)}
				</TextField>
			</Stack>
			<Stack direction="row" spacing={1} sx={{ alignItems: 'start', display: 'flex', mb: 2 }}>
				<Box sx={{ width: '100%' }}>
					<FormLabel
						sx={{ color: (theme) => theme.palette.text.primary }}
						id="postLengths-label"
					>{t`Longueur du message`}</FormLabel>
					<RadioGroup sx={{ pl: 2, pt: 1 }} aria-labelledby="postLengths-label" defaultValue={options.postLengths}>
						{Object.entries(postLengths).map(([cle, valeur]) => (
							<FormControlLabel
								key={'postLengths-label' + cle}
								control={<Radio />}
								label={valeur}
								value={cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, postLengths: cle }));
								}}
							/>
						))}
					</RadioGroup>
				</Box>
				<Box sx={{ width: '100%' }}>
					<FormLabel
						sx={{ color: (theme) => theme.palette.text.primary }}
						id="nbHashtags-label"
					>{t`Nombre de hashtags`}</FormLabel>
					<RadioGroup sx={{ pl: 2, pt: 1 }} aria-labelledby="nbHashtags-label" defaultValue={options.nbHashtags}>
						{Object.entries(nbHashtags).map(([cle, valeur]) => (
							<FormControlLabel
								key={'nbHashtags-label' + cle}
								control={<Radio />}
								label={valeur}
								value={cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, nbHashtags: cle }));
								}}
							/>
						))}
					</RadioGroup>
				</Box>
				<Box sx={{ width: '100%' }}>
					<FormLabel
						sx={{ color: (theme) => theme.palette.text.primary }}
						id="tonalities-label"
					>{t`Tonalité`}</FormLabel>
					<RadioGroup sx={{ pl: 2, pt: 1 }} aria-labelledby="tonalities-label" defaultValue={options.tonality}>
						{Object.entries(tonalities).map(([cle, valeur]) => (
							<FormControlLabel
								key={'tonalities-label' + cle}
								control={<Radio />}
								label={valeur}
								value={cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, tonality: cle }));
								}}
							/>
						))}
					</RadioGroup>
				</Box>
				<Box sx={{ width: '100%' }}>
					<FormLabel
						sx={{ color: (theme) => theme.palette.text.primary }}
						id="translationLanguages-label"
					>{t`Langue`}</FormLabel>
					<RadioGroup
						sx={{ pl: 2, pt: 1 }}
						aria-labelledby="translationLanguages-label"
						defaultValue={options.translationLanguages}
					>
						{Object.entries(translationLanguages).map(([cle, valeur]) => (
							<FormControlLabel
								key={'translationLanguages-label' + cle}
								control={<Radio />}
								label={valeur}
								value={cle}
								onClick={() => {
									SetOption((prevOptions) => ({ ...prevOptions, translationLanguages: cle }));
								}}
							/>
						))}
					</RadioGroup>
				</Box>
			</Stack>
			<Stack direction="row" spacing={2} sx={{ alignItems: 'end', display: 'flex', mb: 2 }}>
				<LoadingButton
					disabled={isPostGenerating}
					loading={isPostGenerating}
					onClick={handlerSendGenerationQuery}
					sx={{ mt: 2, width: 'max-content' }}
					variant="contained"
				>
					<Trans>{t`Générer`}</Trans>
				</LoadingButton>
			</Stack>
		</>
	);
};

export default AiPostsGenerationDesktop;
