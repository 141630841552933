// Common

import { request } from '../common';

interface requestParams {
	pageSize: number;
	pageNumber: number;
	sortColumn: string;
	sortOrder: 'asc' | 'desc';
}

export const GetKeyWords = (requestParams: requestParams): Promise<Response> =>
	request({
		method: 'GET',
		api: `/api/keywords`,
		params: {
			pageSize: requestParams.pageSize,
			pageNumber: requestParams.pageNumber,
			orderBy: requestParams.sortColumn,
			IsDescending: requestParams.sortOrder === 'desc'
		},
		authorized: true
	})
		.then((response) => response.data)
		.catch((error) => ({ data: { value: [] }, errors: [error.message] }));
interface Response {
	result(result: any): unknown;
	keywords: any;
	totalItems: number;
	errors: string[];
}
