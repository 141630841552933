import { t } from '@lingui/macro';
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	TableFooter,
	TablePagination
} from '@mui/material';
import NetworkIcon, { networks } from 'components/legacy/components/network';
import { useState } from 'react';
import PublicationTableHead from './header';
import React from 'react';
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import { useSelector } from 'components/legacy/store';
import { round } from 'lodash';
import renderTextWithHashtagsAndMentions from 'components/legacy/components/blocks/publications/publication-table/components/RenderTextWithTagsAndMentions';
import useMoment from 'components/legacy/hooks/useMoment';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';
import ImageWithSvgOverlay from 'components/legacy/components/blocks/publications/publication-table/components/ImageWithSvgOverlay';

const PublicationTableMobile = ({
	rows,
	totalRows,
	pageSize,
	pageNumber,
	handleChangePage,
	handleChangeRowsPerPage,
	sortColumn,
	sortOrder,
	handleRequestSort
}) => {
	const [hoveredLinkId, setHoveredLinkId] = useState(null);
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const [linkedinAdAccount] = useStorageValue<any>(connectorStorageKeys.linkedin.adAccount, organizationId);
	const [faceBookAdAccount] = useStorageValue<any>(connectorStorageKeys.facebook.adAccount, organizationId);
	const moment = useMoment();

	const getAdAccount = (network: string): any => {
		switch (network) {
			case 'linkedin':
				return linkedinAdAccount;
			case 'facebook':
				return faceBookAdAccount;
		}
	};

	const handleLinkMouseEnter = (id) => {
		setHoveredLinkId(id);
	};

	const handleLinkMouseLeave = () => {
		setHoveredLinkId(null);
	};
	const PostDetails = ({ row, index }) => {
		return (
			<Box sx={{ maxWidth: 'auto', display: 'flex', flexDirection: 'row', alignitem: 'center' }}>
				<a
					id={row.sourceType + '-' + index}
					style={{
						textDecoration: index === hoveredLinkId ? 'underline' : 'none',
						color: 'inherit',
						cursor: 'pointer'
					}}
					onMouseEnter={() => handleLinkMouseEnter(index)}
					onMouseLeave={handleLinkMouseLeave}
					target="_blank"
					rel="noopener noreferrer"
					href={row.url}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							align: 'center',
							verticalAlign: 'center'
						}}
					>
						<Box
							sx={{
								width: '100%',
								maxWidth: '85px',
								height: '100%',
								align: 'center',
								verticalAlign: 'center',
								margin: 0
							}}
						>
							{row.imgPreview ? (
								<Box
									sx={{
										width: '85px',
										height: '70px',
										margin: '0px',
										align: 'center',
										verticalAlign: 'center'
									}}
								>
									<ImageWithSvgOverlay imagePath={row.imgPreview} svgUrl={networks[row.network].logo} alt="Post" />
								</Box>
							) : (
								<Box
									sx={{
										width: '100%',
										maxWidth: '85px',
										height: '100%',
										align: 'center',
										verticalAlign: 'center',
										margin: 0
									}}
								>
									<NetworkIcon networkName={row.network} />
								</Box>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								maxWidth: '250px'
							}}
						>
							{row.status === 'ACTIVE' ? (
								<PlayCircleOutline color="success" />
							) : (
								<PauseCircleOutline color="disabled" />
							)}

							<Typography
								sx={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									display: 'inline-block',
									maxHeight: '1.5em',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 1
								}}
							>
								{renderTextWithHashtagsAndMentions(
									row.network,
									row.text,
									t`Publicité ` +
										' ' +
										networks[row.network].title +
										' ' +
										t` du ` +
										' ' +
										`${moment.unix(row.publishedTime / 1000).format('L')}`,
									true
								)}
							</Typography>
						</Box>
					</Box>
				</a>
			</Box>
		);
	};

	return (
		<TableContainer sx={{ maxHeight: '500px' }}>
			<Table size="small" stickyHeader>
				<PublicationTableHead
					onRequestSort={handleRequestSort}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
				></PublicationTableHead>
				<TableBody>
					{rows?.map((row, index) => (
						<React.Fragment key={row.id}>
							<TableRow key={row.publicationId}>
								<TableCell colSpan={4} align="left" component="th" scope="row" padding="none">
									<PostDetails row={row} index={index} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell align="right" padding="none">
									{getAdAccount(row.network)?.value?.currency
										? new Intl.NumberFormat('be-FR', {
												style: 'currency',
												currency: getAdAccount(row.network)?.value?.currency
										  }).format(row.spent)
										: new Intl.NumberFormat().format(round(row.spent, 2))}
								</TableCell>
								<TableCell align="right" padding="none">
									{new Intl.NumberFormat().format(row.impressions)}
								</TableCell>
								<TableCell align="right" padding="none">
									{new Intl.NumberFormat().format(row.clicks)}
								</TableCell>
								<TableCell align="right" padding="none">
									{getAdAccount(row.network)?.value?.currency
										? new Intl.NumberFormat('be-FR', {
												style: 'currency',
												currency: getAdAccount(row.network).value.currency
										  }).format(row.cpc)
										: new Intl.NumberFormat().format(row.cpc)}
								</TableCell>
							</TableRow>
						</React.Fragment>
					))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 20, 30]}
							colSpan={3}
							count={totalRows}
							rowsPerPage={pageSize}
							page={pageNumber}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							labelRowsPerPage={''}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};
export default PublicationTableMobile;
