// Externals
import { Trans } from '@lingui/macro';
import {
	Avatar,
	Button,
	CircularProgress,
	MenuItem,
	Stack,
	TextField,
	Theme,
	Typography,
	useMediaQuery
} from '@mui/material';
import ConnectorAuditValues from 'components/legacy/connectors/connectorAuditValues';
import { t } from '@lingui/macro';

// Types
import connectorStorageKeys, {
	EntityConnectorKeys,
	entityConnectorAuthConfig
} from 'components/legacy/connectors/connectorStorageKeys';

// Hooks
import useConnectedEntities from 'components/legacy/hooks/useConnectedEntities';
import useConnectorStatus from 'components/legacy/hooks/useConnectorStatus';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';
import useStorageValueCollection from 'components/legacy/hooks/useStorageValueCollection';
import { useState } from 'react';
import {
	connectorsApi,
	useDissociateSelectedEntityMutation,
	useUpdateSelectedEntityMutation
} from 'components/legacy/services/connectors';
import {
	useDeleteSelectTokenUserMutation,
	usePostSelectTokenUserMutation
} from 'components/legacy/services/organizations';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { LoadingButton } from '@mui/lab';
import { socialDataApi } from 'components/legacy/services/socialData';
import { useDispatch } from 'react-redux';
import { storageApi } from 'components/legacy/services/storages';
import { ConnectorSkeleton } from 'components/legacy/pages/freewayteam/organization-settings/tabs/connectors/skeleton-entity';

interface ConnectedEntitySelectionProps {
	selectionLabel?: string;
	connector: EntityConnectorKeys;
	connectedEntityPropKey: string;
	selectedEntityPropKeys: string;
}

const ConnectedEntitySelection = ({
	selectionLabel = t`Sélectionner un compte`,
	connector,
	connectedEntityPropKey,
	selectedEntityPropKeys
}: ConnectedEntitySelectionProps) => {
	const orgaId = Number(window.localStorage.getItem('organizationId'));
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const dispatch = useDispatch();

	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
		organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
		userId: userProfile?.data?.userProfile?.id
	});
	const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
		roleId: role?.data?.member?.roleId
	});

	const [dissociateSelectedEntity, { isLoading: loadingDeleteSelectedEntity }] = useDissociateSelectedEntityMutation();
	const [updateSelectedEntity, { isLoading: loadingUpdateSelectedEntity }] = useUpdateSelectedEntityMutation();
	const [deleteSelectTokenUser] = useDeleteSelectTokenUserMutation();
	const [postSelectTokenUser] = usePostSelectTokenUserMutation();

	const [entitiesLoading, connectedEntities, selectedEntity, updateEntity] = useConnectedEntities(
		connector,
		connectedEntityPropKey,
		selectedEntityPropKeys
	);
	const [entityId] = useState<any>();
	const [connectedEntitiesList] = useStorageValueUser<any>(connectorStorageKeys[connector][connectedEntityPropKey], 0);

	const [mapping] = useStorageValueCollection<any>(
		[
			connectorStorageKeys[connector].selectedUserId,
			connectorStorageKeys[connector].selectedEntity,
			connectorStorageKeys[connector].selectedAdEntity
		],
		orgaId
	);

	const selectedUserIdCheck = mapping[connectorStorageKeys[connector].selectedUserId];
	const selectedEntityCheck = mapping[connectorStorageKeys[connector].selectedEntity];
	const selectedAdEntityCheck = mapping[connectorStorageKeys[connector].selectedAdEntity];

	const entityConnectorAuthConfigConnector = entityConnectorAuthConfig[connector];
	const connectedEntitiesFullKey = entityConnectorAuthConfigConnector[connectedEntityPropKey];
	const selectedEntityFullKey = entityConnectorAuthConfigConnector[selectedEntityPropKeys];

	if (loadingDeleteSelectedEntity || loadingUpdateSelectedEntity) {
		return <ConnectorSkeleton />;
	}

	if (connectedEntitiesList == null) {
		return (
			<Typography>
				<Trans>Nous récupérons les entités liées à votre compte.</Trans>
			</Typography>
		);
	}

	const handleAssociateEntity = async (entityId, connector) => {
		if (selectedUserIdCheck.value === null) {
			postSelectTokenUser({
				namespace: connector,
				organizationId: orgaId,
				userId: userProfile?.data?.userProfile?.id
			}).then(async () => {
				await updateSelectedEntity({
					entityId: entityId.id,
					connector: connector,
					connectorStorageKeys: connectedEntitiesFullKey.split(':')[1]
				});
			});
		} else {
			await updateSelectedEntity({
				entityId: entityId.id,
				connector: connector,
				connectorStorageKeys: connectedEntitiesFullKey.split(':')[1]
			});
		}
		dispatch(socialDataApi.util.invalidateTags(['sn-data']));
	};

	const handleDissociateEntity = async () => {
		if (
			(selectedEntityCheck.value === null || selectedEntityCheck.key === selectedEntityFullKey) &&
			(selectedAdEntityCheck.value === null || selectedAdEntityCheck.key === selectedEntityFullKey)
		) {
			deleteSelectTokenUser({ namespace: connector, organizationId: orgaId }).then(async () => {
				await dissociateSelectedEntity({
					connector: connector,
					connectorStorageKeys: selectedEntityFullKey.split(':')[1]
				});
			});
		} else {
			await dissociateSelectedEntity({
				connector: connector,
				connectorStorageKeys: selectedEntityFullKey.split(':')[1]
			});
		}
		dispatch(socialDataApi.util.invalidateTags(['sn-data']));
	};

	if (selectedEntity)
		return (
			<Stack direction="column" sx={{ mt: 1 }}>
				<Stack
					direction={isMobile ? 'column' : 'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					sx={{ border: 1, p: 2, borderRadius: '4px', borderColor: (theme) => theme.palette.text.primary }}
				>
					<Typography sx={{ color: (theme) => theme.palette.text.primary }}>{selectedEntity.name}</Typography>
					<LoadingButton
						loading={loadingDeleteSelectedEntity}
						sx={{
							backgroundColor: 'transparent',
							borderColor: (theme) => theme.palette.text.primary,
							border: 1.5,
							color: (theme) => theme.palette.text.primary,
							borderRadius: '4px !important',
							fontFamily: 'Helvetica, Arial, sans-serif',
							py: 0.5,
							px: 1.5,
							whiteSpace: 'nowrap',
							maxWidth: 'max-content',
							height: '44px'
						}}
						onClick={() => handleDissociateEntity()}
						variant="outlined"
					>
						<Trans>Dissocier</Trans>
					</LoadingButton>
				</Stack>
			</Stack>
		);
	if (connectedEntitiesList.value) {
		return (
			<Stack direction="row" spacing={0} sx={{ alignItems: 'end', display: 'flex' }}>
				<TextField
					fullWidth
					disabled={!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer') ? true : false}
					id={'Dd_organization'}
					margin="normal"
					select={true}
					sx={{
						mb: 0
					}}
					value={entityId ? entityId.id : ''}
				>
					{connectedEntitiesList
						? connectedEntitiesList.value.map((property) => {
								return (
									<MenuItem
										key={property.id}
										onClick={() => {
											handleAssociateEntity(property, connector);
										}}
										value={property.id}
									>
										{property.name}
									</MenuItem>
								);
						  })
						: ''}
				</TextField>
			</Stack>
		);
	} else {
		return <></>;
	}
};

export default ConnectedEntitySelection;
