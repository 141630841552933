import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Trans } from '@lingui/macro';
import severitiesType from 'components/legacy/models/types/severitiesType';

const SeverityColors = {
	[severitiesType.CRITICAL]: '#780000',
	[severitiesType.HIGH]: '#dc0000',
	[severitiesType.MEDIUM]: '#fd8c00',
	[severitiesType.LOW]: '#fddc00',
	[severitiesType.NONE]: '#00a000',
	DEFAULT: '#4293f5'
};

function getDisplayName(severity: severitiesType) {
	switch (severity) {
		case severitiesType.CRITICAL:
			return <Trans>Critique</Trans>;
		case severitiesType.HIGH:
			return <Trans>Haute</Trans>;
		case severitiesType.MEDIUM:
			return <Trans>Moyenne</Trans>;
		case severitiesType.LOW:
			return <Trans>Basse</Trans>;
		case severitiesType.NONE:
			return <Trans>Aucune</Trans>;
		default:
			return severity;
	}
}

interface DisplayVulnerabilitiesProps {
	value: number;
	severity?: severitiesType;
	forceDisplay?: boolean;
	displayTag?: boolean;
	obsoleteTechnologies?: any;
}

const DisplayVulnerabilities = ({
	value,
	severity = null,
	forceDisplay = false,
	displayTag = false,
	obsoleteTechnologies
}: DisplayVulnerabilitiesProps) => {
	if (!forceDisplay && (value === undefined || value === null || severity === 'NONE')) {
		return null;
	}

	const backgroundColor = SeverityColors[severity] || SeverityColors.DEFAULT;

	return (
		<div
			style={{
				display: 'inline-flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			{displayTag && <DisplayVulnerabilitiesTag severity={severity} />}
			<Tooltip title={displayTag ? '' : getDisplayName(severity)} arrow={displayTag}>
				<Box
					sx={{
						backgroundColor,
						width: '2rem',
						height: '2rem',
						marginX: '0.2rem',
						borderRadius: '50%',
						display: 'inline-flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
					style={{ cursor: displayTag ? 'default' : 'pointer' }}
				>
					<Typography sx={{ fontWeight: 'bold', color: 'white' }}>{value}</Typography>
				</Box>
			</Tooltip>
		</div>
	);
};

interface DisplayVulnerabilitiesTagProps {
	severity?: severitiesType;
	display?: boolean;
}

const DisplayVulnerabilitiesTag = ({ severity = null, display = true }: DisplayVulnerabilitiesTagProps) => {
	if (!severity || !display) {
		return null;
	}

	const backgroundColor = SeverityColors[severity] || SeverityColors.DEFAULT;

	return (
		<Box
			sx={{
				backgroundColor,
				marginX: '0.2rem',
				marginBottom: '0.2rem',
				paddingX: '0.3rem',
				borderRadius: '4px',
				display: 'inline-flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Typography sx={{ color: 'white', fontSize: '14px' }}>{getDisplayName(severity)}</Typography>
		</Box>
	);
};

export default DisplayVulnerabilities;
export { DisplayVulnerabilitiesTag };
