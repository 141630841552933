// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Box, Grid, Paper, Typography } from '@mui/material';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import { GoogleSearchHistory } from '../models';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	tooltip: string;
	questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [lastThirtyDaysTotalReport] = useStorageValue<GoogleSearchHistory>(
		connectorStorageKeys.googleSearch.report.lastThirtyDaysTotal,
		organizationId
	);

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const impressionsLastPeriodProgress =
		lastThirtyDaysTotalReport.value &&
		(lastThirtyDaysTotalReport.value.rows.length === 2
			? lastThirtyDaysTotalReport.value.rows[1].impressions - lastThirtyDaysTotalReport.value.rows[0].impressions
			: lastThirtyDaysTotalReport.value.rows[0].impressions);

	const CTRLastPeriodProgress =
		lastThirtyDaysTotalReport.value &&
		(lastThirtyDaysTotalReport.value.rows.length === 2
			? ((lastThirtyDaysTotalReport.value.rows[1].ctr - lastThirtyDaysTotalReport.value.rows[0].ctr) * 100).toFixed(0)
			: (lastThirtyDaysTotalReport.value.rows[0].ctr * 100).toFixed(0));

	const positionLastPeriodProgress =
		lastThirtyDaysTotalReport.value &&
		(lastThirtyDaysTotalReport.value.rows.length === 2
			? (lastThirtyDaysTotalReport.value.rows[1].position - lastThirtyDaysTotalReport.value.rows[0].position).toFixed(0)
			: lastThirtyDaysTotalReport.value.rows[0].position.toFixed(0));

	return (
		<>
			<Header
				isScoreVisible={true}
				label={description}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>
			{lastThirtyDaysTotalReport.value !== null && lastThirtyDaysTotalReport.value.rows !== null ? (
				<>
					<Grid columns={{ xs: 1, sm: 8, md: 12 }} container={true} spacing={{ xs: 2, md: 3 }}>
						<Grid item={true} md={4} sm={4} xs={3}>
							<Paper
								elevation={3}
								sx={{
									p: 2,
									position: 'relative',
									height: '100%',
									background: '#FBF9FF',
									borderRadius: '20px',
									breakInside: 'avoid'
								}}
							>
								<Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
									{t`Impressions`}
								</Typography>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',

										flexDirection: 'row'
									}}
								>
									<Typography
										sx={{
											color: (theme) => theme.palette.primary.main,
											textAlign: 'center',
											fontFamily: 'Inter, sans-serif',
											fontSize: 32,
											fontStyle: 'normal',
											fontWeight: 700,
											letterSpacing: 'normal'
										}}
									>
										{lastThirtyDaysTotalReport.value.rows[1]
											? new Intl.NumberFormat().format(lastThirtyDaysTotalReport.value.rows[1].impressions)
											: new Intl.NumberFormat().format(lastThirtyDaysTotalReport.value.rows[0].impressions)}
									</Typography>
									<Typography
										sx={{
											ml: 1,
											textAlign: 'center',
											fontFamily: 'Inter, sans-serif',
											fontSize: 20,
											fontStyle: 'normal',
											fontWeight: 700,
											letterSpacing: 'normal',

											color: (theme) =>
												impressionsLastPeriodProgress === null
													? theme.palette.text.primary
													: impressionsLastPeriodProgress > 0
													? theme.palette.success.main
													: impressionsLastPeriodProgress === 0
													? theme.palette.text.primary
													: impressionsLastPeriodProgress < 0
													? theme.palette.error.main
													: theme.palette.error.main
										}}
									>
										{impressionsLastPeriodProgress === null
											? '--'
											: impressionsLastPeriodProgress > 0
											? '+' + new Intl.NumberFormat().format(impressionsLastPeriodProgress)
											: impressionsLastPeriodProgress === 0
											? '--'
											: impressionsLastPeriodProgress < 0
											? new Intl.NumberFormat().format(impressionsLastPeriodProgress)
											: new Intl.NumberFormat().format(impressionsLastPeriodProgress)}
									</Typography>
								</Box>
							</Paper>
						</Grid>
						<Grid item={true} md={4} sm={4} xs={3}>
							<Paper
								elevation={3}
								sx={{
									p: 2,
									position: 'relative',
									height: '100%',
									background: '#FBF9FF',
									borderRadius: '20px',
									breakInside: 'avoid'
								}}
							>
								<Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
									{t`CTR moyen`}
								</Typography>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',

										flexDirection: 'row'
									}}
								>
									<Typography
										sx={{
											color: (theme) => theme.palette.primary.main,
											textAlign: 'center',
											fontFamily: 'Inter, sans-serif',
											fontSize: 32,
											fontStyle: 'normal',
											fontWeight: 700,
											letterSpacing: 'normal'
										}}
									>
										{lastThirtyDaysTotalReport.value.rows[1]
											? (lastThirtyDaysTotalReport.value.rows[1].ctr * 100).toFixed(0) + ' %'
											: (lastThirtyDaysTotalReport.value.rows[0].ctr * 100).toFixed(0) + ' %'}
									</Typography>
									<Typography
										sx={{
											ml: 1,
											textAlign: 'center',
											fontFamily: 'Inter, sans-serif',
											fontSize: 20,
											fontStyle: 'normal',
											fontWeight: 700,
											letterSpacing: 'normal',

											color: (theme) =>
												CTRLastPeriodProgress === null
													? theme.palette.text.primary
													: parseInt(CTRLastPeriodProgress) > 0
													? theme.palette.success.main
													: parseInt(CTRLastPeriodProgress) === 0
													? theme.palette.text.primary
													: parseInt(CTRLastPeriodProgress) < 0
													? theme.palette.error.main
													: theme.palette.error.main
										}}
									>
										{CTRLastPeriodProgress === null
											? '--'
											: parseInt(CTRLastPeriodProgress) > 0
											? '+' + parseInt(CTRLastPeriodProgress)
											: parseInt(CTRLastPeriodProgress) === 0
											? '--'
											: parseInt(CTRLastPeriodProgress) < 0
											? CTRLastPeriodProgress
											: CTRLastPeriodProgress}{' '}
										%
									</Typography>
								</Box>
							</Paper>
						</Grid>
						<Grid item={true} md={4} sm={4} xs={3}>
							<Paper
								elevation={3}
								sx={{
									p: 2,
									position: 'relative',
									height: '100%',
									background: '#FBF9FF',
									borderRadius: '20px',
									breakInside: 'avoid'
								}}
							>
								<Typography sx={{ textAlign: 'center', pt: 1, fontWeight: 400 }} variant="h6">
									{t`Position moyenne`}
								</Typography>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',

										flexDirection: 'row'
									}}
								>
									<Typography
										sx={{
											color: (theme) => theme.palette.primary.main,
											textAlign: 'center',
											fontFamily: 'Inter, sans-serif',
											fontSize: 32,
											fontStyle: 'normal',
											fontWeight: 700,
											letterSpacing: 'normal'
										}}
									>
										{lastThirtyDaysTotalReport.value.rows[1]
											? new Intl.NumberFormat().format(
													parseInt(lastThirtyDaysTotalReport.value.rows[1].position.toFixed(0))
											  )
											: new Intl.NumberFormat().format(
													parseInt(lastThirtyDaysTotalReport.value.rows[0].position.toFixed(0))
											  )}
									</Typography>
									<Typography
										sx={{
											ml: 1,
											textAlign: 'center',
											fontFamily: 'Inter, sans-serif',
											fontSize: 20,
											fontStyle: 'normal',
											fontWeight: 700,
											letterSpacing: 'normal',

											color: (theme) =>
												parseInt(positionLastPeriodProgress) === null
													? theme.palette.text.primary
													: parseInt(positionLastPeriodProgress) > 0
													? theme.palette.success.main
													: parseInt(positionLastPeriodProgress) === 0
													? theme.palette.text.primary
													: parseInt(positionLastPeriodProgress) < 0
													? theme.palette.error.main
													: theme.palette.error.main
										}}
									>
										{parseInt(positionLastPeriodProgress) === null
											? '--'
											: parseInt(positionLastPeriodProgress) > 0
											? '+' + new Intl.NumberFormat().format(parseInt(positionLastPeriodProgress))
											: parseInt(positionLastPeriodProgress) === 0
											? '--'
											: parseInt(positionLastPeriodProgress) < 0
											? new Intl.NumberFormat().format(parseInt(positionLastPeriodProgress))
											: new Intl.NumberFormat().format(parseInt(positionLastPeriodProgress))}
									</Typography>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</>
			) : (
				<Trans>Pas de données</Trans>
			)}
		</>
	);
};

class GoogleSearchStats implements ICustomBlock {
	tooltip =
		() => t`Les impressions mesurent le nombre de fois que votre site est apparu dans les résultats de recherche sur Google. Cela ne veut pas dire que la personne a cliqué sur le lien.
  Le CTR (clicthrough rate) est le pourcentage de clics vers votre page par rapport au total des impressions lors d'une recherche sur Google.`;
	description = () => t`Progression par rapport aux 30 jours précédents`;
	name = () => t`Statistiques sur ces 30 derniers jours`;
	key = () => 'google-search-stats';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleSearchStats;
