// Externals
import type { Shadows } from '@mui/material/styles/shadows';

export const lightShadows: Shadows = [
	'none',
	'0px 0px 2px rgba(0,0,0,0.25)',
	'0px 0px 4px rgba(0,0,0,0.25)',
	'0px 0px 6px rgba(0,0,0,0.25)',
	'0px 0px 8px rgba(0,0,0,0.25)',
	'0px 0px 10px rgba(0,0,0,0.25)',
	'0px 0px 12px rgba(0,0,0,0.25)',
	'0px 0px 14px rgba(0,0,0,0.25)',
	'0px 0px 16px rgba(0,0,0,0.25)',
	'0px 0px 18px rgba(0,0,0,0.25)',
	'0px 0px 20px rgba(0,0,0,0.25)',
	'0px 0px 22px rgba(0,0,0,0.25)',
	'0px 0px 24px rgba(0,0,0,0.25)',
	'0px 0px 26px rgba(0,0,0,0.25)',
	'0px 0px 28px rgba(0,0,0,0.25)',
	'0px 0px 30px rgba(0,0,0,0.25)',
	'0px 0px 32px rgba(0,0,0,0.25)',
	'0px 0px 34px rgba(0,0,0,0.25)',
	'0px 0px 36px rgba(0,0,0,0.25)',
	'0px 0px 38px rgba(0,0,0,0.25)',
	'0px 0px 40px rgba(0,0,0,0.25)',
	'0px 0px 42px rgba(0,0,0,0.25)',
	'0px 0px 44px rgba(0,0,0,0.25)',
	'0px 0px 46px rgba(0,0,0,0.25)',
	'0px 0px 48px rgba(0,0,0,0.25)'
];

export const darkShadows: Shadows = [
	'none',
	'0px 0px 2px rgba(0,0,0,0.25)',
	'0px 0px 4px rgba(0,0,0,0.25)',
	'0px 0px 6px rgba(0,0,0,0.25)',
	'0px 0px 8px rgba(0,0,0,0.25)',
	'0px 0px 10px rgba(0,0,0,0.25)',
	'0px 0px 12px rgba(0,0,0,0.25)',
	'0px 0px 14px rgba(0,0,0,0.25)',
	'0px 0px 16px rgba(0,0,0,0.25)',
	'0px 0px 18px rgba(0,0,0,0.25)',
	'0px 0px 20px rgba(0,0,0,0.25)',
	'0px 0px 22px rgba(0,0,0,0.25)',
	'0px 0px 24px rgba(0,0,0,0.25)',
	'0px 0px 26px rgba(0,0,0,0.25)',
	'0px 0px 28px rgba(0,0,0,0.25)',
	'0px 0px 30px rgba(0,0,0,0.25)',
	'0px 0px 32px rgba(0,0,0,0.25)',
	'0px 0px 34px rgba(0,0,0,0.25)',
	'0px 0px 36px rgba(0,0,0,0.25)',
	'0px 0px 38px rgba(0,0,0,0.25)',
	'0px 0px 40px rgba(0,0,0,0.25)',
	'0px 0px 42px rgba(0,0,0,0.25)',
	'0px 0px 44px rgba(0,0,0,0.25)',
	'0px 0px 46px rgba(0,0,0,0.25)',
	'0px 0px 48px rgba(0,0,0,0.25)'
];
