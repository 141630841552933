import React from 'react';

// Externals
import Image from 'next/image';
import { Box } from '@mui/material';

const ImageWithSvgOverlay = ({ imagePath, svgUrl, alt }) => {
	const imageWidth = 70;
	const imageHeight = 70;
	return (
		<>
			<style>
				{`
        .image-container {
          position: relative;
          
        }
        
        .image-wrapper {
          position: relative;
          width: ${imageWidth}px;
          height: ${imageHeight}px;
        }
        .svg-overlay {
          position: absolute;
          bottom: 0px;
          right: 10px;
        }      
        .svg-overlay-background {
          position: absolute;
          top: 0;
          left: 0;
          width:  ${(imageWidth / 100) * 50}px;
          height: ${(imageHeight / 100) * 50}px;
        } 
        .svg-overlay-icon {
          position: relative;
          z-index: 1;
          bottom: -7px;
          right: -7px;

        }
      `}
			</style>
			<Box
				sx={{
					maxWidth: `${imageWidth + 15}px`,
					maxHeight: `${imageHeight + 15}px`,
					margin: '0px',
					align: 'center',
					verticalAlign: 'center'
				}}
			>
				<div className="image-container">
					<div className="image-wrapper">
						<Image
							unoptimized
							src={imagePath}
							alt={alt}
							style={{ width: '100%', height: '100%', objectFit: 'cover' }}
							fill={true}
						/>
					</div>
					<div className="svg-overlay">
						<svg viewBox="0 0 60 60" className="svg-overlay-background">
							<rect width="100%" height="100%" fill="white" rx="15" />
						</svg>
						<Image
							src={svgUrl}
							alt="SVG Overlay"
							className="svg-overlay-icon"
							width={(imageWidth / 100) * 37}
							height={(imageHeight / 100) * 37}
						/>
					</div>
				</div>
			</Box>
		</>
	);
};
export default ImageWithSvgOverlay;
