// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const audit360Api = createApi({
	reducerPath: 'audit360Api',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['organization-id', 'values'],
	endpoints: (build) => ({
		addAudit360Clients: build.mutation({
			query: (body: { clients: { url: string; email: string }[] }) => ({
				url: `/api/audit360/add-clients`,
				method: 'POST',
				body
			})
		}),
		getAllAudit360Clients: build.query({
			query: () => `/api/audit360/get-all`
		}),
		retrieveAudit360ReportDatesByRef: build.query<
			{
				data: { reportDates: string[] };
				errors: string[];
			},
			{
				urlReference: string;
			}
		>({
			query: (body: { urlReference: string }) => ({
				url: `/api/audit360/report-dates/${body.urlReference}`,
				method: 'POST',
				body
			}),
			providesTags: ['values']
		})
	})
});

export const {
	useAddAudit360ClientsMutation,
	useGetAllAudit360ClientsQuery,
	useRetrieveAudit360ReportDatesByRefQuery,
	util: { invalidateTags }
} = audit360Api;
