import * as React from 'react';

// Components
import ContactUs from './contactUs';
import Calendly from './calendly';
import FacebookRecap from './facebook';

import GoogleSearch from './googleSearch';
import GoogleSearchNbrClic from './googleSearch/gs-nbr-clic';
import GoogleSearchStats from './googleSearch/gs-stats';
import GoogleSearchTop10 from './googleSearch/gs-top-10';
import HIBPRecap from './haveibeenpwned';
import InstagramRecap from './instagram';
import UptimeRobotRecap from './uptimerobot';
import GoogleGA4 from './googleGA4';
import GoogleGA4Sessions from './googleGA4/sessions';
import GoogleGA4PageViews from './googleGA4/pageViews';
import GoogleGA4AvgTime from './googleGA4/avgTime';
import Youtube from './youtube';
import SocialNetworkFollowersRecap from './socialNetworkFollowers';
import SocialNetworkViewsRecap from './socialNetworkViews';
import SocialNetworkLikesRecap from './socialNetworkLikes';
import SeoBacklinks from './seoptimer/backlinks';
import SEODomainAuthority from './seoptimer/domain-authority';
import SEOTotalBacklinks from './seoptimer/total-backlinks';
import SEOptimerDisplayViewsRecap from './seoptimer/displayViews';
import LinkedInConnect from './linkedIn/linkedInConnect';
import LinkedInFollowers from './linkedIn/followers';
import YoutubeFollowers from './youtube/followers';
import YoutubeViewers from './youtube/viewers';
import Leaderboard from './leaderboard';
import MailTesterProRecap from './mailtesterpro';
import BlackListProRecap from './mailtesterpro/blacklistpro';
import MailTesterMLRecap from './mailtesterml';
import BlackListMLRecap from './mailtesterml/blacklistml';
import AltAttributeMLRecap from './mailtesterml/altattributeml';
import BrokenLinkMLRecap from './mailtesterml/brokenlinksml';
import ForbiddenTagMLRecap from './mailtesterml/forbiddentagsml';
import UnsuscribeMLRecap from './mailtesterml/unsuscribeml';
import MailTesterTransactRecap from './mailtestertransact';
import BlackListTransactRecap from './mailtestertransact/blacklisttransact';
import AltAttributeTransactRecap from './mailtestertransact/altattributetransact';
import BrokenLinkTransactRecap from './mailtestertransact/brokenlinkstransact';
import ForbiddenTagTransactRecap from './mailtestertransact/forbiddentagstransact';
import MailTesterColdRecap from './mailtestercold';
import BlackListColdRecap from './mailtestercold/blacklistcold';
import AltAttributeColdRecap from './mailtestercold/altattributecold';
import BrokenLinkColdRecap from './mailtestercold/brokenlinkscold';
import ForbiddenTagColdRecap from './mailtestercold/forbiddentagscold';
import GoogleAdsPerformanceDetails from './google-ads/performance-details';

import GoogleGA4ChartSessionsCountry from './googleGA4/chartSessionsCountry';
import GoogleGA4ChartLandingExitPage from './googleGA4/chartLandingExitPage';
import GoogleGA4ChartAgeGender from './googleGA4/chartAgeGender';
import GoogleGA4ChartBrowsersDevices from './googleGA4/chartBrowsersDevices';
import GoogleGA4ChartSourcesMediums from './googleGA4/chartSourcesMedium';
import SocialNetworkImpressionsRecap from './socialNetworkImpressions';
import QuestItemsList from './taskItems/quest';
import TaskItemsList from './taskItems/tasksList';
import TaskItemsAccomplishment from './taskItems/accomplishment';
import Report from './report';
import HistoryQuest from './taskItems/history-quest';
import IgnoredQuest from './taskItems/ignored-quest';
import Publications from './publications';
import AdsPerfomence from './ads-performance';
import PubsPerfomence from './pubs-performance';
import GoogleBusinessProfileCompleteness from './googleBusinessProfile/completeness';
import GoogleBusinessProfileReviews from './googleBusinessProfile/reviews';
import DomainTech from './domain-tech';
import GoogleAdsChart from './google-ads/performance-chart';
import GoogleAds from './google-ads';
import GoogleAdsKeyWords from './google-ads/performance-keywords';
import ActivityCenter from './ai-activity-center';
import WebSiteSummary from './ai-website-summary';
import AiPostsGeneration from './ai-posts-generation';

export interface ICustomBlock {
	description: () => string;
	name: () => string;
	key: () => string;
	UserComponent: React.FC<UserComponentProps>;
	AdminComponent?: React.FC;
}

export interface UserComponentProps {
	questionKey: string;
	name: string;
	description: string;
	toolTip: string;
}

interface BlocksContextProps {
	getBlock: (key: string) => ICustomBlock | null;
	blocks: Array<ICustomBlock>;
}

export const BlocksContext: React.Context<BlocksContextProps> = React.createContext<BlocksContextProps>({
	getBlock: () => {
		return null;
	},
	blocks: []
});

export const useBlocks = () => React.useContext(BlocksContext);

export const BlocksProvider: React.FC = (props) => {
	const [blocks] = React.useState<Array<ICustomBlock>>([
		new AdsPerfomence(),
		new AltAttributeColdRecap(),
		new AltAttributeMLRecap(),
		new AltAttributeTransactRecap(),
		new BlackListColdRecap(),
		new BlackListMLRecap(),
		new BlackListProRecap(),
		new BlackListTransactRecap(),
		new BrokenLinkColdRecap(),
		new BrokenLinkMLRecap(),
		new BrokenLinkTransactRecap(),
		new Calendly(),
		new ContactUs(),
		new FacebookRecap(),
		new ForbiddenTagColdRecap(),
		new ForbiddenTagMLRecap(),
		new ForbiddenTagTransactRecap(),
		new GoogleAdsPerformanceDetails(),
		new GoogleAdsChart(),
		new GoogleAdsKeyWords(),
		new GoogleAds(),
		new GoogleBusinessProfileCompleteness(),
		new GoogleBusinessProfileReviews(),
		new GoogleGA4(),
		new GoogleGA4AvgTime(),
		new GoogleGA4ChartAgeGender(),
		new GoogleGA4ChartBrowsersDevices(),
		new GoogleGA4ChartLandingExitPage(),
		new GoogleGA4ChartSessionsCountry(),
		new GoogleGA4ChartSourcesMediums(),
		new GoogleGA4PageViews(),
		new GoogleGA4Sessions(),
		new GoogleSearch(),
		new GoogleSearchNbrClic(),
		new GoogleSearchStats(),
		new GoogleSearchTop10(),
		new HIBPRecap(),
		new HistoryQuest(),
		new IgnoredQuest(),
		new InstagramRecap(),
		new Leaderboard(),
		new LinkedInConnect(),
		new LinkedInFollowers(),
		new MailTesterColdRecap(),
		new MailTesterMLRecap(),
		new MailTesterProRecap(),
		new MailTesterTransactRecap(),
		new Publications(),
		new PubsPerfomence(),
		new QuestItemsList(),
		new Report(),
		new SeoBacklinks(),
		new SEODomainAuthority(),
		new SEOptimerDisplayViewsRecap(),
		new SEOTotalBacklinks(),
		new SocialNetworkFollowersRecap(),
		new SocialNetworkImpressionsRecap(),
		new SocialNetworkLikesRecap(),
		new SocialNetworkViewsRecap(),
		new TaskItemsAccomplishment(),
		new TaskItemsList(),
		new UnsuscribeMLRecap(),
		new UptimeRobotRecap(),
		new Youtube(),
		new YoutubeFollowers(),
		new YoutubeViewers(),
		new DomainTech(),
		new ActivityCenter(),
		new WebSiteSummary(),
		new AiPostsGeneration()
	]);

	const getBlock = React.useCallback(
		(key: string) => {
			return blocks.find((i) => i.key() === key);
		},
		[blocks]
	);

	return React.useMemo(() => {
		return <BlocksContext.Provider value={{ getBlock, blocks }}>{props.children}</BlocksContext.Provider>;
	}, [getBlock, blocks, props.children]);
};
