import { useEffect, useState } from 'react';

// Externals
import { List, Pagination, Stack } from '@mui/material';

import { t } from '@lingui/macro';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import BannerQuest from '../quest/table-quests/banner-quest';
import CheckBoxQuest from '../quest/table-quests/checkbox-quest';

//Query
import { useGetHistoryQuestQuery, useGetQuestArchievedCountQuery } from 'components/legacy/services/quests';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
	tooltip: string;
	isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [page, setPage] = useState(1);

	const { data: historyCount } = useGetQuestArchievedCountQuery({ organizationId });
	const { data: historyQuest } = useGetHistoryQuestQuery({ pageSkip: page - 1 });

	const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const description = historyQuest?.data?.quests.length === 0 ? t`Vous n'avez encore validé aucune tâche` : '';

	return (
		<>
			<Header
				isScoreVisible={props.isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={props.name}
				tooltip={props.tooltip}
			/>
			<List component="nav">
				{historyQuest?.data?.quests.map((quest: any, index) => (
					<Stack
						alignItems={'center'}
						flexDirection={'row'}
						justifyContent={'center'}
						key={index}
						sx={{
							width: '100%',
							mb: 2
						}}
					>
						<CheckBoxQuest
							archived={quest.archived}
							completionMode={quest.completionMode}
							handleComplete={() => null}
							occurrenceId={quest.occurrenceId}
							status={quest.status}
							title={quest.title}
						/>
						<BannerQuest
							custom={false}
							displayLink={true}
							handleDelete={() => null}
							handleIgnore={() => null}
							handleSkip={() => null}
							quest={quest}
							updateNote={() => null}
						/>
					</Stack>
				))}
			</List>

			{historyQuest?.data?.quests.length === 0 ? (
				''
			) : (
				<Pagination
					color="primary"
					count={historyCount?.data?.questsCount ? Math.ceil(historyCount?.data?.questsCount / 25) : 1}
					onChange={handleChangePage}
					page={page}
					sx={{ display: 'flex', justifyContent: 'center' }}
				/>
			)}
		</>
	);
};

class HistoryQuest implements ICustomBlock {
	name = () => t`Historique des tâches réalisées`;
	description = () => '';
	key = () => 'quest-history-display';
	tooltip = () =>
		t` Voici toutes les tâches que vous avez réalisées auparavant. Elles ne sont plus modifiables. La plupart des tâches sont récurrentes, elles vous seront proposées à nouveau une autre semaine. Les tâches réalisées dans la semaine en cours apparaitront ici lundi prochain.`;
	isScoreVisible = () => false;
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}
export default HistoryQuest;
