import React, { useCallback, useEffect, useState } from 'react';

// Externals
import { CircularProgress, Theme, useMediaQuery } from '@mui/material';
import { t } from '@lingui/macro';

//Components
import KeyWordsTableDesktop from './gs-keywords-desktop';
import KeyWordsTableMobile from './gs-keywords-mobile';

// Services
import { GetKeyWords } from 'components/legacy/services/keywords';
import { GoogleAdsKeywordDTO } from 'components/legacy/models/googleAdsKeywords.dto';
import { useSignalRContext } from 'components/legacy/components/providers/SignalRProvider';

const KeyWordsTable = () => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [rows, setRows] = useState<GoogleAdsKeywordDTO[]>(null);
	const [pageSize, setPageSize] = useState<number>(10);
	const [pageNumber, setPageNumber] = useState<number>(0); //first page is 0

	const [totalRows, setTotalRows] = useState<number>(100);
	const [sortColumn, setSortColumn] = useState<string>('Clicks'); // Colonne de tri par default
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc'); // Ordre de tri (ascendant ou descendant)

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const { registerKeywordHandler, removeKeywordHandler } = useSignalRContext();

	//organization ralated values

	const handleRequestSort = (columnId, order) => {
		setSortColumn(columnId);
		setSortOrder(order);
		setPageNumber(0);
	};

	const handleChangePage = (event, newPage) => {
		setPageNumber(newPage);
	};

	const valueChangeHandler = useCallback(async () => {
		try {
			const dataResponse = await GetKeyWords({
				pageSize,
				pageNumber,
				sortColumn,
				sortOrder
			});
			if (dataResponse.errors?.length > 0) {
				throw Error(`Erreur GetKeyWords: ${dataResponse.errors.join(';')}`);
			}
			setRows(dataResponse.keywords);
			setTotalRows(dataResponse.totalItems);
		} catch (e) {
			console.error(t`erreur lors de la récupération des campagnes : `, e);
		} finally {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [GetKeyWords]);

	useEffect(() => {
		valueChangeHandler();
	}, [valueChangeHandler, pageSize, pageNumber, sortColumn, sortOrder, organizationId]);

	useEffect(() => {
		registerKeywordHandler(valueChangeHandler);

		return () => {
			removeKeywordHandler(valueChangeHandler);
		};
	}, [registerKeywordHandler, removeKeywordHandler, valueChangeHandler]);

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		setPageNumber(0);
	};

	if (isLoading) {
		return <CircularProgress />;
	} else {
		if (isMobile) {
			return (
				<KeyWordsTableMobile
					rows={rows}
					totalRows={totalRows}
					pageSize={pageSize}
					pageNumber={pageNumber}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
					handleRequestSort={handleRequestSort}
				/>
			);
		} else {
			return (
				<KeyWordsTableDesktop
					rows={rows}
					totalRows={totalRows}
					pageSize={pageSize}
					pageNumber={pageNumber}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					sortColumn={sortColumn}
					sortOrder={sortOrder}
					handleRequestSort={handleRequestSort}
				/>
			);
		}
	}
};

export default KeyWordsTable;
