import React, { useEffect, useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import { CircularProgress, Grid, Theme, useMediaQuery } from '@mui/material';
import ReactECharts from 'echarts-for-react';

// Components
import { Header } from 'components/legacy/pages/audit/questions/header';
import { ICustomBlock, UserComponentProps } from '../..';
import { ComponentProps } from '../../google/connect/models';
import * as StorageDataKeys from '../../google/constants';

// Services
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { StorageValue } from 'components/legacy/hooks/useStorageValue';

interface IStorageValueCollection extends Query {
	[StorageDataKeys.CHART_BROWSERS_GA]: StorageValue<any>;
	[StorageDataKeys.CHART_DEVICES_GA]: StorageValue<any>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const [browsersCategories, setBrowsersCategories] = useState<any>([]);
	const [browsersData, setBrowsersData] = useState<any>([]);

	const [devicesData, setDevicesData] = useState<any>([]);

	const [mapping] = useStorageValueCollection<IStorageValueCollection>(
		[StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.CHART_BROWSERS_GA, StorageDataKeys.CHART_DEVICES_GA],
		organizationId
	);

	const loading =
		mapping[StorageDataKeys.PROPERTY_ID_GA].loading ||
		mapping[StorageDataKeys.CHART_BROWSERS_GA].loading ||
		mapping[StorageDataKeys.CHART_DEVICES_GA].loading;

	const chartBrowsersGA = mapping[StorageDataKeys.CHART_BROWSERS_GA].value;
	const chartDevicesGA = mapping[StorageDataKeys.CHART_DEVICES_GA].value;
	const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

	useEffect(() => {
		if (selectedGA.value && chartBrowsersGA) {
			const browserCategoriesTmp = [];
			const browserDataTmp = [];
			chartBrowsersGA?.slice(1).map((element) => {
				browserCategoriesTmp.push(element[0]);
				browserDataTmp.push(element[1]);
			});
			setBrowsersCategories(browserCategoriesTmp.reverse());
			setBrowsersData(browserDataTmp.reverse());
		}
	}, [chartBrowsersGA, selectedGA]);

	useEffect(() => {
		if (selectedGA.value && chartDevicesGA) {
			const tmpData = [];
			chartDevicesGA?.slice(1).map((element) => {
				var newObj = {
					name: element[0],
					value: parseInt(element[1])
				};
				tmpData.push(newObj);
			});
			setDevicesData(tmpData);
		}
	}, [chartDevicesGA, selectedGA]);

	const option = {
		color: ['rgb(77, 119, 255)'],
		smooth: true,
		yAxis: {
			type: 'category',
			data: browsersCategories
		},
		toolbox: {
			feature: {
				saveAsImage: {}
			}
		},
		tooltip: {
			trigger: 'axis'
		},
		xAxis: {
			type: 'value'
		},
		grid: { containLabel: true },
		series: [
			{
				data: browsersData,
				type: 'bar',
				stack: 'total'
			}
		]
	};

	const optionsDevice = {
		smooth: true,

		tooltip: {
			trigger: 'item'
		},
		series: [
			{
				data: devicesData,
				type: 'pie',
				stack: 'total'
			}
		],
		legend: {
			orient: 'vertical',
			left: 'right'
		}
	};

	if (loading) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					title={name}
					tooltip={tooltip}
				/>
				<CircularProgress />
			</>
		);
	}
	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={name}
				tooltip={tooltip}
			/>
			<Grid container={true} spacing={2}>
				<Grid item={true} md={6} sm={12} xs={12}>
					<ReactECharts option={option} />
				</Grid>
				<Grid item={true} md={6} sm={12} xs={12}>
					<ReactECharts option={optionsDevice} />
				</Grid>
			</Grid>
		</>
	);
};

class GoogleGA4ChartBrowsersDevices implements ICustomBlock {
	name = () => t`Navigateurs et appareils`;
	description = () =>
		t`Ces 30 derniers jours, vos visiteurs ont parcouru votre site depuis les navigateurs et appareils suivant:`;
	tooltip = () =>
		t`Google Analytics analyse quelles technologies sont utilisés par vos visiteurs. Assurez-vous que son affichage fonctionne bien sur tous ceux-ci, en particulier la version mobile et les navigateurs principaux.`;
	isScoreVisible = () => false;
	key = () => 'google-ga-chart-browsersdevices';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleGA4ChartBrowsersDevices;
