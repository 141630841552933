import { useState } from 'react';

import { alpha, Box, Collapse, Divider, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface Props<T> {
	title: T;
	nbrElement: T;
	data: T[];
}

const GenericList = <T extends unknown>({ title, nbrElement, data }: Props<T>) => {
	const [openList, setOpenList] = useState(false);

	const handleOpenList = () => {
		setOpenList(!openList);
	};

	let duplicateData: boolean[] = [];
	const checkDuplicateData = (key: string) => {
		if (duplicateData[key] === undefined) {
			duplicateData[key] = true;
			return false;
		}
		return true;
	};

	return (
		<>
			<ListItemButton onClick={handleOpenList} sx={{ mt: 1 }}>
				<Box
					sx={{
						width: 40,
						height: 40,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						borderRadius: '50%',
						background: (theme) => alpha(theme.palette.primary.light, 0.4)
					}}
				>
					<Typography
						sx={{
							fontFamily: 'Inter, sans-serif',
							fontSize: 25,
							fontStyle: 'bold',
							color: 'white'
						}}
					>
						{nbrElement}
					</Typography>
				</Box>
				<Typography
					sx={{
						fontFamily: 'Inter, sans-serif',
						fontSize: 22,
						fontStyle: 'bold',
						pl: 2
					}}
				>
					{title}
				</Typography>
				{openList ? (
					<ExpandLess sx={{ right: 10, position: 'absolute' }} />
				) : (
					<ExpandMore sx={{ right: 10, position: 'absolute' }} />
				)}
			</ListItemButton>
			<Collapse in={openList} timeout="auto" unmountOnExit={true}>
				{data.map((element, index) => (
					<List
						component="div"
						disablePadding={true}
						key={checkDuplicateData(element.toString()) ? `${element.toString()}${index}` : element.toString()}
					>
						<ListItem sx={{ pl: 4 }}>
							<Typography variant="h6">{element}</Typography>
						</ListItem>
						<Divider orientation="horizontal" />
					</List>
				))}
			</Collapse>
		</>
	);
};

export default GenericList;
