import * as React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableRow
} from '@mui/material';
import { round } from 'lodash';

interface DialogDetails {
	open: boolean;
	onClose: () => void;
	data: any;
}

/**
 * AccordionExpandIcon component
 * Renders a dialog with details for a specific data item.
 * @param {AccordionDetails} props - Object containing data and control functions.
 **/
export const DialogDetails = (props: DialogDetails) => {
	// Function to add space before each capital letter
	const addSpaceBeforeCapital = (str: string) => {
		return str.replace(/([A-Z])/g, ' $1').trim();
	};

	return (
		<Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
			<DialogTitle align="left">OPTIONS</DialogTitle>
			<DialogContent>
				<Table>
					<TableBody>
						{Object.entries(props.data.metrics)
							.filter(([propertyName, _]) => !propertyName.startsWith('has'))
							.map(([propertyName, value]: [string, number]) => {
								// Définir les métriques monétaires
								const monetaryMetrics = [
									'costMicros',
									'crossDeviceConversionsValueMicros',
									'hotelAverageLeadValueMicros',
									'hotelCommissionRateMicros',
									'hotelExpectedCommissionCost',
									'costOfGoodsSoldMicros',
									'grossProfitMicros',
									'revenueMicros',
									'crossSellCostOfGoodsSoldMicros',
									'crossSellGrossProfitMicros',
									'crossSellRevenueMicros',
									'leadCostOfGoodsSoldMicros',
									'leadGrossProfitMicros',
									'leadRevenueMicros',
									'averageCost',
									'averageCpc',
									'averageCpe',
									'averageCpm',
									'averageCpv',
									'averageTargetCpaMicros',
									'averageOrderValueMicros'
								];

								// Vérifier si la métrique actuelle est une métrique monétaire
								const isMonetaryMetric = monetaryMetrics.includes(propertyName);

								return (
									<TableRow key={propertyName}>
										<TableCell sx={{ textTransform: 'lowercase' }}>{addSpaceBeforeCapital(propertyName)}</TableCell>
										<TableCell component="th" scope="row">
											{isMonetaryMetric ? (
												<Box sx={{ wordWrap: 'break-word' }}>
													{new Intl.NumberFormat().format(round(value, 2)) +
														(props.data.currencyCode === 'USD' ? ' $' : ' €')}
												</Box>
											) : (
												new Intl.NumberFormat().format(round(value, 2))
											)}
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</DialogContent>
		</Dialog>
	);
};
