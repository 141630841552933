import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import ItemRow from './item/row';
import ItemShimmer from './item/shimmer';
import Scrollbar from 'components/legacy/components/Scrollbar';

// Externals
import { t, Trans } from '@lingui/macro';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	LinearProgress,
	MenuItem,
	Skeleton,
	Stack,
	Switch,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Tooltip,
	Typography,
	useTheme
} from '@mui/material';
import _ from 'lodash';
import Countdown from 'react-countdown';

// Redux
import { useDispatch, useSelector } from 'components/legacy/store';

// Services
import { getTopTenOrganizationDetails } from 'components/legacy/services/organizations/getTopTenDetails';
import { GetLastUpdateDatetime } from 'components/legacy/services/organizations/getLastUpdateDatetime';
import {
	useGetOrganizationDetailsQuery,
	useUpdateOrganizationHiddenMutation
} from 'components/legacy/services/organizations';
import { useGetQuestionAnswersQuery } from 'components/legacy/services/questions';

// Slices

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const orgaId = Number(window.localStorage.getItem('organizationId'));

	const [updateOrganizationHidden] = useUpdateOrganizationHiddenMutation();
	const { data: organizationDetails, isLoading: loadingOrganizationDetails } = useGetOrganizationDetailsQuery({
		id: orgaId
	});
	const { data: answers, isLoading: loadingAnswers } = useGetQuestionAnswersQuery({
		auditKey: 'leaderboard',
		organizationId: orgaId
	});

	const scrollRef = React.useRef<unknown | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [refreshing, setRefreshing] = React.useState(false);
	const [data, setData] = React.useState<any[]>();
	const [districtFilter, setDistrictFilter] = React.useState<string>(localStorage.getItem('districtFilter') || '');
	const [sameCountryFilter, setSameCountryFilter] = React.useState<boolean>(
		localStorage.getItem('sameCountryFilter') === 'true'
	);
	const [sameEmployeeNumberFilter, setSameEmployeeNumberFilter] = React.useState<boolean>(
		localStorage.getItem('sameEmployeeNumberFilter') === 'true'
	);
	const [sameSectorFilter, setSameSectorFilter] = React.useState<boolean>(
		localStorage.getItem('sameSectorFilter') === 'true'
	);
	const [sameYearFilter, setSameYearFilter] = React.useState<boolean>(
		localStorage.getItem('sameYearFilter') === 'true'
	);
	const [countDownLastUpdate, setCountDownLastUpdate] = React.useState<number>(Date.now);

	const updateLastUpdate = () => {
		GetLastUpdateDatetime().then(async (response) => {
			let resp = await response.data;
			setCountDownLastUpdate(resp.lastUpdate);
		});
	};

	// Not loading before will cause a crash
	const hasAnsweredCountries = useSelector<boolean>((state) =>
		answers?.data?.answers?.some((a) => a.questionKey === 'central-countries')
	);

	const countryKey = useSelector<any>((state) => _.find(answers?.data?.answers, { questionKey: 'central-countries' }));

	const hasAnsweredEmployeeNumber = useSelector<boolean>((state) =>
		answers?.data?.answers?.some((a) => a.questionKey === 'central-employee-number')
	);

	const employeeNumberKey = useSelector<any>((state) =>
		_.find(answers?.data?.answers, { questionKey: 'central-employee-number' })
	);

	const hasAnsweredSector = useSelector<boolean>((state) =>
		answers?.data?.answers?.some((a) => a.questionKey === 'central-sector')
	);

	const sectorKey = useSelector<any>((state) => _.find(answers?.data?.answers, { questionKey: 'central-sector' }));

	const hasAnsweredYear = useSelector<boolean>((state) =>
		answers?.data?.answers?.some((a) => a.questionKey === 'central-year')
	);

	const yearKey = useSelector<any>((state) => _.find(answers?.data?.answers, { questionKey: 'central-year' }));

	const setDataWithValues = React.useCallback(async () => {
		setRefreshing(true);

		let response = await getTopTenOrganizationDetails({
			district: districtFilter,
			countryKey: sameCountryFilter && countryKey ? countryKey.choiceKey : undefined,
			employeeNumberKey: sameEmployeeNumberFilter && employeeNumberKey ? employeeNumberKey.choiceKey : undefined,
			sectorKey: sameSectorFilter && sectorKey ? sectorKey.choiceKey : undefined,
			yearKey: sameYearFilter && yearKey ? yearKey.choiceKey : undefined
		});

		setData(response.data.organizations);

		setLoading(false);
		setRefreshing(false);
	}, [
		countryKey,
		districtFilter,
		employeeNumberKey,
		sameCountryFilter,
		sameEmployeeNumberFilter,
		sameSectorFilter,
		sameYearFilter,
		sectorKey,
		yearKey
	]);

	const setHidden = React.useCallback(
		async (isHidden: boolean) => {
			setRefreshing(true);

			updateOrganizationHidden({ isHidden });

			setLoading(false);
			setRefreshing(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch]
	);

	React.useEffect(() => {
		setDataWithValues();
	}, [
		districtFilter,
		sameCountryFilter,
		organizationDetails?.data?.organization.isHidden,
		sameEmployeeNumberFilter,
		sameSectorFilter,
		sameYearFilter,
		setDataWithValues
	]);

	React.useLayoutEffect(() => {
		setTimeout(() => {
			// @ts-ignore
			if (scrollRef.current && scrollRef.current.updateScroll) scrollRef.current.updateScroll();
		}, theme.transitions.duration.enteringScreen);
	});

	const CountDownRenderer = ({ hours, minutes, seconds, completed }) => {
		if (completed) updateLastUpdate();
		return (
			<span>
				{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
			</span>
		);
	};

	updateLastUpdate();

	if (loading) {
		return (
			<>
				<Header
					isScoreVisible={false}
					label={description}
					organizationId={organizationDetails?.data?.organization.id}
					title={name}
				/>
				<Skeleton height={122} sx={{ borderRadius: 1, mb: 1 }} variant="rectangular" width="100%" />
				<TableContainer>
					<Scrollbar options={{ suppressScrollY: true }} ref={(el) => (scrollRef.current = el)}>
						<Table sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
							<TableHead>
								<TableRow>
									<TableCell align="center" sx={{ width: '10%' }}>
										<Skeleton variant="text" width="100%" />
									</TableCell>
									<TableCell align="left" sx={{ width: '80%' }}>
										<Skeleton variant="text" width="100%" />
									</TableCell>
									<TableCell align="center" sx={{ width: '10%' }}>
										<Skeleton variant="text" width="100%" />
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{_.map(Array.from({ length: 10 }), (_, index) => (
									<ItemShimmer key={index.toString()} />
								))}
							</TableBody>
						</Table>
					</Scrollbar>
				</TableContainer>
			</>
		);
	}

	return (
		<>
			<Header
				isScoreVisible={false}
				label={description}
				organizationId={organizationDetails?.data?.organization.id}
				title={name}
			/>
			<Typography align="right" variant="body2">
				{t`Prochaine mise à jour des points dans :`}{' '}
				<Countdown date={countDownLastUpdate + (60 * 60 + 3) * 1000} renderer={CountDownRenderer}></Countdown>
			</Typography>
			<TextField
				SelectProps={{
					displayEmpty: true
				}}
				disabled={refreshing}
				fullWidth={true}
				label={t`District`}
				onChange={(event) => {
					localStorage.setItem('districtFilter', event.target.value);
					setDistrictFilter(event.target.value);
				}}
				select={true}
				sx={{ my: 2 }}
				value={districtFilter}
				variant="filled"
			>
				<MenuItem value="">
					<Trans>Tous</Trans>
				</MenuItem>
				{_.map(organizationDetails?.data?.districts, (d, index) => (
					<MenuItem key={d.key.toString() + index.toString()} value={d.key}>
						{d.texts?.label || d.key}
					</MenuItem>
				))}
			</TextField>
			<Stack alignItems="center" direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" spacing={1}>
				<FormGroup row={true}>
					<Tooltip
						disableFocusListener={hasAnsweredEmployeeNumber}
						disableHoverListener={hasAnsweredEmployeeNumber}
						disableTouchListener={hasAnsweredEmployeeNumber}
						title={t`Pour pouvoir utiliser ce filtre, veuillez renseigner la nombre d'employés de votre organisation.`}
					>
						<FormControlLabel
							control={
								<Switch
									checked={sameEmployeeNumberFilter}
									disabled={refreshing || !hasAnsweredEmployeeNumber}
									onChange={(event) => {
										localStorage.setItem('sameEmployeeNumberFilter', event.target.checked.toString());
										setSameEmployeeNumberFilter(event.target.checked);
									}}
								/>
							}
							label={t`Même taille`}
							labelPlacement="end"
						/>
					</Tooltip>
					<Tooltip
						disableFocusListener={hasAnsweredCountries}
						disableHoverListener={hasAnsweredCountries}
						disableTouchListener={hasAnsweredCountries}
						title={t`Pour pouvoir utiliser ce filtre, veuillez renseigner le pays du siège social de votre organisation.`}
					>
						<FormControlLabel
							control={
								<Switch
									checked={sameCountryFilter}
									disabled={refreshing || !hasAnsweredCountries}
									onChange={(event) => {
										localStorage.setItem('sameCountryFilter', event.target.checked.toString());
										setSameCountryFilter(event.target.checked);
									}}
								/>
							}
							label={t`Même pays`}
							labelPlacement="end"
						/>
					</Tooltip>
					<Tooltip
						disableFocusListener={hasAnsweredSector}
						disableHoverListener={hasAnsweredSector}
						disableTouchListener={hasAnsweredSector}
						title={t`Pour pouvoir utiliser ce filtre, veuillez renseigner le secteur de votre organisation.`}
					>
						<FormControlLabel
							control={
								<Switch
									checked={sameSectorFilter}
									disabled={refreshing || !hasAnsweredSector}
									onChange={(event) => {
										localStorage.setItem('sameSectorFilter', event.target.checked.toString());
										setSameSectorFilter(event.target.checked);
									}}
								/>
							}
							label={t`Même secteur`}
							labelPlacement="end"
						/>
					</Tooltip>
					<Tooltip
						disableFocusListener={hasAnsweredYear}
						disableHoverListener={hasAnsweredYear}
						disableTouchListener={hasAnsweredYear}
						title={t`Pour pouvoir utiliser ce filtre, veuillez renseigner l'année de création de votre organisation.`}
					>
						<FormControlLabel
							control={
								<Switch
									checked={sameYearFilter}
									disabled={refreshing || !hasAnsweredYear}
									onChange={(event) => {
										localStorage.setItem('sameYearFilter', event.target.checked.toString());
										setSameYearFilter(event.target.checked);
									}}
								/>
							}
							label={t`Même année de création`}
							labelPlacement="end"
						/>
					</Tooltip>
				</FormGroup>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={organizationDetails?.data?.organization.isHidden}
								disabled={refreshing}
								onChange={(event) => {
									setHidden(event.target.checked);
								}}
							/>
						}
						label={t`Cacher mon organisation du classement`}
						labelPlacement="start"
					/>
				</FormGroup>
			</Stack>
			{refreshing && <LinearProgress sx={{ my: 1 }} variant="indeterminate" />}
			<TableContainer>
				<Scrollbar options={{ suppressScrollY: true }} ref={(el) => (scrollRef.current = el)}>
					<Table sx={{ maxWidth: '100%', whiteSpace: 'nowrap' }}>
						<TableHead>
							<TableRow>
								<TableCell align="center" sx={{ width: 'auto' }}>
									<Trans>Rang</Trans>
								</TableCell>
								<TableCell align="left" sx={{ width: '100%' }}>
									<Trans>Nom</Trans>
								</TableCell>
								<TableCell align="center" sx={{ width: 'auto' }}>
									<Trans>Habitants</Trans>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{_.map(data, (d, index) => (
								<ItemRow data={d} index={index} key={d.id.toString() + index.toString()} />
							))}
						</TableBody>
					</Table>
				</Scrollbar>
			</TableContainer>
		</>
	);
};

class Leaderboard implements ICustomBlock {
	description = () => t`Découvrez le top 10 des meilleures organisations sur FreewayTeam !`;
	name = () => t`Leaderboard`;
	key = () => 'leaderboard';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default Leaderboard;
