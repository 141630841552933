// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const organizationRoleApi = createApi({
	reducerPath: 'organizationRoleApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	tagTypes: ['role'],
	endpoints: (build) => ({
		getRoles: build.query({
			query: () => '/api/roles/all',
			providesTags: ['role']
		}),
		getSingleRole: build.query({
			query: ({ roleId }: { roleId: number }) => `/api/roles/${roleId ?? -1}`,
			providesTags: ['role']
		}),
		changeRole: build.mutation({
			query: (body: { organizationId: number; userId: string; roleId: number }) => ({
				url: `/api/roles/set`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['role']
		}),
		changeOwner: build.mutation({
			query: (body: { organizationId: number; oldOwnerId: string; oldOwnerNewRoleId: number; newOwnerId: string }) => ({
				url: `/api/organizations/members/OwnerChange`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['role']
		})
	})
});

export const { useChangeOwnerMutation, useChangeRoleMutation, useGetSingleRoleQuery, useGetRolesQuery } =
	organizationRoleApi;
