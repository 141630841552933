// Redux
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Externals
import { baseHeader } from '../base-header';

export const accountApi = createApi({
	reducerPath: 'accountApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.NEXT_PUBLIC_API,
		prepareHeaders: () => {
			return baseHeader(true);
		}
	}),
	endpoints: (build) => ({
		// done
		deleteUserAccount: build.mutation({
			query: (body: { reason: string }) => ({
				url: `/api/accounts/deleteUserAccount`,
				method: 'DELETE',
				body
			})
		})
	})
});

export const { useDeleteUserAccountMutation } = accountApi;
