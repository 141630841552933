import { t } from '@lingui/macro';
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	TableFooter,
	TablePagination,
	TableHead,
	useMediaQuery,
	Theme
} from '@mui/material';
import NetworkIcon, { networks } from 'components/legacy/components/network';
import PublicationTableHead from './header';
import renderTextWithHashtagsAndMentions from '../components/RenderTextWithTagsAndMentions';
import { useEffect, useState } from 'react';
import useMoment from 'components/legacy/hooks/useMoment';
import ImageWithSvgOverlay from '../components/ImageWithSvgOverlay';

/**
 * PublicationTableDesktop component
 * Renders a publication table for desktop.
 *
 * @param {Array} rows - Data displayed on the table page.
 * @param {number} totalRows - Number of available items.
 * @param {number} pageSize - Number of items displayed per page.
 * @param {number} pageNumber - Number of pages.
 * @param {string} sortColumn - Name of the sorted column.
 * @param {string} sortOrder - Column sort order (desc or asc).
 * @param {array} filterAvailable - Filters available for the table.
 * @param {function} handleChangePage - Action triggered when the user changes the page.
 * @param {function} handleChangeRowsPerPage - Action triggered when the user changes the number of items per page.
 * @param {function} handleChangeFilter - Action triggered when filtering the table.
 * @param {function} handleRequestSort - Action triggered when changing the sort column.
 * @version 1.0.0 [DCH] - Initial version of the PublicationTableDesktop component.
 */
const PublicationTableDesktop = ({
	rows,
	totalRows,
	pageSize,
	pageNumber,
	sortColumn,
	sortOrder,
	filterAvailable,
	handleChangePage,
	handleChangeRowsPerPage,
	handleChangeFilter,
	handleRequestSort
}) => {
	const [hoveredLinkId, setHoveredLinkId] = useState(null);
	const [networkFilter, setNetworkFilter] = useState<string[]>([]);
	const moment = useMoment();
	const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

	const handleLinkMouseEnter = (id) => {
		setHoveredLinkId(id);
	};

	const handleLinkMouseLeave = () => {
		setHoveredLinkId(null);
	};

	useEffect(() => {
		handleChangeFilter(networkFilter);
	}, [handleChangeFilter, networkFilter]);

	const PostDetails = ({ row, index }) => {
		const moment = useMoment();
		const imgPreviewExpired =
			!row.ImgPreviewExpiresAt === null ||
			moment(row.ImgPreviewExpiresAt * 1000).isSameOrBefore(row.ImgPreviewExpiresAt);

		return (
			<Box
				sx={{
					margin: 0,
					padding: 0,
					maxWidth: 'auto',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'left',
					align: 'left'
				}}
			>
				<a
					id={row.sourceType + '-' + index}
					style={{
						textDecoration: index === hoveredLinkId ? 'underline' : 'none',
						color: 'inherit',
						cursor: 'pointer'
					}}
					onMouseEnter={() => handleLinkMouseEnter(index)}
					onMouseLeave={handleLinkMouseLeave}
					target="_blank"
					rel="noopener noreferrer"
					href={row.url}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							align: 'center',
							verticalAlign: 'center',
							margin: 0
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								maxWidth: upLg ? '300px' : '250px'
							}}
						>
							<Typography
								sx={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									display: 'inline-block',
									maxHeight: '1.5em',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 1
								}}
							>
								{renderTextWithHashtagsAndMentions(
									row.network,
									row.text,
									t`Publication ` +
										' ' +
										networks[row.network]?.title +
										' ' +
										t` du ` +
										' ' +
										`${moment.unix(row.publishedTime / 1000).format('DD/MM/YY')}`,
									true
								)}
							</Typography>
						</Box>
					</Box>
				</a>
			</Box>
		);
	};
	return (
		<TableContainer
			id="sticky-header"
			sx={{
				maxHeight: 400,
				left: 0
			}}
		>
			<Table stickyHeader size="small">
				<TableHead>
					<PublicationTableHead
						onRequestSort={handleRequestSort}
						sortColumn={sortColumn}
						sortOrder={sortOrder}
						isSticky={pageSize >= 10}
					></PublicationTableHead>
				</TableHead>
				<TableBody>
					{rows === null || rows?.length === 0 ? (
						<TableRow key="empty">
							<Typography variant="body2">
								<p>{t`Aucune donnée pour le moment`}</p>
							</Typography>
						</TableRow>
					) : (
						rows?.map((row, index) => (
							<TableRow key={row.publicationId}>
								<TableCell>
									<PostDetails row={row} index={index} />
								</TableCell>
								<TableCell align="right">{moment.unix(row.publishedTime / 1000).format('DD/MM/YY')}</TableCell>
								<TableCell align="right">{row.impressions}</TableCell>
								<TableCell align="right">{row.reactions}</TableCell>
								<TableCell align="right">{row.comments}</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
				<TableFooter>
					<TablePagination
						rowsPerPageOptions={rows !== null && rows?.length > 0 ? [5, 10, 20, 30] : []}
						colSpan={5}
						count={totalRows}
						rowsPerPage={pageSize}
						page={pageNumber}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage={t`Nombre de publications par page`}
					/>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};
export default PublicationTableDesktop;
