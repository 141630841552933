import { FC, useState } from 'react';

// Externals
import {
	AccordionProps,
	AccordionSummaryProps,
	alpha,
	Box,
	Button,
	Collapse,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Stack,
	styled,
	TextField,
	Theme,
	Typography,
	useMediaQuery
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { ArrowForwardIosSharp, Delete, DoDisturb, MyLocationSharp, Update, Edit } from '@mui/icons-material';

import { plural, t, Trans } from '@lingui/macro';
import moment from 'moment';
import Image from 'next/image';

// Components
import AssignationQuest from './components/assignation-quest';
import MessageConfirm from 'components/legacy/dialogs/message-confirm';

// Services
import { useDispatch } from 'components/legacy/store';

// Intern Icons
import NoteColor from 'assets/icons/interface/note_color.svg';
import { useUpdateCustomQuestMutation } from 'components/legacy/services/quests';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router';

type BannerProps = {
	quest: any;
	displayLink: boolean;
	custom: boolean;
	handleSkip: (occurrenceId: number, title: string) => void;
	handleIgnore: (occurrenceId: number, title: string) => void;
	handleDelete: (occurrenceId: number, title: string) => void;
	updateNote: (questOccurenceId: number, noteValue: string, noteOrigin: string) => void;
};

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters={true} elevation={0} square={true} {...props} />
))(({ theme }) => ({
	width: '100%'
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '1rem', color: (theme) => theme.palette.primary.main }} />}
		{...props}
	/>
))(({ theme }) => ({
	background: alpha(theme.palette.primary.main, 0.05),
	borderRadius: '30px',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}));

const BannerQuest: FC<BannerProps> = (props) => {
	const navigate = useNavigate();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
	const downLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
	const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
	var utcNow = moment.unix(moment.utc().unix());
	const totalCreationDays = Math.floor(moment.duration(utcNow.diff(moment.unix(props.quest.creationDate))).asDays());

	const [expanded, setExpanded] = useState<string | false>(false);
	const [note, setNote] = useState(undefined);
	const [openReport, setOpenReport] = useState(false);
	const [openEditCustom, setOpenEditCustom] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [openIgnore, setOpenIgnore] = useState(false);
	const [title, setTitle] = useState<string>(props.quest.title);
	const [description, setDescription] = useState<string>(props.quest.description);

	const [UpdateCustomQuest] = useUpdateCustomQuestMutation();

	const handleEditOrphanQuest = async () => {
		await UpdateCustomQuest({ id: props.quest.occurrenceId, title: title, description: description });
		setOpenEditCustom(false);
	};

	const GetCreationDayLabel = () =>
		t({
			message: plural(totalCreationDays, {
				'=0': `0 j.`,
				one: `1 j.`,
				other: `# j.`
			})
		});

	const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
		setExpanded(newExpanded ? panel : false);
	};

	const isCompleted = () => props.quest.status === 'completed';

	const GetColorByDays = () => {
		return totalCreationDays < 7
			? (theme) => theme.palette.success.main
			: totalCreationDays < 31 && totalCreationDays >= 7
			? (theme) => theme.palette.warning.main
			: (theme) => theme.palette.error.main;
	};

	return (
		<>
			<Accordion
				expanded={expanded === 'panel' + props.quest.occurrenceId}
				onChange={handleChange('panel' + props.quest.occurrenceId)}
				sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '30px', width: '100%' }}
			>
				<AccordionSummary
					sx={{
						border: (theme) =>
							props.quest.archived ? null : isCompleted() ? `2px solid  ${theme.palette.success.main}` : null
					}}
				>
					<Grid alignItems="center" container={true} direction="row" justifyContent="space-between">
						<Grid item={true} lg={6} md={4} xl={8} xs={5}>
							<Typography
								sx={{
									cursor: props.displayLink ? 'pointer' : 'default',
									fontWeight: !expanded ? 500 : 700,
									my: 'auto',
									maxWidth:
										(isMobile && !expanded) || downLg ? '120px' : upLg ? '700px' : !expanded ? '400px' : 'max-content',
									overflow: 'hidden',
									whiteSpace: isMobile && expanded ? '' : 'nowrap',
									textOverflow: 'ellipsis',
									display: 'inline-block'
								}}
							>
								{props.quest.title}
							</Typography>
						</Grid>
						<Grid item={true} lg md xl xs>
							<Stack direction="row" justifyContent="end">
								{!expanded && !isCompleted() ? (
									<Typography
										sx={{
											fontWeight: 700,
											color: props.quest.archived ? 'black' : GetColorByDays(),
											my: 'auto',
											mr: 2
										}}
									>
										{props.quest.archived
											? moment(props.quest.completionDate * 1000).format('L')
											: GetCreationDayLabel()}
									</Typography>
								) : (
									''
								)}
								{!expanded && !isMobile && (
									<AssignationQuest
										AssignedToEmail={props.quest.assignedToEmail}
										assignedTo={props.quest.assignedTo}
										isDelegated={props.quest.delegated}
										occurrenceId={props.quest.occurrenceId}
										questDefinitionId={props.quest.questDefinitionId}
										questTitle={props.quest.title}
										status={props.quest.status}
									/>
								)}
								{!expanded && !isMobile && (
									<>
										{props.quest.questDefinitionId && (
											<>
												{!isCompleted() && (
													<Update
														onClick={(event) => {
															event.stopPropagation();
															setOpenReport(true);
														}}
														sx={{
															cursor: 'pointer',
															marginX: 1,
															color: (theme) => theme.palette.primary.main,
															my: 'auto'
														}}
														titleAccess={t`Reporter à plus tard`}
													/>
												)}

												{props.quest.url !== '' ? (
													<MyLocationSharp
														onClick={(event) => {
															event.stopPropagation();

															navigate(props.quest.url);
														}}
														sx={{ marginX: 1, color: (theme) => theme.palette.primary.main, my: 'auto' }}
														titleAccess={t`Voir la question liée`}
													/>
												) : (
													''
												)}
											</>
										)}
										{!isCompleted() && !props.quest.questDefinitionId && (
											<Delete
												onClick={(event) => {
													event.stopPropagation();
													setOpenDelete(true);
												}}
												sx={{
													cursor: 'pointer',
													marginX: 1,
													color: (theme) => theme.palette.primary.main,
													my: 'auto'
												}}
												titleAccess={t`Supprimer`}
											/>
										)}
									</>
								)}
							</Stack>
						</Grid>
					</Grid>
				</AccordionSummary>

				<Collapse in={true} sx={{ pl: 2, pb: 4, pt: 2 }} timeout="auto" unmountOnExit={true}>
					{props.quest.archived ? (
						<Typography
							sx={{
								fontWeight: 700,
								mb: 4,
								color: (theme) => theme.palette.text.primary
							}}
						>
							{t`Tâche réalisée le` + ' ' + t`${moment(props.quest.completionDate * 1000).format('L')}`}
						</Typography>
					) : isCompleted() ? (
						<Typography
							sx={{
								fontWeight: 700,
								mb: 4,
								color: (theme) => theme.palette.success.main
							}}
						>
							{t`Tâche créée le` +
								' ' +
								moment(props.quest.creationDate * 1000).format('L') +
								' ' +
								t`et réalisée le ${moment(props.quest.completionDate * 1000).format('L')}`}
						</Typography>
					) : (
						<Typography
							sx={{
								fontWeight: 700,
								mb: 4,
								color: GetColorByDays()
							}}
						>
							{t`Tâche créée le` + ' ' + moment(props.quest.creationDate * 1000).format('L')}
						</Typography>
					)}
					<ReactMarkdown>{props.quest.description}</ReactMarkdown>
					<AssignationQuest
						AssignedToEmail={props.quest.assignedToEmail}
						assignedTo={props.quest.assignedTo}
						isDelegated={props.quest.delegated}
						occurrenceId={props.quest.occurrenceId}
						questDefinitionId={props.quest.questDefinitionId}
						questTitle={props.quest.title}
						status={props.quest.status}
					/>
					{props.quest.archived && props.quest.note !== '' ? (
						<Stack flexDirection={'row'} sx={{ mt: 2 }}>
							{!isMobile && (
								<Box sx={{ mr: 1 }}>
									<Image alt="note" src={NoteColor} height={24} width={24} />
								</Box>
							)}
							<TextField
								disabled={isCompleted()}
								margin="none"
								multiline={true}
								onBlur={(e) => props.updateNote(props.quest.occurrenceId, e.target.value, props.quest.note)}
								onChange={(e) => setNote(e.target.value)}
								placeholder={t`Laisser un commentaire`}
								rows={5}
								sx={{
									width: '100%',
									pr: 2,
									display: 'flex',
									'& .MuiInputBase-input': {
										background: (theme) => theme.palette.background.default
									},
									'& .MuiTextField-root': {
										background: (theme) => theme.palette.background.default
									}
								}}
								type="text"
								value={note !== undefined ? note : props.quest.note}
							/>
						</Stack>
					) : !props.quest.archived ? (
						<Stack flexDirection={'row'} sx={{ mt: 2 }}>
							{!isMobile && (
								<Box sx={{ mr: 1 }}>
									<Image alt="note" src={NoteColor} height={24} width={24} />
								</Box>
							)}
							<TextField
								disabled={isCompleted()}
								margin="none"
								multiline={true}
								onBlur={(e) => props.updateNote(props.quest.occurrenceId, e.target.value, props.quest.note)}
								onChange={(e) => setNote(e.target.value)}
								placeholder={t`Laisser un commentaire`}
								rows={5}
								sx={{
									width: '100%',
									pr: 2,
									display: 'flex',
									'& .MuiInputBase-input': {
										background: (theme) => theme.palette.background.default
									},
									'& .MuiTextField-root': {
										background: (theme) => theme.palette.background.default
									}
								}}
								type="text"
								value={note !== undefined ? note : props.quest.note}
							/>
						</Stack>
					) : (
						<></>
					)}

					<Stack
						alignItems={'center'}
						flexDirection={isMobile ? 'column' : 'row'}
						justifyContent={'center'}
						sx={{ mt: 5 }}
					>
						{!isCompleted() && props.quest.questDefinitionId && (
							<Button
								onClick={() => setOpenReport(true)}
								sx={{ border: '2px solid #F2F2F2', ml: isMobile || isCompleted() ? 0 : 2, mt: isMobile ? 2 : 0 }}
							>
								<Update sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }} />
								{t`Reporter`}
							</Button>
						)}

						{props.custom && (
							<Button
								onClick={() => setOpenEditCustom(true)}
								sx={{ border: '2px solid #F2F2F2', ml: isMobile || isCompleted() ? 0 : 2, mt: isMobile ? 2 : 0 }}
							>
								<Edit sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }} />
								{t`Modifier`}
							</Button>
						)}

						{!isCompleted() && !props.quest.questDefinitionId && (
							<Button
								onClick={() => setOpenDelete(true)}
								sx={{ border: '2px solid #F2F2F2', ml: isMobile || isCompleted() ? 0 : 2, mt: isMobile ? 2 : 0 }}
							>
								<Delete sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }} />
								{t`Supprimer`}
							</Button>
						)}

						{!isCompleted() && props.quest.questDefinitionId && (
							<Button
								onClick={() => setOpenIgnore(true)}
								sx={{ border: '2px solid #F2F2F2', ml: isMobile || isCompleted() ? 0 : 2, mt: isMobile ? 2 : 0 }}
							>
								<DoDisturb sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }} />
								{t`Ignorer`}
							</Button>
						)}

						{props.quest.questDefinitionId && props.quest.url !== '' && (
							<Button
								onClick={() => {
									navigate(props.quest.url);
								}}
								sx={{ border: '2px solid #F2F2F2', ml: isMobile || isCompleted() ? 0 : 2, mt: isMobile ? 2 : 0 }}
							>
								<MyLocationSharp
									sx={{ cursor: 'pointer', marginRight: 2, color: (theme) => theme.palette.primary.main }}
								/>
								{t`Localiser`}
							</Button>
						)}
					</Stack>
				</Collapse>
			</Accordion>
			<MessageConfirm
				message={t`Êtes-vous sur de vouloir reporter la tâche ?`}
				onAccept={() => {
					props.handleSkip(props.quest.occurrenceId, props.quest.title);
					setOpenReport(false);
				}}
				onCancel={() => setOpenReport(false)}
				open={openReport}
				title={t``}
			></MessageConfirm>
			<MessageConfirm
				message={t`Êtes-vous sur de vouloir ignorer la tâche définitivement ?`}
				onAccept={() => {
					props.handleIgnore(props.quest.organizationQuestId, props.quest.title);
					setOpenIgnore(false);
				}}
				onCancel={() => setOpenIgnore(false)}
				open={openIgnore}
				title={t``}
			></MessageConfirm>
			<MessageConfirm
				message={t`Êtes-vous sur de vouloir supprimer la tâche ?`}
				onAccept={() => {
					props.handleDelete(props.quest.occurrenceId, props.quest.title);
					setOpenDelete(false);
				}}
				onCancel={() => setOpenDelete(false)}
				open={openDelete}
				title={t``}
			></MessageConfirm>
			<Dialog
				keepMounted={true}
				onClose={() => {
					setOpenEditCustom(false);
				}}
				open={openEditCustom}
			>
				<DialogTitle>{t`Modifier une tâche personnalisée`}</DialogTitle>
				<DialogContent>
					<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
						<TextField
							label={t`Titre`}
							margin="none"
							onChange={(e) => setTitle(e.target.value)}
							size="small"
							sx={{
								display: 'flex',
								mt: 2,
								'& .MuiInputBase-input': { background: (theme) => theme.palette.background.default },
								'& .MuiTextField-root': {
									background: (theme) => theme.palette.background.default
								}
							}}
							type="text"
							value={title}
						></TextField>
						<TextField
							label={t`Description`}
							margin="none"
							multiline={true}
							name="description"
							onChange={(e) => setDescription(e.target.value)}
							rows={5}
							sx={{
								display: 'flex',
								mt: 2,
								'& .MuiInputBase-input': {
									background: (theme) => theme.palette.background.default
								},
								'& .MuiTextField-root': {
									background: (theme) => theme.palette.background.default
								}
							}}
							type="text"
							value={description}
						/>
						<Button
							disabled={title === '' || description === ''}
							onClick={() => handleEditOrphanQuest()}
							sx={{ mt: 2, width: 'max-content' }}
							variant="contained"
						>
							<Trans>Modifier</Trans>
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default BannerQuest;
