import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DisplayVulnerabilitiesTag } from '../DispayVulnerabilities';
import { Trans } from '@lingui/macro';
import { Vulnerability } from 'components/legacy/models/domain-tech.dto';
import Severity from 'components/legacy/models/types/severitiesType';

interface Props {
	vulnerabilities: Vulnerability[];
}

function VulnerabilitiesTable({ vulnerabilities }: Props) {
	return (
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow key="table-row">
						<TableCell align="center">
							<Trans>Gravité</Trans>
						</TableCell>
						<TableCell>
							<Trans>Nom</Trans>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{vulnerabilities.length > 0 ? (
						vulnerabilities
							.sort((vulnA, vulnB) => vulnB.Score - vulnA.Score)
							.map((vuln) => (
								<TableRow key={vuln.ID}>
									<TableCell align="center">
										<DisplayVulnerabilitiesTag severity={vuln.Severity as Severity} />
									</TableCell>
									<TableCell>
										<Link href={vuln.Link} target="_blank" rel="noopener" underline="hover">
											{vuln.ID}
										</Link>
									</TableCell>
								</TableRow>
							))
					) : (
						<TableRow>
							<TableCell colSpan={2}>
								<Trans>Pas de vulnérabilités connues</Trans>
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default VulnerabilitiesTable;
