import { useCallback } from 'react';
import type { FC } from 'react';

// Externals
import { t } from '@lingui/macro';
import { alpha, Avatar, Box, TableCell, TableRow, Typography, useTheme } from '@mui/material';

// Models

// Store
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

interface ItemProps {
	data: any;
	index: number;
}

const Item: FC<ItemProps> = ({ data, index }) => {
	const theme = useTheme();
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});

	const isMyOrganization =
		userProfile?.data?.userProfile && userProfile?.data?.userProfile.currentOrganizationId === data.id;

	const getStyle = useCallback((): any => {
		if (userProfile?.data?.userProfile && userProfile?.data?.userProfile.currentOrganizationId === data.id)
			return {
				backgroundImage: `linear-gradient(to right, ${alpha(
					theme.palette.primary.main,
					0.3
				)}, rgba(0,0,0,0)) !important`
			};
		else if (index === 0) {
			return {
				backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.gold.main, 0.3)}, rgba(0,0,0,0)) !important`
			};
		} else if (index === 1) {
			return {
				backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.argent.main, 0.3)}, rgba(0,0,0,0)) !important`
			};
		} else if (index === 2) {
			return {
				backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.bronze.main, 0.3)}, rgba(0,0,0,0)) !important`
			};
		}
	}, [
		index,
		theme.palette.gold.main,
		theme.palette.argent.main,
		theme.palette.bronze.main,
		theme.palette.primary.main,
		userProfile,
		data.id
	]);

	return (
		<TableRow
			sx={{
				'&:nth-of-type(odd)': {
					backgroundColor: theme.palette.action.hover
				},
				'&:last-child td, &:last-child th': { border: 0 },
				...getStyle()
			}}
		>
			<TableCell align="center">
				<Typography color="textSecondary" sx={{ fontWeight: isMyOrganization ? 'bold' : 'medium' }} variant="subtitle2">
					{data.rank}
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
					<Avatar
						src={data.picture}
						sx={{
							backgroundColor: 'text.primary',
							color: 'background.paper'
						}}
					/>
					<Typography
						color="textPrimary"
						sx={{
							ml: 2,
							fontWeight: isMyOrganization ? 'bold' : 'regular'
						}}
						variant="subtitle2"
					>
						{data.name ?? t`Anonyme`}
					</Typography>
				</Box>
			</TableCell>
			<TableCell align="center">
				<Typography color="textPrimary" sx={{ fontWeight: isMyOrganization ? 'bold' : 'regular' }} variant="subtitle2">
					{data.score}
				</Typography>
			</TableCell>
		</TableRow>
	);
};

export default Item;
