// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import { t } from '@lingui/macro';
import { Box, Grid, Typography } from '@mui/material';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { isEmpty } from 'lodash';
import _ from 'lodash';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

// We could create a custom Admin Component if necessary
// const AdminComponent = () => {
//   return <div>Hello</div>;
// };

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const MAILTESTER_UNSUSCRIBE_ML = 'mailtester:unsuscribe-ml';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps, props) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const [unsuscribe] = useStorageValue<any>(MAILTESTER_UNSUSCRIBE_ML, organizationId);

	return (
		<>
			{!isEmpty(unsuscribe.value) ? (
				<>
					<Header
						isScoreVisible={true}
						label={description}
						organizationId={organizationId}
						score={score}
						title={name}
					/>
					<Grid
						sx={{
							display: 'flex',
							flexDirection: { md: 'row' },
							alignItems: 'center',
							pb: 2
						}}
					>
						<Box
							sx={{
								position: 'relative',
								display: 'flex',
								alignItems: 'center',
								height: '40px',
								width: '40px',
								cursor: 'pointer'
							}}
						>
							<Box
								sx={{
									border: 1.5,
									borderColor: (theme) =>
										unsuscribe.value.status === 'success icon-check'
											? theme.palette.success.main
											: theme.palette.error.main,
									display: 'block',
									background: (theme) =>
										unsuscribe.value.status === 'success icon-check'
											? theme.palette.success.light
											: theme.palette.error.light,
									height: '100%',
									width: '100%',
									borderRadius: '20px'
								}}
							/>
							{unsuscribe.value.status === 'success icon-check' ? (
								<Check
									sx={{
										fontSize: '30px',
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
										color: (theme) => theme.palette.success.main
									}}
								/>
							) : (
								<Clear
									sx={{
										fontSize: '30px',
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)',
										color: (theme) => theme.palette.error.main
									}}
								/>
							)}
						</Box>
						<Typography sx={{ pl: 2 }} variant="h6">
							{unsuscribe.value.status === 'success icon-check'
								? t`Il y a un lien pour se désabonner.`
								: t`Nous n'avons pas trouvé de lien pour se désabonner.`}
						</Typography>
					</Grid>
				</>
			) : (
				''
			)}
		</>
	);
};

class UnsuscribeMLRecap implements ICustomBlock {
	description = () => t`Nous regardons si un lien pour se désabonner figure dans l'email que vous nous avez envoyé.`;
	name = () => t`Lien pour se désabonner`;
	key = () => 'unsuscribe-ml-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default UnsuscribeMLRecap;
