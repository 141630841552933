import React, { useEffect, useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import { CircularProgress, Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import ReactECharts from 'echarts-for-react';

// Components
import { Header } from 'components/legacy/pages/audit/questions/header';

// Services
import { ICustomBlock, UserComponentProps } from '../..';
import { ComponentProps } from '../../google/connect/models';
import * as StorageDataKeys from '../../google/constants';
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { StorageValue } from 'components/legacy/hooks/useStorageValue';

interface IStorageValueCollection extends Query {
	[StorageDataKeys.CHART_SOURCES_GA]: StorageValue<any>;
	[StorageDataKeys.CHART_MEDIUMS_GA]: StorageValue<any>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const theme = useTheme();
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

	const [sourcesData, setSourcesData] = useState<any>();
	const [sourcesCategories, setSourcesCategories] = useState<any>([]);

	const [mediumsData, setMediumsData] = useState<any>();
	const [mediumsCategories, setMediumsCategories] = useState<any>([]);

	const [mapping] = useStorageValueCollection<IStorageValueCollection>(
		[StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.CHART_SOURCES_GA, StorageDataKeys.CHART_MEDIUMS_GA],
		organizationId
	);

	const loading =
		mapping[StorageDataKeys.PROPERTY_ID_GA].loading ||
		mapping[StorageDataKeys.CHART_SOURCES_GA].loading ||
		mapping[StorageDataKeys.CHART_MEDIUMS_GA].loading;

	const chartSourcesGA = mapping[StorageDataKeys.CHART_SOURCES_GA].value;
	const chartMediumsGA = mapping[StorageDataKeys.CHART_MEDIUMS_GA].value;

	const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

	useEffect(() => {
		if (selectedGA.value && chartSourcesGA) {
			const tmpData = [];
			const tmpCategories = [];
			chartSourcesGA?.slice(1).map((element) => {
				tmpCategories.push(element[0]);
				tmpData.push(element[1]);
			});

			setSourcesCategories(tmpCategories.reverse());
			setSourcesData(tmpData.reverse());
		}
	}, [chartSourcesGA, selectedGA]);

	useEffect(() => {
		if (selectedGA.value && chartMediumsGA) {
			const tmpData = [];
			const tmpCategories = [];
			chartMediumsGA?.slice(1).map((element) => {
				tmpCategories.push(element[0]);
				tmpData.push(element[1]);
			});

			setMediumsCategories(tmpCategories.reverse());
			setMediumsData(tmpData.reverse());
		}
	}, [chartMediumsGA, selectedGA]);

	const optionSources = {
		color: ['rgb(77, 119, 255)'],
		smooth: true,
		yAxis: {
			type: 'category',
			data: sourcesCategories,
			left: 'center'
		},
		title: {
			text: 'Total de sessions par Source',
			textStyle: {
				color: theme.palette.text.primary
			}
		},
		toolbox: {
			feature: {
				saveAsImage: {}
			}
		},
		grid: { containLabel: true },

		tooltip: {
			trigger: 'axis'
		},
		xAxis: {
			type: 'value'
		},
		series: [
			{
				data: sourcesData,
				type: 'bar',
				stack: 'total'
			}
		]
	};

	const optionMedium = {
		color: ['rgb(77, 119, 255)'],
		smooth: true,
		yAxis: {
			type: 'category',
			data: mediumsCategories,
			left: 'center'
		},
		title: {
			text: 'Total de sessions par Medium',
			textStyle: {
				color: theme.palette.text.primary
			}
		},
		toolbox: {
			feature: {
				saveAsImage: {}
			}
		},
		grid: { containLabel: true },

		tooltip: {
			trigger: 'axis'
		},
		xAxis: {
			type: 'value'
		},
		series: [
			{
				data: mediumsData,
				type: 'bar',
				stack: 'total'
			}
		]
	};

	if (loading) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					title={name}
					tooltip={tooltip}
				/>
				<CircularProgress />
			</>
		);
	}
	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={name}
				tooltip={tooltip}
			/>
			<Grid container={true} spacing={2}>
				<Grid item={true} md={6} sm={12} xs={12}>
					<ReactECharts option={optionSources} />
				</Grid>
				<Grid item={true} md={6} sm={12} xs={12}>
					<ReactECharts option={optionMedium} />
				</Grid>
			</Grid>
		</>
	);
};

class GoogleGA4ChartSourcesMediums implements ICustomBlock {
	name = () => t`Origine du trafic web`;
	description = () => t`Sources et médiums principaux du trafic des 30 derniers jours:`;
	tooltip = () =>
		t`La source d'une visite est le site qui a redirigé l'utilisateur vers votre site. Le trafic "Direct" signifie que l'utilisateur a directement écrit votre url exacte dans la barre du navigateur. Le médium classe ce trafic en différentes catégories.
    Par exemple, "Organic Search" pour les moteurs de recherche, "Social" pour les réseaux sociaux, " "Display", "CPC" et "Paid Search" pour les pubs, Referral" pour les autres sites, etc.`;
	isScoreVisible = () => false;
	key = () => 'google-ga-chart-sourcesmediums';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleGA4ChartSourcesMediums;

function AdaptDataTableFormat(chartDataCollection) {
	const table = [];
	const headers = [];
	chartDataCollection[0].forEach((element, index) => {
		headers.push(GetHeaderName(element));
	});
	table.push(headers);

	if (chartDataCollection.rows?.length === 0) {
		table.push('', 0);
	}

	chartDataCollection.forEach((element, index) => {
		if (index === 0) return; //skip header row
		const data = [];
		element.forEach((element, index) => {
			if (index === 0) {
				//Country Name column
				data.push(element);
			} else {
				//value column
				data.push(parseInt(element));
			}
		});
		table.push(data);
	});
	return table;
}
function GetHeaderName(value: string) {
	switch (value) {
		case 'ga:sessions':
			return t`Sessions`;
		default:
			return value;
	}
}
