import { useEffect, useState } from 'react';

// Externals
import _ from 'lodash';
import { t } from '@lingui/macro';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import moment from 'moment';

// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import * as StorageDataKeys from '../../google/constants';
import { ComponentProps } from '../../google/connect/models';

// Stores && models
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Hooks
import { StorageValue } from 'components/legacy/hooks/useStorageValue';
import useStorageValueCollection, { Query } from 'components/legacy/hooks/useStorageValueCollection';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router-dom';

interface IStorageValueCollection extends Query {
	[StorageDataKeys.PROPERTY_ID_GA]: StorageValue<string>;
}

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const [mapping] = useStorageValueCollection<IStorageValueCollection>(
		[StorageDataKeys.PROPERTY_ID_GA, StorageDataKeys.LAST_THIRTY_DAY_SESSIONS_REPORT_GA],
		organizationId
	);

	const selectedGA = mapping[StorageDataKeys.PROPERTY_ID_GA];

	const loading =
		mapping[StorageDataKeys.PROPERTY_ID_GA].loading ||
		mapping[StorageDataKeys.LAST_THIRTY_DAY_SESSIONS_REPORT_GA].loading;

	const dataReportThirtyGA = mapping[StorageDataKeys.LAST_THIRTY_DAY_SESSIONS_REPORT_GA].value;

	const [dataTable, setDataTable] = useState<any>();

	useEffect(() => {
		setDataTable('');
	}, [selectedGA, dataReportThirtyGA]);

	useEffect(() => {
		if (selectedGA.value) {
			setDataTable(dataReportThirtyGA);
		}
	}, [selectedGA, dataReportThirtyGA]);

	if (loading) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					score={score}
					title={name}
					tooltip={tooltip}
				/>
				<CircularProgress />
			</>
		);
	}
	if (dataTable) {
		return (
			<>
				<Header
					isScoreVisible={isScoreVisible}
					label={description}
					organizationId={organizationId}
					score={score}
					title={name}
					tooltip={tooltip}
				/>
				<ProgressionDetails {...dataTable} />
			</>
		);
	}

	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={description}
				organizationId={organizationId}
				title={name}
				tooltip={tooltip}
			/>
			<Typography>{t`Aucune données pour le moment.`}</Typography>
		</>
	);
};

class GoogleGA4AvgTime implements ICustomBlock {
	name = () => t`Temps moyen - Google Analytics`;
	description = () => t`Durant ces 30 derniers jours, le temps moyen passé par vos visiteurs sur votre site est de:`;
	tooltip = () => t`La progression affichée est par rapport au temps moyen des 30 jours précédents la période évaluée.`;
	isScoreVisible = () => true;
	key = () => 'google-ga-avgtime';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleGA4AvgTime;

function ProgressionDetails(dataTable: any) {
	return (
		<>
			<DurationInformations {...dataTable} />
		</>
	);
}

function DurationInformations({ duration, durationProgression }) {
	const durationFormat = moment(duration * 1000).format('mm[min ]ss[s]');
	const durationProgressionFormat = moment(Math.abs(durationProgression) * 1000).format('mm[min ]ss[s]');

	return (
		<Grid columns={{ xs: 1, sm: 8, md: 12 }} container={true} spacing={{ xs: 2, md: 3 }}>
			<Grid item={true} md={4} sm={12} xs={12}>
				<Paper
					elevation={3}
					sx={{
						p: 2,
						position: 'relative',
						height: '100%',
						background: '#FBF9FF',
						borderRadius: '20px',
						breakInside: 'avoid'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column'
						}}
					>
						<Typography
							sx={{
								color: (theme) => theme.palette.primary.main,
								textAlign: 'center',
								fontFamily: 'Inter, sans-serif',
								fontSize: 36,
								fontStyle: 'normal',
								fontWeight: 700,
								letterSpacing: 'normal'
							}}
						>
							{durationFormat}
						</Typography>
						<Typography
							sx={{
								textAlign: 'center',
								fontFamily: 'Inter, sans-serif',
								fontSize: 24,
								fontStyle: 'normal',
								fontWeight: 700,
								letterSpacing: 'normal',

								color: (theme) =>
									durationProgression === null
										? theme.palette.text.primary
										: durationProgression > 0
										? theme.palette.success.main
										: durationProgression === 0
										? theme.palette.text.primary
										: durationProgression < 0
										? theme.palette.error.main
										: theme.palette.error.main
							}}
						>
							{durationProgression === null
								? '--'
								: durationProgression > 0
								? '+' + durationProgressionFormat
								: durationProgression === 0
								? '--'
								: durationProgression < 0
								? '-' + durationProgressionFormat
								: durationProgression}
						</Typography>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
}
