import { useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import GoogleConnect from '../google/connect';
import { ComponentProps } from '../google/connect/models';
import { GA4Property, UAProperty } from './models';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Button, Divider, MenuItem, TextField, Stack, Typography, CircularProgress } from '@mui/material';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Services

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';

// Query & mutations
import { useSelectPropertyGA4Mutation, useSelectPropertyGUAMutation } from 'components/legacy/services/connectors';
import {
	useDeleteSelectTokenUserMutation,
	usePostSelectTokenUserMutation
} from 'components/legacy/services/organizations';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router-dom';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { socialDataApi } from 'components/legacy/services/socialData';
import { storageApi } from 'components/legacy/services/storages';

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const dispatch = useDispatch();
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
		organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
		userId: userProfile?.data?.userProfile?.id
	});
	const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
		roleId: role?.data?.member?.roleId
	});

	const [connectionStatus] = useStorageValue<string>(connectorStorageKeys.google.connectionStatus, organizationId);
	const [getAcessToken] = useStorageValueUser<any>(connectorStorageKeys.google.accessToken, 0);
	const [tokenSelectUserGa4] = useStorageValue<string>(connectorStorageKeys.googleGa4.selectedUserId, organizationId);

	const [gaPropertyId] = useStorageValue<string>(connectorStorageKeys.googleGa4.propertyId, organizationId);
	const [gaPropertyName] = useStorageValue<string>(connectorStorageKeys.googleGa4.propertyName, organizationId);
	const [gaProperties] = useStorageValueUser<GA4Property[]>(connectorStorageKeys.googleGa4.listProperties, 0);

	const [uaPropertyId] = useStorageValue<string>(connectorStorageKeys.googleUA.propertyId, organizationId);
	const [uaPropertyName] = useStorageValue<string>(connectorStorageKeys.googleUA.propertyName, organizationId);
	const [uaProperties] = useStorageValueUser<UAProperty[]>(connectorStorageKeys.googleUA.listProperties, 0);

	const [propertyy, setProperty] = useState<any>(null);
	const [loader, setLoader] = useState<any>(false);

	const [selectPropertyGA4] = useSelectPropertyGA4Mutation();
	const [deleteSelectTokenUser] = useDeleteSelectTokenUserMutation();
	const [postSelectTokenUser] = usePostSelectTokenUserMutation();

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;
	const selectedEntity = gaPropertyId.value ? gaPropertyId.value : '';
	const accessToken = getAcessToken.value ? getAcessToken.value.token : null;
	const currentGoogleVersion = gaPropertyId.value ? 'google-ga4' : uaPropertyId.value ? 'google-ua' : '';

	const handleAssociateEntity = async (property) => {
		setLoader(true);
		await postSelectTokenUser({
			namespace: 'google-ga4',
			organizationId: organizationId,
			userId: userProfile?.data?.userProfile?.id
		}).then(async () => {
			await selectPropertyGA4({
				propertyId: property,
				propertyName: gaProperties.value.filter((p) => p.propertyId === property)[0]
					? gaProperties.value.filter((p) => p.propertyId === property)[0].name
					: ''
			});
			dispatch(socialDataApi.util.invalidateTags(['sn-data']));
		});

		setLoader(false);
	};

	const handleDissociateEntity = async () => {
		setLoader(true);
		await deleteSelectTokenUser({ namespace: currentGoogleVersion, organizationId: organizationId });
		dispatch(socialDataApi.util.invalidateTags(['sn-data']));
		setLoader(false);
	};

	if (loader || gaPropertyName.loading) return <CircularProgress />;

	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={selectedEntity || accessToken ? '' : description}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>

			{(accessToken || selectedEntity) && !connectionExpired ? (
				<>
					{selectedEntity ? (
						<Stack direction="row" spacing={2} sx={{ alignItems: 'center', mb: 2 }}>
							<Typography>{gaPropertyName.value ? gaPropertyName.value : uaPropertyName.value}</Typography>
							<LoadingButton
								loading={loader || gaPropertyName.loading}
								color="primary"
								onClick={() => handleDissociateEntity()}
								variant="contained"
							>
								<Trans>Dissocier ce compte</Trans>
							</LoadingButton>
						</Stack>
					) : (
						<>
							<Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
								<TextField
									disabled={!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer') ? true : false}
									id="ga-property-select"
									label={t`Sélectionner un compte`}
									margin="normal"
									select={true}
									onChange={(e) => {
										if (e.target.value) {
											setProperty(e.target.value);
										}
									}}
									sx={{
										'& label': {
											color: '#FF0000'
										},
										mb: 0,
										width: '50%'
									}}
									value={propertyy?.id ? propertyy.id : ''}
								>
									{gaProperties.value?.map((property) => {
										return (
											<MenuItem
												onClick={() => {
													handleAssociateEntity(property.propertyId);
												}}
												key={property.propertyId}
												value={property.propertyId}
											>
												{property.name}
											</MenuItem>
										);
									})}
									<Divider />
									{uaProperties.value?.map((property) => {
										return (
											<MenuItem
												sx={{ color: '#F00' }}
												disabled
												key={property.propertyId}
												value={'UA-' + property.profileId}
											>
												{property.name} {'>'} {property.profileName} [
												{
													<Typography variant="caption">
														<Trans>Obsolète</Trans>
													</Typography>
												}
												]
											</MenuItem>
										);
									})}
								</TextField>
							</Stack>
							<Typography sx={{ mt: 2, mb: 2 }} variant="body2">
								<Trans>
									Si la propriété de votre organisation n'apparait pas dans la liste, reconnectez un compte Google ayant
									accès à la page de votre organisation.
								</Trans>
							</Typography>
							<GoogleConnect
								accessTokenKey={connectorStorageKeys.google.accessToken}
								connectionStatusKey={connectorStorageKeys.google.connectionStatus}
								tokenSelectUserKey={connectorStorageKeys.google.selectedUserId}
							/>
						</>
					)}
				</>
			) : connectionExpired && tokenSelectUserGa4.value === userProfile?.data?.userProfile.id ? (
				<Typography sx={{ color: (theme) => theme.palette.red.main }}>
					<Trans>Il semble que votre session Google ait expiré. Vous devez vous reconnecter à votre compte.</Trans>
				</Typography>
			) : (
				''
			)}
			{(!accessToken && !selectedEntity) || connectionExpired ? (
				<>
					<GoogleConnect
						accessTokenKey={connectorStorageKeys.google.accessToken}
						connectionStatusKey={connectorStorageKeys.google.connectionStatus}
						tokenSelectUserKey={connectorStorageKeys.google.selectedUserId}
					/>
				</>
			) : (
				''
			)}
		</>
	);
};

class GoogleGA4 implements ICustomBlock {
	name = () => t`Google Analytics`;
	description = () => t`Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.`;
	tooltip =
		() => t`Connectez le compte Google d'une personne ayant accès à la propriété Google Analytics de l'entreprise, afin d'avoir une analyse de ses métriques principales. 
  Si vous ne trouvez pas votre site dans la liste, assurez-vous d'avoir accès au compte Google Analytics de votre entreprise (https://analytics.google.com), et que la balise GA correspondante soit sur votre site. 
  FreewayTeam ne partage pas vos données avec des tiers, ne fait aucune action sur votre compte. Cette connexion est validée et contrôlée par Google, et les données sont supprimées de FreewayTeam si vous déconnectez votre compte Google.`;
	isScoreVisible = () => true;
	key = () => 'google-ga-connect';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleGA4;
