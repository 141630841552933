import { useCallback, useEffect, useState } from 'react';

// Services
import { useSignalRContext } from 'components/legacy/components/providers/SignalRProvider';
import { useLazyRetrieveValuesStorageByRefQuery } from '../services/storages';

const useStorageValueByRef = <StorageValueType>(key: string, reference: string): [StorageValue<StorageValueType>] => {
	// The value to retrieve and subscribe to
	const [value, setValue] = useState<StorageValue<StorageValueType>>({ key: key, value: null, loading: true });
	// Methods to start and stop subscribing to storage value change
	const { registerStorageHandler, removeStorageHandler } = useSignalRContext();
	const [retrieveValues] = useLazyRetrieveValuesStorageByRefQuery();

	const retrieveValueAsync = useCallback(async () => {
		let response = await retrieveValues({
			reference: reference,
			keys: [key]
		}).unwrap();

		if (response?.data.values[key]) {
			setValue((oldValue) => {
				try {
					return {
						...oldValue,
						value: JSON.parse(response.data.values[key]),
						loading: false
					};
				} catch (error) {
					console.log('useStorageValueByRef Error', key);
					return { ...oldValue };
				}
			});
		} else {
			setValue((oldValue) => ({
				...oldValue,
				value: null,
				loading: false,
				error: 'key not found'
			}));
		}
		try {
		} catch (error) {
			setValue((oldValue) => ({
				...oldValue,
				value: null,
				loading: false,
				error: 'unknown error'
			}));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key, reference]);

	const valueChangeHandler = useCallback(
		(updatedKey: string) => {
			if (updatedKey === key) {
				setValue((oldValue) => ({
					...oldValue,
					loading: true
				}));
				retrieveValueAsync();
			}
		},
		[key, retrieveValueAsync]
	);

	useEffect(() => {
		registerStorageHandler(valueChangeHandler);

		return () => {
			removeStorageHandler(valueChangeHandler);
		};
	}, [registerStorageHandler, removeStorageHandler, valueChangeHandler]);

	useEffect(() => {
		retrieveValueAsync();
	}, [retrieveValueAsync]);

	return [value];
};

interface StorageValue<StorageValueType> {
	key: string;
	value: StorageValueType | null;
	loading: boolean;
	error?: string;
}

export default useStorageValueByRef;
export type { StorageValue };
