import { t } from '@lingui/macro';
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import { useSelector } from 'components/legacy/store';
import PublicationTable from './publication-table';

interface ComponentProps {
	name: string;
	description: string;
	tips: string;
	isScoreVisible: boolean;
}

const UserComponent = (props: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	return (
		<>
			<Header
				isScoreVisible={props.isScoreVisible}
				label={props.description}
				organizationId={organizationId}
				title={props.name}
				tooltip={props.tips}
			/>
			<PublicationTable />
		</>
	);
};

class PubsPerformance implements ICustomBlock {
	tips = () => t``;
	description = () => ``;
	name = () => t`Performances détaillées par pub`;
	key = () => 'pubsPerformance';
	isScoreVisible = () => false;
	UserComponent: React.FC<UserComponentProps> = () => (
		<UserComponent
			description={this.description()}
			name={this.name()}
			tips={this.tips()}
			isScoreVisible={this.isScoreVisible()}
		/>
	);
}

export default PubsPerformance;
