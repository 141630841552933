import * as React from 'react';
import { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import BoxAnalytics from './boxAnalytics';

// Externals
import { t } from '@lingui/macro';
import {
	Box,
	CircularProgress,
	Grid,
	Theme,
	ToggleButton,
	ToggleButtonGroup,
	useMediaQuery,
	useTheme
} from '@mui/material';
import ReactECharts from 'echarts-for-react';

// Hooks
import useSocialDataHistory from 'components/legacy/hooks/useSocialDataHistory';
import { useGetKeyFigureQuery } from 'components/legacy/services/socialData';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	tooltip: string;
	questionKey: string;
}

export const options = {
	chartArea: { width: '50%' },
	isStacked: true
};

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const theme = useTheme();

	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const betweenSmallAndMd = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
	const betweenMdAndLg = useMediaQuery((theme: Theme) => theme.breakpoints.between('md', 'lg'));

	const [is30, setis30] = useState(false);

	const [, chartData] = useSocialDataHistory({ days: is30 ? 30 : 90, property: 'views' }, organizationId);
	const colorsTmp = [];
	const [color, setColor] = useState([]);
	const handleClick30 = () => {
		setis30(true);
	};

	const handleClick90 = () => {
		setis30(false);
	};

	const [haveDataToDisplay, setHaveDataToDisplay] = useState(false);

	const { data: fb } = useGetKeyFigureQuery({ socialNetwork: 'facebook' });
	const { data: ig } = useGetKeyFigureQuery({ socialNetwork: 'instagram' });
	const { data: yt } = useGetKeyFigureQuery({ socialNetwork: 'youtube' });
	const { data: linkedin } = useGetKeyFigureQuery({ socialNetwork: 'linkedin' });

	useEffect(() => {
		(async () => {
			//FILTER: REMOVE columns from Data.
			const header = chartData ? chartData[0] : null;

			if (header == null) return;

			let indexYoutube = header.indexOf('Youtube');
			chartData?.forEach((element) => {
				if (indexYoutube > -1) {
					// only splice array when item is found
					element.splice(indexYoutube, 1); // 2nd parameter means remove one item only
				}
			});

			// eslint-disable-next-line array-callback-return
			chartData[0].map((element, index) => {
				if (element === 'Facebook')
					fb?.data?.keyFigures !== null ? colorsTmp.splice(index, 0, '#3b5998') : colorsTmp.splice(index, 0, '#CCCCCC');
				if (element === 'Instagram')
					ig?.data?.keyFigures !== null ? colorsTmp.splice(index, 0, '#B908E2') : colorsTmp.splice(index, 0, '#CCCCCC');
				if (element === 'LinkedIn')
					linkedin?.data?.keyFigures !== null
						? colorsTmp.splice(index, 0, '#00AE65')
						: colorsTmp.splice(index, 0, '#CCCCCC');
				if (element === 'Youtube')
					yt?.data?.keyFigures !== null ? colorsTmp.splice(index, 0, '#FF4545') : colorsTmp.splice(index, 0, '#CCCCCC');
			});
			setColor(colorsTmp);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationId, chartData, fb, ig, linkedin, yt]);

	const [alignment, setAlignment] = useState('90days');
	const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		switch (newAlignment) {
			case '30days':
				handleClick30();
				break;
			case '90days':
				handleClick90();
				break;
			default:
				handleClick30();
				break;
		}
		setAlignment(newAlignment);
	};

	const option = {
		legend: {
			textStyle: {
				color: theme.palette.text.primary
			}
		},
		color: color,
		smooth: true,
		xAxis: {
			type: 'category',
			boundaryGap: false,
			axisLabel: {
				rotate: 30
			},
			data: chartData.slice(1).map((element) => element[0])
		},
		toolbox: {
			feature: {
				saveAsImage: {}
			}
		},
		tooltip: {
			trigger: 'axis'
		},
		yAxis: {
			type: 'value'
		},
		series: [
			{
				name: 'Facebook',
				type: 'bar',
				stack: 'total',
				label: {
					show: false
				},
				data: chartData.slice(1).map((element) => element[1])
			},
			{
				name: 'Instagram',
				type: 'bar',
				stack: 'total',
				label: {
					show: false
				},
				data: chartData.slice(1).map((element) => element[2])
			},
			{
				name: 'LinkedIn',
				type: 'bar',
				stack: 'total',
				label: {
					show: false
				},
				data: chartData.slice(1).map((element) => element[3])
			}
		]
	};

	if (chartData === null || chartData.length <= 1) {
		return (
			<Header
				isScoreVisible={false}
				label={`Veuillez connecter au moins un de vos réseaux sociaux pour avoir accès au tableau de bord de l'évolution de vos vues.`}
				organizationId={organizationId}
				title={name}
			/>
		);
	}

	return (
		<>
			<Header
				isScoreVisible={false}
				label={description}
				organizationId={organizationId}
				title={name}
				tooltip={tooltip}
			/>
			<Grid container spacing={3}>
				{isMobile || betweenSmallAndMd || betweenMdAndLg ? (
					''
				) : (
					<>
						<Grid item lg={4}></Grid>
						<Grid item lg={8}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									py: 2,
									pl: 4,
									justifyContent: 'start'
								}}
							>
								<ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
									<ToggleButton value="30days">{t`30 jours`}</ToggleButton>
									<ToggleButton value="90days">{t`90 jours`}</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						</Grid>
					</>
				)}
				<Grid
					item
					lg={4}
					xs={12}
					sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: isMobile ? 2 : 0 }}
				>
					{' '}
					<BoxAnalytics type="facebook" setHaveDataToDisplay={setHaveDataToDisplay} />
					<BoxAnalytics type="instagram" setHaveDataToDisplay={setHaveDataToDisplay} />
					<BoxAnalytics type="linkedin" setHaveDataToDisplay={setHaveDataToDisplay} />
				</Grid>
				{!isMobile ? (
					''
				) : (
					<>
						<Grid item md={12} xs={12}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									pt: 4,
									justifyContent: 'center'
								}}
							>
								<ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
									<ToggleButton value="30days">{t`30 jours`}</ToggleButton>
									<ToggleButton value="90days">{t`90 jours`}</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						</Grid>
					</>
				)}
				{betweenSmallAndMd || betweenMdAndLg ? (
					<>
						<Grid item md={12} xs={12}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									pt: 4,
									justifyContent: 'center'
								}}
							>
								<ToggleButtonGroup color="primary" exclusive={true} onChange={handleChange} value={alignment}>
									<ToggleButton value="30days">{t`30 jours`}</ToggleButton>
									<ToggleButton value="90days">{t`90 jours`}</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						</Grid>
					</>
				) : (
					''
				)}
				{chartData === null ? (
					<CircularProgress />
				) : (
					<Grid item lg={8} xs={12}>
						<ReactECharts option={option} />
					</Grid>
				)}
			</Grid>
			<Box
				sx={{
					display: 'flex',
					flexDirection: !isMobile ? 'row' : 'column'
				}}
			>
				{!haveDataToDisplay && t`pas de données`}
			</Box>
		</>
	);
};

class SocialNetworkViewsRecap implements ICustomBlock {
	tooltip = () => t`Les pages vues représentent les visites sur votre page d'entreprise ou votre profil.`;
	description = () => '';
	name = () => t`Vues de votre page d'entreprise`;
	key = () => 'socialNetworkViews-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default SocialNetworkViewsRecap;
