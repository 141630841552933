import type { FC } from 'react';

// Externals
import { Box, Skeleton, TableCell, TableRow, Typography, useTheme } from '@mui/material';

const Item: FC = () => {
	const theme = useTheme();

	return (
		<TableRow
			sx={{
				'&:nth-of-type(odd)': {
					backgroundColor: theme.palette.action.hover
				},
				'&:last-child td, &:last-child th': { border: 0 }
			}}
		>
			<TableCell align="center">
				<Typography color="textSecondary" variant="subtitle2">
					<Skeleton variant="text" width="100%" />
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start'
					}}
				>
					<Skeleton height={40} variant="circular" width={40} />
					<Skeleton sx={{ ml: 2 }} variant="text" width="calc(100% - 56px)" />
				</Box>
			</TableCell>
			<TableCell align="center">
				<Typography color="textPrimary" variant="subtitle2">
					<Skeleton variant="text" width="100%" />
				</Typography>
			</TableCell>
		</TableRow>
	);
};

export default Item;
