import React, { useMemo } from 'react';

// Externals
import { alpha, Box, CircularProgress, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { Verified } from '@mui/icons-material';

// Stores
import { useGetOrganizationTaskItemsAccomplishmentQuery } from 'components/legacy/services/taskitems';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useRouter } from 'next/router';

const TableAccomplishment = () => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const userLanguage = window.localStorage.getItem('preferred-language')
		? window.localStorage.getItem('preferred-language')
		: 'fr';
	const router = useRouter();

	const { data: rows, isLoading: rowLoading } = useGetOrganizationTaskItemsAccomplishmentQuery({ id: organizationId });
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});

	const nbrDone = rows?.data?.taskItems.filter(
		(q) => q.status.value === 'done' && q.publicationStatus.value === 'published'
	);

	const percentProgressionPerLevel = useMemo(
		() => (100 * nbrDone?.length) / rows?.data?.taskItems?.length,
		[rows, nbrDone]
	);
	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'end', mt: -2, mb: 2 }}>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<Box sx={{ position: 'relative' }}>
						<CircularProgress
							size={100}
							sx={{
								'&.MuiCircularProgress-colorPrimary': {
									color: '#F2F3FF'
								}
							}}
							thickness={5}
							value={100}
							variant="determinate"
						/>
						<CircularProgress
							size={100}
							sx={{
								position: 'absolute',
								left: 0,
								'&.MuiCircularProgress-colorPrimary': {
									color: (theme) => theme.palette.primary.main
								}
							}}
							thickness={5}
							value={percentProgressionPerLevel}
							variant="determinate"
						/>
					</Box>
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Typography component="div" sx={{ fontWeight: 700, fontSize: 20 }}>
							{`${nbrDone?.length}/${rows?.data?.taskItems?.length}`}
						</Typography>
					</Box>
				</Box>
			</Box>
			<Grid columns={{ xs: 4, sm: 8, md: 10 }} container={true} spacing={{ xs: 2, md: 3 }}>
				{rows?.data?.taskItems?.map((row) => {
					if (!userProfile?.data?.userProfile?.role && row.publicationStatus.value === 'draft')
						return <React.Fragment key={row.taskItemKey}></React.Fragment>;
					if (
						(row.publicationStatus.value === 'published' || userProfile?.data?.userProfile?.role) &&
						row.status.value === 'done'
					) {
						return (
							<Grid item={true} key={row.taskItemKey} md={2} sm={2} xs={2}>
								<Tooltip
									arrow={true}
									title={row.translations.find((x) => x.languageCode === userLanguage)?.title ?? ''}
								>
									<Paper
										elevation={3}
										onClick={() => router.push(row.url)}
										sx={{
											cursor: 'pointer',
											height: '100%',
											background: (theme) => theme.palette.success.light,
											display: 'flex',
											borderColor:
												row.publicationStatus.value === 'draft' && userProfile?.data?.userProfile?.role
													? '#E44E2D'
													: '',
											border: row.publicationStatus.value === 'draft' && userProfile?.data?.userProfile?.role ? 1 : 0,
											borderWidth:
												row.publicationStatus.value === 'draft' && userProfile?.data?.userProfile?.role ? '1em' : '0',
											flexDirection: 'column',
											textAlign: 'center'
										}}
									>
										<Box
											sx={{
												mt: 2,
												ml: 'auto',
												mr: 'auto',
												display: 'flex',
												justifyContent: 'center',
												width: 'max-content',
												height: 'max-content'
											}}
										>
											<Verified
												fontSize="large"
												sx={{ m: 1, color: (theme) => theme.palette.success.main, width: 50, height: 50 }}
											/>
										</Box>
										<Typography
											sx={{
												textAlign: 'center',
												mb: 2,
												mt: 1,
												mx: 0.5,
												fontWeight: 500,
												color: (theme) => theme.palette.success.main
											}}
										>
											{row.translations.find((x) => x.languageCode === userLanguage)?.messageDone ?? ''}
										</Typography>
									</Paper>
								</Tooltip>
							</Grid>
						);
					}
					return (
						<Grid item={true} key={row.taskItemKey} md={2} sm={2} xs={2}>
							<Tooltip arrow={true} title={row.translations.find((x) => x.languageCode === userLanguage)?.title ?? ''}>
								<Paper
									elevation={3}
									onClick={() => router.push(row.url)}
									sx={{
										cursor: 'pointer',
										height: '100%',
										boxShadow: 'none',
										color:
											row.publicationStatus.value === 'draft' && userProfile?.data?.userProfile?.role ? '#E44E2D' : '',
										border: row.publicationStatus.value === 'draft' && userProfile?.data?.userProfile?.role ? 1 : 0,
										background: (theme) => alpha(theme.palette.argent.main, 0.5),
										display: 'flex',
										flexDirection: 'column',
										textAlign: 'center'
									}}
								>
									<Box
										sx={{
											mt: 2,
											ml: 'auto',
											mr: 'auto',
											display: 'flex',
											justifyContent: 'center',
											width: 'max-content',
											height: 'max-content'
										}}
									>
										<Box
											alt="FreewayTeam"
											component="img"
											src="/static/icons/brand/logo_disabled.svg"
											sx={{
												height: 56,
												width: 'auto'
											}}
										/>
									</Box>
									<Typography
										sx={{
											textAlign: 'center',
											mb: 2,
											mt: 1,
											mx: 0.5,
											fontWeight: 500,
											color: 'white'
										}}
									>
										{row.translations.find((x) => x.languageCode === userLanguage)?.messageDone ?? ''}
									</Typography>
								</Paper>
							</Tooltip>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default TableAccomplishment;
