import * as React from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';

// Services
import connectorStorageKeys, { connectorStorageProperties } from 'components/legacy/connectors/connectorStorageKeys';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import ConnectedEntitySelection from 'components/legacy/components/connected-entity-selection';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';
import { useConnectFacebookMutation, useRemoveConnectionMutation } from 'components/legacy/services/connectors';
import { useDeleteSelectTokenUserMutation } from 'components/legacy/services/organizations';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
	tooltip: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
		organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
		userId: userProfile?.data?.userProfile?.id
	});
	const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
		roleId: role?.data?.member?.roleId
	});

	const [connectionStatus] = useStorageValue<string>(connectorStorageKeys.facebook.connectionStatus, organizationId);
	const [getAcessToken] = useStorageValueUser<any>(connectorStorageKeys.facebook.accessToken, 0);
	const [tokenSelectUser] = useStorageValue<string>(connectorStorageKeys.facebook.selectedUserId, organizationId);

	const [connectFacebook, { isLoading: loadingConnectFacebook }] = useConnectFacebookMutation();
	const [removeConnection, { isLoading: loadingRemoveconnection }] = useRemoveConnectionMutation();
	const [deleteSelectTokenUser, { isLoading: loadingDeleteSelectTokenUser }] = useDeleteSelectTokenUserMutation();

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;

	const accessToken = getAcessToken.value ? getAcessToken.value.accessToken : null;

	const handleLogin = () => {
		window.FB.login(
			function ({ authResponse, status }) {
				if (status === 'connected') {
					onAuthenticated(authResponse);
				}
			},
			{
				scope:
					'ads_read, business_management, pages_show_list, pages_read_user_content, read_insights, pages_read_engagement',
				return_scopes: true
			}
		);
	};

	const handleLogout = async () => {
		if (tokenSelectUser.value) await deleteSelectTokenUser({ namespace: 'facebook', organizationId: organizationId });
		await removeConnection({
			connectorAuthName: 'facebook'
		});
	};

	const onAuthenticated = async (response) => {
		await connectFacebook({
			facebookToken: response.accessToken,
			facebookUserId: response.userID
		});
	};

	return (
		<>
			<Header
				isScoreVisible={true}
				label={''}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>
			{accessToken ? (
				<>
					{!tokenSelectUser.value ||
					tokenSelectUser.value !== userProfile?.data?.userProfile.id ||
					!connectionExpired ? (
						<>
							<Typography sx={{ fontWeight: 600 }}>Page Facebook</Typography>
							<ConnectedEntitySelection
								key="facebookPage"
								selectionLabel={t`Sélectionner une page`}
								connector="facebook"
								connectedEntityPropKey={connectorStorageProperties.connectedEntities}
								selectedEntityPropKeys={connectorStorageProperties.selectedEntity}
							/>
							<Typography sx={{ fontWeight: 600 }}>Compte Meta Ads</Typography>
							<ConnectedEntitySelection
								key="facebookAd"
								selectionLabel={t`Sélectionner un compte publicitaire`}
								connector="facebook"
								connectedEntityPropKey={connectorStorageProperties.connectedAdEntities}
								selectedEntityPropKeys={connectorStorageProperties.selectedAdEntity}
							/>
						</>
					) : (
						''
					)}
					{!tokenSelectUser.value || connectionExpired ? (
						<>
							<Typography sx={{ pt: 2, pb: 2 }} variant="body2">
								{connectionExpired &&
								tokenSelectUser.value &&
								tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
									<Typography sx={{ color: (theme) => theme.palette.red.main }}>
										<Trans>
											Il semble que votre session Facebook ait expiré. Vous devez vous reconnecter à votre compte.
										</Trans>
									</Typography>
								) : accessToken ? (
									<Trans>
										Si le compte de votre organisation n'apparait pas dans la liste, reconnectez votre compte Facebook,
										et sélectionnez tous vos comptes disponibles. Vous pourrez ensuite choisir celui visible par les
										membres de cette organisation.
									</Trans>
								) : (
									<Trans>Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.</Trans>
								)}
							</Typography>
							<LoadingButton
								disabled={
									loadingConnectFacebook ||
									loadingRemoveconnection ||
									!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
										? true
										: false
								}
								loading={loadingConnectFacebook || loadingRemoveconnection}
								onClick={() => (!connectionExpired && accessToken ? handleLogout() : handleLogin())}
								startIcon={<Box component="img" src="/static/icons/brand/facebook_color.svg" />}
								sx={{
									borderColor: '#5890FF',
									border: 1.5,
									color: '#5890FF',
									borderRadius: '18px',
									fontFamily: 'Helvetica, Arial, sans-serif',
									py: 1.5,
									px: 1.5,
									whiteSpace: 'nowrap',
									maxWidth: 'max-content',
									height: '44px'
								}}
							>
								{!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Facebook`}
							</LoadingButton>
						</>
					) : (
						''
					)}
				</>
			) : (
				<>
					<Typography sx={{ pb: 2 }} variant="body2">
						{connectionExpired &&
						tokenSelectUser.value &&
						tokenSelectUser.value === userProfile?.data?.userProfile.id ? (
							<Typography sx={{ color: (theme) => theme.palette.red.main }}>
								<Trans>
									Il semble que votre session Facebook ait expiré. Vous devez vous reconnecter à votre compte.
								</Trans>
							</Typography>
						) : accessToken ? (
							<Trans>
								Si le compte de votre organisation n'apparait pas dans la liste, reconnectez votre compte Facebook, et
								sélectionnez tous vos comptes disponibles. Vous pourrez ensuite choisir celui visible par les membres de
								cette organisation.
							</Trans>
						) : (
							<Trans>Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.</Trans>
						)}
					</Typography>
					<LoadingButton
						disabled={
							loadingConnectFacebook ||
							loadingRemoveconnection ||
							!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
								? true
								: false
						}
						loading={loadingConnectFacebook || loadingRemoveconnection}
						onClick={() => (!connectionExpired && accessToken ? handleLogout() : handleLogin())}
						startIcon={<Box component="img" src="/static/icons/brand/facebook_color.svg" />}
						sx={{
							borderColor: '#5890FF',
							border: 1.5,
							color: '#5890FF',
							borderRadius: '18px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							py: 1.5,
							px: 1.5,
							whiteSpace: 'nowrap',
							maxWidth: 'max-content',
							height: '44px'
						}}
					>
						{!connectionExpired && accessToken ? t`Se déconnecter` : t`Se connecter à Facebook`}
					</LoadingButton>
				</>
			)}
		</>
	);
};

class FacebookRecap implements ICustomBlock {
	description = () => t`Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.`;
	name = () => t`Facebook`;
	key = () => 'facebook-recap';
	tooltip = () =>
		t` Connectez un compte personnel administrateur de la page Facebook de l'entreprise pour avoir accès à ses chiffres clés , tels que le nombre de followers, le nombre de visites sur votre page et le nombre de likes, et des graphes de leur évolution. 
    FreewayTeam ne partage pas vos données avec des tiers, ne fait aucune action avec votre compte entreprise, et n'a pas accès à votre compte privé. Cette connexion est validée et contrôlée par Meta (Facebook), et les données sont supprimées de FreewayTeam si vous déconnectez votre compte Facebook. 
    Vous devez d'abord connecter un compte Facebook si vous souhaitez connecter un compte Instagram Business.`;
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default FacebookRecap;
