// Internals
import { ICustomBlock, UserComponentProps } from '../../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import GenericList from 'components/legacy/components/layouts/lists';

// Externals
import { t } from '@lingui/macro';
import { isEmpty } from 'lodash';
import _ from 'lodash';

// Models
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';

// We could create a custom Admin Component if necessary
// const AdminComponent = () => {
//   return <div>Hello</div>;
// };

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const MAILTESTER_FORBIDDENTAGS_COLD = 'mailtester:forbiddentags-cold';

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps, props) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const [forbiddenTags] = useStorageValue<any>(MAILTESTER_FORBIDDENTAGS_COLD, organizationId);

	return (
		<>
			{!isEmpty(forbiddenTags.value) ? (
				<>
					<Header
						isScoreVisible={true}
						label={description}
						organizationId={organizationId}
						score={score}
						title={name}
					/>
					<GenericList
						data={forbiddenTags.value.list}
						nbrElement={`${forbiddenTags.value.list.length}`}
						title={forbiddenTags.value.list.length + ' ' + t` contenu(s) douteux ont été trouvé(s).`}
					/>
				</>
			) : (
				''
			)}
		</>
	);
};

class ForbiddenTagColdRecap implements ICustomBlock {
	description = () => t`Nous regardons si du contenu douteux figure dans l'email que vous nous avez envoyé.`;
	name = () => t`Contenu douteux dans votre email`;
	key = () => 'forbidentag-cold-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default ForbiddenTagColdRecap;
