// Externals
import { t } from '@lingui/macro';
import { ICustomBlock, UserComponentProps } from '..';
import { Header } from 'components/legacy/pages/audit/questions/header';
import { Grid } from '@mui/material';

// Components

// Redux
import { useSelector } from 'components/legacy/store';

// Hooks
import useMoment from 'components/legacy/hooks/useMoment';

interface ComponentProps {
	name: string;
	description: string;
	tips?: string;
}

const UserComponent = ({ name, description, tips }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const dateNow = Date.now();
	const moment = useMoment();

	name = t`Rapport du ${moment(dateNow).subtract(7, 'days').format('L')} au ${moment(dateNow).format('L')}*`;
	description = t`Comparaison avec les 7 jours précédents cette période.`;
	return (
		<>
			<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} tooltip={tips} />

			<Grid columns={{ xs: 1, sm: 8, md: 12 }} container={true} spacing={{ xs: 2, md: 3 }}></Grid>
		</>
	);
};

class Report implements ICustomBlock {
	description = () => t`Récapitulatif des chiffres clés et conseils.`;
	name = () => t`Rapport`;
	key = () => 'report';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent description={this.description()} name={this.name()} />
	);
}

export default Report;
