import React from 'react';

// Components
import { NetworkKey } from 'components/legacy/components/network';

function renderTextWithHashtagsAndMentionsLinkedin(text: string, readOnly: boolean = false) {
	const hashtagRegex = /\{hashtag\|\\#\|(.+?)\}/g;
	// eslint-disable-next-line no-useless-escape
	const mentionRegex = /\@\[([^\]]+)\]\(urn:li:person:([^\)]+)\)/g;

	const parts = text.split(hashtagRegex);
	return parts.map((part, index) => {
		if (index % 2 === 1) {
			const hashtag = part.trim();
			const url = `https://www.linkedin.com/feed/hashtag/?keywords=${hashtag}`;
			return (
				<React.Fragment key={index}>
					{readOnly ? (
						`#${hashtag}`
					) : (
						<a target="_blank" rel="noopener noreferrer" href={url}>
							#{hashtag}
						</a>
					)}
				</React.Fragment>
			);
		} else {
			const mentionParts = part.split(mentionRegex);
			return mentionParts.map((mentionPart, mentionIndex) => {
				if (mentionIndex % 3 === 1) {
					const mentionName = mentionPart;
					return <React.Fragment key={`${index}-${mentionIndex}`}>@{mentionName}</React.Fragment>;
				} else if (mentionIndex % 3 === 2) {
					return '';
				} else {
					const lines = mentionPart.split('\n');
					return lines.map((line, lineIndex) => (
						<React.Fragment key={`${index}-${mentionIndex}-${lineIndex}`}>
							{line}
							{line === '' && <br />}
						</React.Fragment>
					));
				}
			});
		}
	});
}

function renderTextWithHashtagsAndMentionsFacebook(text: string, readOnly: boolean = false) {
	return text;
}

/**
 * RenderText component
 * Render Text correctly with tag and/or Mentions readable for user.
 *
 * @param network - The name of the network.
 * @param text - Text must be formatted.
 * @param defaultValueIsTextIsEmpty - If @param text is empty, the defaultValue will be displayed.
 * @param readOnly - Render hashtags or mentions clickable. If false, the render will be like normal text.
 * @version 1.0.0 [DCH] - Initial version of the RenderText component.
 */
export default function renderTextWithHashtagsAndMentions(
	network: NetworkKey,
	text: string,
	defaultValueIsTextIsEmpty: string = null,
	readOnly: boolean = false
) {
	if (text === null || text === '') return defaultValueIsTextIsEmpty;
	if (network === 'linkedin') {
		return renderTextWithHashtagsAndMentionsLinkedin(text, readOnly);
	}
	if (network === 'facebook') {
		return renderTextWithHashtagsAndMentionsFacebook(text, readOnly);
	}
	return text;
}
