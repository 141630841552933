import { useEffect, useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';

// Externals
import { t } from '@lingui/macro';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import toast from 'react-hot-toast';

// Redux
import { useSelector } from 'components/legacy/store';
import { AnswerDto } from 'components/legacy/models/answer.dto';
import { useGetDetailsMailTesterMutation } from 'components/legacy/services/connectors';
import { useGetQuestionAnswersQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';

// Services

// We could create a custom Admin Component if necessary
// const AdminComponent = () => {
//   return <div>Hello</div>;
// };

interface ComponentProps {
	name: string;
	description: string;
	connectorKey: string;
	questionKey: string;
}

const UserComponent = ({ name, description, connectorKey, questionKey }: ComponentProps, props) => {
	const [data, setData] = useState<any>([]);
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
		organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
		userId: userProfile?.data?.userProfile?.id
	});
	const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
		roleId: role?.data?.member?.roleId
	});

	const index = process.env.NEXT_PUBLIC_MAIL_TESTER;
	const testCopi = 'freewayteam-' + index + '-pro-' + organizationId + '@mail-tester.com';
	const { auditKey } = useParams();
	const { data: answersQuery, isLoading: loadingAnswers } = useGetQuestionAnswersQuery({
		auditKey,
		organizationId
	});
	const answers = answersQuery?.data?.answers?.audit?.answers?.filter((a) => a.questionKey === questionKey);

	const handleCopy = () => {
		navigator.clipboard.writeText(testCopi);
		toast.success(t`Copié dans votre presse-papier.`);
	};

	const [getDetailsMailTester] = useGetDetailsMailTesterMutation();

	useEffect(() => {
		(async () => {
			let response = await getDetailsMailTester({
				OrganizationId: organizationId,
				EmailAccount: 'freewayteam-' + index + '-pro-' + organizationId + '@mail-tester.com',
				Context: 'pro'
			}).unwrap();
			setData(response.data);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [organizationId, index]);

	if (answers) {
		if (answers[0]?.choiceKey === 'mailtester-has-report-pro') {
			description = t`Si vous avez changé votre configuration, veuillez renvoyer un email à l'adresse suivante et patienter quelques minutes.`;
		} else {
			description = t`Envoyez un email à l'adresse ci-dessous afin que nous puissions analyser la configuration technique (DNS) de votre serveur de messagerie et sa présence sur des listes noires, facteurs contribuant à la délivrabilité de vos emails.`;
		}
	}

	return (
		<>
			<Header isScoreVisible={false} label={description} organizationId={organizationId} title={name} />
			<Box
				sx={{
					flexDirection: 'row'
				}}
			>
				{answers && answers[0].choiceKey === 'mailtester-has-report-pro' ? (
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ width: '100%' }}>
							<Grid container={true} sx={{ verticalAlign: 'baseline', pb: 2, pr: 2 }}>
								<Box
									sx={{
										width: 'max-content',
										height: 'max-content',
										borderRadius: '20px',
										overflow: 'hidden'
									}}
								>
									<Button
										disableRipple={true}
										disabled={!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer') ? true : false}
										onClick={handleCopy}
										sx={{
											height: '10%',
											userSelect: 'text',
											'&::after': {
												content: '""',
												display: 'block',
												position: 'absolute',
												left: 0,
												top: 0,
												width: '100%',
												height: '100%',
												opacity: 0,
												transition: 'all 0.5s'
											},

											'&:active::after': {
												position: 'absolute',
												borderRadius: '5px',
												left: 0,
												top: 0,
												opacity: 1,
												transition: '0s'
											}
										}}
									>
										{!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
											? t`Vous n'avez pas les droits`
											: testCopi}
										<ContentCopy
											fontSize={'large'}
											sx={{ cursor: 'pointer', pl: 1, color: (theme) => theme.palette.primary.main }}
										></ContentCopy>
									</Button>
								</Box>
							</Grid>
							<Typography paragraph={true} sx={{ mt: 2 }} variant="body2">
								{t`Date de la dernière analyse : `} {data ? data.dateReceived : ''}
							</Typography>
						</Box>
					</Box>
				) : (
					<Box
						sx={{
							borderRadius: '20px',
							overflow: 'hidden'
						}}
					>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', height: 'max-content' }}>
							<Box sx={{ width: '50%', pt: '20px', pb: '20px', pl: '20px' }}>
								<Typography display="block" fontSize={15} paragraph={true} variant="overline">
									{t`1 - Envoyez un e-mail vide`}
								</Typography>
								<Grid container={true} sx={{ verticalAlign: 'baseline', pt: 2, pr: 2 }}>
									<Button
										disableRipple={true}
										disabled={!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer') ? true : false}
										onClick={handleCopy}
										sx={{
											height: '10%',
											userSelect: 'text',
											'&::after': {
												content: '""',
												display: 'block',
												position: 'absolute',
												left: 0,
												top: 0,
												width: '100%',
												height: '100%',
												opacity: 0,
												transition: 'all 0.5s'
											},

											'&:active::after': {
												position: 'absolute',
												borderRadius: '5px',
												left: 0,
												top: 0,
												opacity: 1,
												transition: '0s'
											}
										}}
									>
										{!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer')
											? t`Vous n'avez pas les droits`
											: testCopi}
										<ContentCopy
											fontSize={'large'}
											sx={{ cursor: 'pointer', pl: 1, color: (theme) => theme.palette.primary.main }}
										></ContentCopy>
									</Button>
								</Grid>
							</Box>
							<Divider flexItem={true} orientation="vertical" variant="middle" />
							<Box sx={{ width: '50%' }}>
								<Box sx={{ pl: '20px', pt: '20px' }}>
									<Typography display="block" fontSize={15} paragraph={true} variant="overline">
										{t`2 - Analyse`}
									</Typography>
									<Grid container={true}>
										<Typography fontSize={17} paragraph={true}>
											{t`Patience, d'ici quelques minutes les résultats devraient s'afficher...`}
										</Typography>
									</Grid>
								</Box>
							</Box>
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

class MailTesterProRecap implements ICustomBlock {
	description = () =>
		t`Plusieurs facteurs rentrent en jeu pour vérifier si vos mails ne vont pas dans le courrier indésirable des destinataires. Vous pouvez envoyer un email à l'adresse indiquée ci-dessous pour pouvoir la vérifier ainsi que le corps de votre message.`;
	name = () => t`Délivrabilité de votre email professionnel`;
	key = () => 'mailtester-pro-recap';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			name={this.name()}
			questionKey={props.questionKey}
		/>
	);
}

export default MailTesterProRecap;
