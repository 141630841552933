import { useState } from 'react';

// Internals
import { ICustomBlock, UserComponentProps } from '../index';
import { Header } from 'components/legacy/pages/audit/questions/header';
import GoogleConnect from '../google/connect';
import { ComponentProps } from '../google/connect/models';
import { AdsProperty } from './models';

// Externals
import _ from 'lodash';
import { t, Trans } from '@lingui/macro';
import { MenuItem, TextField, Stack, Typography, CircularProgress } from '@mui/material';

// Stores
import { useSelector } from 'components/legacy/store';
import { QuestionScoreDto } from 'components/legacy/models/questionScore.dto';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

// Services

// Hooks
import useStorageValue from 'components/legacy/hooks/useStorageValue';
import ConnectorAuthValues from 'components/legacy/connectors/connectorAuthValues';
import useStorageValueUser from 'components/legacy/hooks/useStorageValueUser';

// Query & mutations
import { useSelectPropertyAdsMutation } from 'components/legacy/services/connectors';
import {
	useDeleteSelectTokenUserMutation,
	usePostSelectTokenUserMutation
} from 'components/legacy/services/organizations';
import { useGetQuestionScoresQuery } from 'components/legacy/services/questions';
import { useParams } from 'react-router-dom';
import { useGetUserProfileQuery } from 'components/legacy/services/userProfiles';
import { useGetSingleRoleQuery } from 'components/legacy/services/organization-roles';
import { useGetOrganizationMemberQuery } from 'components/legacy/services/organizationMember';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { socialDataApi } from 'components/legacy/services/socialData';

const UserComponent = ({ name, description, connectorKey, questionKey, isScoreVisible, tooltip }: ComponentProps) => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const dispatch = useDispatch();
	const { data: userProfile, isLoading: loadingUserProfile } = useGetUserProfileQuery({});
	const { data: role, isLoading: loadingRoleAndPermissions } = useGetOrganizationMemberQuery({
		organizationId: userProfile?.data?.userProfile?.currentOrganizationId,
		userId: userProfile?.data?.userProfile?.id
	});
	const { data: roleDetail, isLoading: loadingRoleDetail } = useGetSingleRoleQuery({
		roleId: role?.data?.member?.roleId
	});

	const [connectionStatus] = useStorageValue<string>(connectorStorageKeys.google.connectionStatus, organizationId);
	const [getAcessToken] = useStorageValueUser<any>(connectorStorageKeys.google.accessToken, 0);
	const [tokenSelectUserAds] = useStorageValue<string>(connectorStorageKeys.googleAds.selectedUserId, organizationId);

	const [adsPropertyId] = useStorageValue<string>(connectorStorageKeys.googleAds.selectedEntity, organizationId);
	const [adsPropertyName] = useStorageValue<string>(connectorStorageKeys.googleAds.selectedEntityName, organizationId);
	const [adsProperties] = useStorageValueUser<AdsProperty[]>(connectorStorageKeys.googleAds.listProperties, 0);

	const [propertyy, setProperty] = useState<any>(null);
	const [loader, setLoader] = useState<any>(false);

	const [selectPropertyAds] = useSelectPropertyAdsMutation();
	const [deleteSelectTokenUser] = useDeleteSelectTokenUserMutation();
	const [postSelectTokenUser] = usePostSelectTokenUserMutation();

	const { auditKey } = useParams();

	const { data: scoresQuery, isLoading: loadingScores } = useGetQuestionScoresQuery({
		organizationId,
		auditKey
	});

	const scores: QuestionScoreDto[] = useSelector<QuestionScoreDto[]>((state) =>
		scoresQuery?.data?.questions?.filter((s) => s.key === questionKey)
	);
	const score = _.sumBy(scores, (s) => s.score);

	const connectionExpired = connectionStatus.value === ConnectorAuthValues.expired;
	const selectedEntity = adsPropertyId.value ? adsPropertyId.value : '';
	const selectedEntityName = adsPropertyName.value ? adsPropertyName.value : '';
	const accessToken = getAcessToken.value ? getAcessToken.value.token : null;
	const currentGoogleVersion = 'google-ads';

	const handleAssociateEntity = async (propertyId: string, propertyName: string) => {
		setLoader(true);
		await postSelectTokenUser({
			namespace: 'google-ads',
			organizationId: organizationId,
			userId: userProfile?.data?.userProfile?.id
		}).then(async () => {
			await selectPropertyAds({
				propertyId: propertyId,
				propertyName: propertyName
			});
			dispatch(socialDataApi.util.invalidateTags(['sn-data']));
		});

		setLoader(false);
	};

	const handleDissociateEntity = async () => {
		setLoader(true);
		await deleteSelectTokenUser({ namespace: currentGoogleVersion, organizationId: organizationId });
		dispatch(socialDataApi.util.invalidateTags(['sn-data']));
		setLoader(false);
	};

	if (loader || adsPropertyId.loading || adsPropertyName.loading) return <CircularProgress />;

	return (
		<>
			<Header
				isScoreVisible={isScoreVisible}
				label={selectedEntityName || accessToken ? '' : description}
				organizationId={organizationId}
				score={score}
				title={name}
				tooltip={tooltip}
			/>

			{(accessToken || selectedEntity || selectedEntityName) && !connectionExpired ? (
				<>
					{selectedEntityName ? (
						<Stack direction="row" spacing={2} sx={{ alignItems: 'center', mb: 2 }}>
							<Typography>{adsPropertyName.value}</Typography>
							<LoadingButton
								loading={loader || adsPropertyName.loading}
								color="primary"
								onClick={() => handleDissociateEntity()}
								variant="contained"
							>
								<Trans>Dissocier ce compte</Trans>
							</LoadingButton>
						</Stack>
					) : (
						<>
							<Stack direction="row" spacing={1} sx={{ mt: 2, mb: 2 }}>
								<TextField
									disabled={!roleDetail?.data?.role?.permissions.find((r) => r.key === 'answer') ? true : false}
									id="ads-property-select"
									label={t`Sélectionner un compte`}
									margin="normal"
									select={true}
									onChange={(e) => {
										if (e.target.value) {
											setProperty(e.target.value);
										}
									}}
									sx={{
										'& label': {
											color: '#FF0000'
										},
										mb: 0,
										width: '50%'
									}}
									value={propertyy?.id ? propertyy.id : ''}
								>
									{adsProperties.value?.map((customer) => {
										return customer.customerClients && customer.customerClients.length > 0 ? (
											customer.customerClients.map((client) => {
												return (
													<MenuItem
														onClick={() => {
															handleAssociateEntity(
																client.entityName,
																customer.descriptiveName + ' - ' + client.descriptiveName
															);
														}}
														key={client.entityName}
														value={client.entityName}
													>
														{client.descriptiveName == null && client.descriptiveName.trim() == ''
															? client.entityName
															: customer.descriptiveName + ' - ' + client.descriptiveName}
													</MenuItem>
												);
											})
										) : (
											<MenuItem
												onClick={() => {
													handleAssociateEntity(customer.entityName, customer.descriptiveName);
												}}
												key={customer.entityName}
												value={customer.entityName}
											>
												{customer.descriptiveName == null && customer.descriptiveName.trim() == ''
													? customer.entityName
													: customer.descriptiveName}
											</MenuItem>
										);
									})}
								</TextField>
							</Stack>
							<Typography sx={{ mt: 2, mb: 2 }} variant="body2">
								<Trans>
									Si la propriété de votre organisation n'apparait pas dans la liste, reconnectez un compte Google ayant
									accès à la page de votre organisation.
								</Trans>
							</Typography>
							<GoogleConnect
								accessTokenKey={connectorStorageKeys.google.accessToken}
								connectionStatusKey={connectorStorageKeys.google.connectionStatus}
								tokenSelectUserKey={connectorStorageKeys.google.selectedUserId}
							/>
						</>
					)}
				</>
			) : connectionExpired && tokenSelectUserAds.value === userProfile?.data?.userProfile.id ? (
				<Typography sx={{ color: (theme) => theme.palette.red.main }}>
					<Trans>Il semble que votre session Google ait expiré. Vous devez vous reconnecter à votre compte.</Trans>
				</Typography>
			) : (
				''
			)}
			{(!accessToken && !selectedEntity) || connectionExpired ? (
				<>
					<GoogleConnect
						accessTokenKey={connectorStorageKeys.google.accessToken}
						connectionStatusKey={connectorStorageKeys.google.connectionStatus}
						tokenSelectUserKey={connectorStorageKeys.google.selectedUserId}
					/>
				</>
			) : (
				''
			)}
		</>
	);
};

class GoogleAds implements ICustomBlock {
	name = () => t`Google Ads`;
	description = () => t`Connectez le compte de votre entreprise pour obtenir une analyse de ses données clés.`;
	tooltip = () => t``;
	isScoreVisible = () => true;
	key = () => 'google-ads-connect';
	UserComponent: React.FC<UserComponentProps> = (props) => (
		<UserComponent
			connectorKey={this.key()}
			description={this.description()}
			isScoreVisible={this.isScoreVisible()}
			name={this.name()}
			questionKey={props.questionKey}
			tooltip={this.tooltip()}
		/>
	);
}

export default GoogleAds;
