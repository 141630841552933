import React, { useEffect, useState } from 'react';
import { CircularProgress, Theme, Typography, useMediaQuery } from '@mui/material';
import { Trans, t } from '@lingui/macro';
import { useSelector } from 'components/legacy/store';
import PublicationTableDesktop from './publication-table-desktop';
import PublicationTableMobile from './publication-table-mobile';

import { GetSocialAdsData } from 'components/legacy/services/socialData-ads';

import useStorageValue from 'components/legacy/hooks/useStorageValue';
import connectorStorageKeys from 'components/legacy/connectors/connectorStorageKeys';

const PublicationTable = () => {
	const organizationId = Number(window.localStorage.getItem('organizationId'));
	const [rows, setRows] = useState<any>(null);
	const [pageSize, setPageSize] = useState<number>(5);
	const [pageNumber, setPageNumber] = useState<number>(0); //first page is 0

	const [totalRows, setTotalRows] = useState<number>(100);
	const [sortColumn, setSortColumn] = useState<string>('PublishedTime'); // Colonne de tri par default
	const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Ordre de tri (ascendant ou descendant)
	const [filterAvailable, setFilterAvailable] = useState<string[]>();
	const [filterNetwork, SetFilterNetwork] = useState<string[]>();
	const [filterStatus, SetFilterStatus] = useState<string>('ALL');
	const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedLinkedin] = useStorageValue<string>(connectorStorageKeys.linkedin.selectedEntity, organizationId);
	const [selectedFacebook] = useStorageValue<string>(connectorStorageKeys.facebook.selectedEntity, organizationId);
	const [selectedInstagram] = useStorageValue<string>(connectorStorageKeys.instagram.selectedEntity, organizationId);
	const hasRsSelected =
		selectedLinkedin.value !== null || selectedFacebook.value !== null || selectedInstagram.value !== null;

	const handleRequestSort = (columnId, order) => {
		setSortColumn(columnId);
		setSortOrder(order);
		setPageNumber(0);
	};

	const handleChangePage = (event, newPage) => {
		setPageNumber(newPage);
	};

	useEffect(() => {
		(async () => {
			try {
				const dataResponse = await GetSocialAdsData({
					pageSize,
					pageNumber,
					sortColumn,
					sortOrder,
					filterNetwork,
					filterStatus
				});

				if (dataResponse.errors?.length > 0) {
					throw Error(`Erreur GetSocialAdsData: ${dataResponse.errors.join(';')}`);
				}
				setRows(dataResponse.data.result);
				setTotalRows(dataResponse.data.totalItems);
				setFilterAvailable(dataResponse.data.networkAvailable);
			} catch (e) {
				console.error(t`erreur lors de la récupération des publications : `, e);
			} finally {
				setIsLoading(false);
			}
		})();
	}, [pageSize, pageNumber, sortColumn, sortOrder, organizationId, filterNetwork, filterStatus]);

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(parseInt(event.target.value, 10));
		setPageNumber(0);
	};
	const handleChangeFilterNetwork = (filteredNetwork: string[]) => {
		SetFilterNetwork(filteredNetwork);
		setPageNumber(0);
	};
	const handleChangeFilterStatus = (filteredStatus: string) => {
		SetFilterStatus(filteredStatus);
		setPageNumber(0);
	};

	if (isLoading) {
		return <CircularProgress />;
	} else {
		if (!hasRsSelected) {
			return (
				<Typography variant="body2">
					<Trans>
						Veuillez lier au moins un de vos comptes LinkedIn, Facebook ou Instagram afin d'accéder aux données
						synthétiques de vos publications.
					</Trans>
				</Typography>
			);
		} else {
			if (isMobile) {
				return (
					<PublicationTableMobile
						rows={rows}
						totalRows={totalRows}
						pageSize={pageSize}
						pageNumber={pageNumber}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						sortColumn={sortColumn}
						sortOrder={sortOrder}
						handleRequestSort={handleRequestSort}
					/>
				);
			} else {
				return (
					<PublicationTableDesktop
						rows={rows}
						totalRows={totalRows}
						pageSize={pageSize}
						pageNumber={pageNumber}
						handleChangePage={handleChangePage}
						handleChangeRowsPerPage={handleChangeRowsPerPage}
						sortColumn={sortColumn}
						sortOrder={sortOrder}
						handleChangeFilterStatus={handleChangeFilterStatus}
						handleRequestSort={handleRequestSort}
						handleChangeFilterNetwork={handleChangeFilterNetwork}
						filterAvailable={filterAvailable}
					/>
				);
			}
		}
	}
};

export default PublicationTable;
