import { Trans } from '@lingui/macro';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import KeyWordsTable from '../..';

const KeyWordsTableHead = ({ onRequestSort, sortColumn, sortOrder }) => {
	const handleSort = (columnId) => {
		const isAsc = sortColumn === columnId && sortOrder === 'asc';

		onRequestSort(columnId, isAsc ? 'desc' : 'asc');
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell
					key="KeyWords"
					align="left"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel
						active={sortColumn === 'KeyWords'}
						direction={sortOrder}
						onClick={() => handleSort('KeyWords')}
					>
						<Trans>Mots-clés</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="Spent"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel active={sortColumn === 'Spent'} direction={sortOrder} onClick={() => handleSort('Spent')}>
						<Trans>Dépensé</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="Impressions"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel
						active={sortColumn === 'Impressions'}
						direction={sortOrder}
						onClick={() => handleSort('Impressions')}
					>
						<Trans>Impressions</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="CTR"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel active={sortColumn === 'CTR'} direction={sortOrder} onClick={() => handleSort('CTR')}>
						<Trans>CTR</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="Clicks"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel active={sortColumn === 'Clicks'} direction={sortOrder} onClick={() => handleSort('Clicks')}>
						<Trans>Clics</Trans>
					</TableSortLabel>
				</TableCell>
				<TableCell
					key="CPC"
					align="right"
					padding="none"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: (theme) => theme.palette.background.default,
						zIndex: (theme) => theme.zIndex.appBar
					}}
				>
					<TableSortLabel active={sortColumn === 'CPC'} direction={sortOrder} onClick={() => handleSort('CPC')}>
						<Trans>CPC</Trans>
					</TableSortLabel>
				</TableCell>
			</TableRow>
		</TableHead>
	);
};

export default KeyWordsTableHead;
