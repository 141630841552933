import { useState } from 'react';

// Externals
import { t } from '@lingui/macro';
import {
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	TableFooter,
	TablePagination,
	ToggleButtonGroup,
	ToggleButton,
	TableHead,
	Theme,
	useMediaQuery,
	Button
} from '@mui/material';
import { round } from 'lodash';
import { PlayCircleOutline, PauseCircleOutline } from '@mui/icons-material';

// Hooks

// Components

import AdsTableHead from './header';
import { DialogDetails } from './options-desktop';
import React from 'react';

interface AdsDetailsProps {
	row: any;
	index: number;
}

/**
 * AdsTableDesktop component
 * Renders a campaign table for desktop.
 *
 * @param {SocialAds[]} rows - Data displayed on the table page.
 * @param {number} totalRows - Number of available items.
 * @param {number} pageSize - Number of items displayed per page.
 * @param {number} pageNumber - Number of pages.
 * @param {string} sortColumn - Name of the sorted column.
 * @param {string} sortOrder - Column sort order (desc or asc).
 * @param {array} filterAvailable - Filters available for the table.
 * @param {function} handleChangePage - Action triggered when the user changes the page.
 * @param {function} handleChangeRowsPerPage - Action triggered when the user changes the number of items per page.
 * @param {function} handleChangeFilterNetwork - Action triggered when filtering by network the table.
 * @param {function} handleChangeFilterStatus - Action triggered when filtering by status the table.
 * @param {function} handleRequestSort - Action triggered when changing the sort column.
 * @version 1.0.0 [DCH] - Initial version of the AdsTableDesktop component.
 */

const AdsTableDesktop = ({
	rows,
	totalRows,
	pageSize,
	pageNumber,
	sortColumn,
	sortOrder,
	handleChangePage,
	handleChangeRowsPerPage,
	handleChangeFilterStatus,
	handleRequestSort
}) => {
	const [alignment, setAlignment] = useState<string>('ALL');
	const upLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
	const datas = [];

	const handleClickFilterStatus = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
		if (newAlignment === null) return;
		setAlignment(newAlignment);
		handleChangeFilterStatus(newAlignment);
	};
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	rows?.map((element) => datas.push(JSON.parse(element?.data)));

	const AdsDetails: React.FC<AdsDetailsProps> = ({ row, index }) => {
		return (
			<Box
				sx={{
					margin: 0,
					padding: 0,
					maxWidth: 'auto',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'left',
					align: 'left'
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',

						verticalAlign: 'center',
						margin: 0
					}}
				>
					<Box
						sx={{
							display: 'flex',

							flexDirection: 'column',
							maxWidth: upLg ? '400px' : '250px'
						}}
					>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							{row.status === 'ACTIVE' ? (
								<PlayCircleOutline color="success" />
							) : (
								<PauseCircleOutline color="disabled" />
							)}

							<Typography
								sx={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
									display: 'inline-block',
									maxHeight: '1.5em',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 1,
									fontWeight: 'medium'
								}}
							>
								{/* row.name = titre de la campagne
              row.text = description de la campagne
              (row.name !== null ? row.adsId : 'titre')
              */}
								{row.name}
							</Typography>
						</Box>

						<Typography
							sx={{
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
								display: 'inline-block',
								maxHeight: '1.5em',
								WebkitBoxOrient: 'vertical',
								WebkitLineClamp: 1
							}}
						>
							{row.text}
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	};

	return rows === null || rows?.length === 0 ? (
		<Typography variant="body2">{t`Pas de données pour le moment.`}</Typography>
	) : (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					pb: 2
				}}
			>
				<ToggleButtonGroup color="primary" exclusive={true} onChange={handleClickFilterStatus} value={alignment}>
					<ToggleButton value="ACTIVE">{t`Active`}</ToggleButton>
					<ToggleButton value="ALL">{t`Toutes`}</ToggleButton>
				</ToggleButtonGroup>
			</Box>

			<TableContainer>
				<Table size="small">
					<TableHead>
						<AdsTableHead
							onRequestSort={handleRequestSort}
							sortColumn={sortColumn}
							sortOrder={sortOrder}
						></AdsTableHead>
					</TableHead>

					<TableBody>
						{rows === null ||
							(rows?.length === 0 && (
								<TableRow key="empty">
									<Typography variant="body2">{t`Aucune données n'a été trouvée`}</Typography>
								</TableRow>
							))}
						{rows?.map((adDetails, index) => {
							return (
								<TableRow key={adDetails.adsId}>
									<TableCell>
										<AdsDetails row={adDetails} index={index} />
									</TableCell>
									<TableCell align="right">
										{new Intl.NumberFormat().format(round(adDetails.spent, 2)) +
											(datas[index]?.currencyCode === 'USD' ? ' $' : ' €')}
									</TableCell>
									<TableCell align="right">{new Intl.NumberFormat().format(adDetails.impressions)}</TableCell>
									<TableCell align="right">{new Intl.NumberFormat().format(adDetails.clicks)}</TableCell>
									<TableCell align="right">
										{new Intl.NumberFormat().format(round(datas[index].metrics?.averageCpc / 1000000, 2)) +
											(datas[index]?.currencyCode === 'USD' ? ' $' : ' €')}
									</TableCell>
									<TableCell align="right">
										{!(adDetails.spent / adDetails.impressions)
											? '0' + (datas[index]?.currencyCode === 'USD' ? ' $' : ' €')
											: new Intl.NumberFormat().format(round(adDetails.spent / adDetails.impressions, 2)) +
											  (datas[index]?.currencyCode === 'USD' ? ' $' : ' €')}
									</TableCell>

									<TableCell align="right">
										{new Intl.NumberFormat().format(datas[index].metrics?.conversions)}
									</TableCell>
									<TableCell align="right">
										<Button onClick={handleClickOpen}>Voir détails</Button>
										<DialogDetails data={datas[index]} open={open} onClose={handleClose} />
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>

					<TableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={rows !== null && rows?.length > 0 ? [10, 20] : []}
								colSpan={6}
								count={totalRows}
								rowsPerPage={pageSize}
								page={pageNumber}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage={t`Nombre de campagne par page`}
							/>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
		</>
	);
};
export default AdsTableDesktop;
