import React from 'react';
import Image from 'next/image';
import { Box, Typography } from '@mui/material';

// Assets
import FbIcon from 'assets/icons/brand/facebook_color.svg';
import IgIcon from 'assets/icons/brand/instagram_color.png';
import YtIcon from 'assets/icons/brand/youtube_color.png';
import LiIcon from 'assets/icons/brand/linkedin_color.png';

interface NetworkConfig {
	title: string;
	namespace: string;
	logo: any;
}

interface Networks {
	[key: string]: NetworkConfig;
}

export const networks: Networks = {
	facebook: { title: 'Facebook', namespace: 'facebook', logo: FbIcon },
	instagram: { title: 'Instagram', namespace: 'instagram', logo: IgIcon },
	youtube: { title: 'Youtube', namespace: 'youtube', logo: YtIcon },
	linkedin: { title: 'LinkedIn', namespace: 'linkedin', logo: LiIcon }
};

interface NetworkIconProps {
	networkName: string;
	displayTitle?: boolean;
	onClick?: () => void;
	disable?: boolean;
}

/**
 * NetworkIcon component
 * Displays the logo and title of a social network.
 *
 * @param networkName - The name of the network.
 * @param displayTitle - Indicates whether the title should be displayed (optional, default: false).
 * @param onClick - Function when user click on item (optional).
 * @param disable - make icon apparance like deactivate (optional, default: false).
 * @version 1.0.0 [DCH] - Initial version of the NetworkIcon component.
 * @version 2.0.0 [DCH] - Add onclick and disable params.
 */
const NetworkIcon: React.FC<NetworkIconProps> = ({ networkName, displayTitle = false, onClick, disable = false }) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	const iconStyle: React.CSSProperties = {
		filter: disable ? 'grayscale(100%)' : 'none'
	};
	return (
		networks[networkName] && (
			<Box
				onClick={handleClick}
				style={{ display: 'inline-flex', cursor: onClick ? 'pointer' : 'default', ...iconStyle }}
			>
				<div style={{ margin: '8px' }}>
					<Image alt="social network" src={networks[networkName]?.logo ?? null} height={32} width={32} />
				</div>
			</Box>
		)
	);
};

export default NetworkIcon;

export type NetworkKey = Extract<keyof Networks, string>;
